define("@clark/cms-ui/components/trustpilot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/mUCGxIy",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[2,\"\\n\"],[11,\"section\"],[16,0,[31,[\"trustpilot \",[30,[36,0],[\"trustpilot-container\"],[[\"from\"],[\"@clark/cms-ui/components/trustpilot/styles\"]]]]]],[24,\"data-test-trustpilot\",\"\"],[17,1],[12],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/trustpilot/template.hbs"
    }
  });

  _exports.default = _default;
});