define("@clark-bi/tracking/services/tracking", ["exports", "@clark-bi/tracking.js"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TrackingService extends Ember.Service {
    /**
     * Send a BI tracking event to our API. Also integrates with Google Tag Manager,
     * if it's enabled for the user.
     *
     * Add new tracking events to the respective
     * `@clark-{composite}/tracking-events` package.
     *
     * @see @clark-bi/tracking.js
     */
    track(event, data) {
      if (Ember.testing) return; // @ts-ignore

      return (0, _tracking.track)(event, data);
    }
    /**
     * Do not use this method any more. Instead, use `TrackingService#track` and
     * add the tracking event to the respective
     * `@clark-{composite}/tracking-events` package or the legacy list in
     * `@clark-bi/tracking.js`.
     *
     * @deprecated
     */


    legacyTrack({
      event,
      ...data
    }) {
      // @ts-ignore
      return this.track(event, data);
    }

  }

  _exports.default = TrackingService;
});