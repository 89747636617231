define("@clark/cms-ui/components/form-builder/input-field/toggle-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dXaCsH4r",
    "block": "{\"symbols\":[\"&attrs\",\"@isChecked\",\"@isDisabled\",\"@onClick\"],\"statements\":[[11,\"label\"],[16,0,[31,[[30,[36,0],[\"slider-input\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/toggle-button/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[8,\"input\",[[16,0,[31,[[30,[36,0],[\"slider-input-checkbox\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/toggle-button/styles\"]]]]]],[17,1],[4,[38,2],[\"click\",[30,[36,1],[[32,4]],null]],null]],[[\"@type\",\"@checked\",\"@disabled\"],[\"checkbox\",[32,2],[32,3]]],null],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[\"slider round\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/toggle-button/styles\"]]]]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"optional\",\"on\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/toggle-button/template.hbs"
    }
  });

  _exports.default = _default;
});