define("@clark/cms-ui/components/lead-gen/consultant-card/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LeadGenConsultantCardVariant = void 0;
  let LeadGenConsultantCardVariant;
  _exports.LeadGenConsultantCardVariant = LeadGenConsultantCardVariant;

  (function (LeadGenConsultantCardVariant) {
    LeadGenConsultantCardVariant["Full"] = "full";
    LeadGenConsultantCardVariant["Minimal"] = "minimal";
  })(LeadGenConsultantCardVariant || (_exports.LeadGenConsultantCardVariant = LeadGenConsultantCardVariant = {}));
});