define("@clark/cms-ui/helpers/hero-small/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validateHeroSmallArgs([args]) {
    (false && !(Ember.isPresent(args.description && typeof args.description === 'string')) && Ember.assert('HeroSmall: Description attribute should be string', Ember.isPresent(args.description && typeof args.description === 'string')));
    (false && !(Object.keys(args.title).length > 0) && Ember.assert('HeroSmall: Title attribute should be string', Object.keys(args.title).length > 0));
    (false && !(Ember.isPresent(args.imgSrc && typeof args.imgSrc.url === 'string')) && Ember.assert('HeroSmall: ImgSrc attribute should be string', Ember.isPresent(args.imgSrc && typeof args.imgSrc.url === 'string')));
  }

  var _default = Ember.Helper.helper(validateHeroSmallArgs);

  _exports.default = _default;
});