define("@clark/cms-ui/components/highlight/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "background-dark": "#203151",
    "c-white": "#FFFFFF",
    "higlight-background": "#080F8C",
    "from-medium": "only screen and (min-width: 769px)",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "highlight-container-width": "720px",
    "highlight-container": "_highlight-container_1gzdbv",
    "highlight": "_highlight_1gzdbv",
    "title": "_title_1gzdbv",
    "description": "_description_1gzdbv"
  };
  _exports.default = _default;
});