define("ember-svg-jar/inlined/reise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.333 2.687C14.09 3.112 14 3.644 14 4v21.5a.5.5 0 01-.243.429L11.5 27.283V29h8v-1.717l-2.257-1.354A.5.5 0 0117 25.5V4c0-.879-.288-1.361-.584-1.628A1.44 1.44 0 0015.5 2c-.585 0-.936.287-1.167.687zm-.866-.5C13.837 1.547 14.485 1 15.5 1c.44 0 1.064.16 1.585.628C17.62 2.111 18 2.88 18 4v21.217l2.257 1.354A.5.5 0 0120.5 27v2.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5V27a.5.5 0 01.243-.429L13 25.217V4c0-.477.111-1.195.467-1.812z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.276 16.053l11-5.5.448.894-10.589 5.294.69 1.724 9.625-.962a.5.5 0 11.1.994l-10 1a.5.5 0 01-.514-.311l-1-2.5a.5.5 0 01.24-.633zM27.865 16.741l-10.588-5.294.447-.894 11 5.5a.5.5 0 01.24.633l-1 2.5a.5.5 0 01-.538.308l-10-1.5.148-.988 9.609 1.44.682-1.705z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.5 20.5v-2h1v2a.5.5 0 01-1 0zM8.5 20v-2h1v2a.5.5 0 01-1 0zM6.5 20v-1.5h1V20a.5.5 0 01-1 0zM23.5 20v-1.5h1V20a.5.5 0 01-1 0z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});