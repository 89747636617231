define("@clark/cms-ui/components/ui/hint/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dSD5rqfI",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[2,\"\\n\"],[11,\"div\"],[16,0,[31,[[30,[36,1],[[30,[36,0],[\"hint \",[32,0,[\"typeClass\"]]],null]],[[\"from\"],[\"@clark/cms-ui/components/ui/hint/styles\"]]]]]],[16,1,[32,0,[\"id\"]]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/ui/hint/template.hbs"
    }
  });

  _exports.default = _default;
});