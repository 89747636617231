define("@clark/cms-ui/components/visually-hidden/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6wblfwQg",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"span\"],[16,0,[31,[[30,[36,0],[\"visually-hidden\"],[[\"from\"],[\"@clark/cms-ui/components/visually-hidden/styles\"]]]]]],[24,\"data-test-visually-hidden\",\"\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/visually-hidden/template.hbs"
    }
  });

  _exports.default = _default;
});