define("@clark/cms-ui/components/common/accordion/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "background-dark": "#203151",
    "font-regular": "'CeraRoundPro-Regular'",
    "icon-color": "#203151",
    "accordion-item": "_accordion-item_106e5r",
    "accordion-item-checkbox": "_accordion-item-checkbox_106e5r",
    "accordion-item-label": "_accordion-item-label_106e5r",
    "accordion-item-label-icon": "_accordion-item-label-icon_106e5r",
    "accordion-item-content": "_accordion-item-content_106e5r"
  };
  _exports.default = _default;
});