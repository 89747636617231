define("@clark/cms-ui/components/ui/labeled-field/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.labelId = labelId;
  _exports.default = void 0;

  function labelId(id) {
    return `${id}:label`;
  }
  /**
   * Use the `LabeledField` component to handle common labeling tasks for
   * form fields, such as adding labels or errors.
   */


  class LabeledFieldComponent extends _component.default {
    get labelId() {
      return labelId(this.args.for);
    }

  }

  _exports.default = LabeledFieldComponent;
});