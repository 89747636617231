define("@clark/cms-ui/modifiers/table-of-contents/did-active-item-update", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class DidActiveItemUpdateModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "containerObserver", void 0);

      _defineProperty(this, "itemsObserver", void 0);

      _defineProperty(this, "activeItem", void 0);
    }

    get items() {
      return this.args.named.items || [];
    }

    get itemElements() {
      return this.items.map(i => this.element.querySelector(`[id="${i.id}"]`));
    }

    didInstall() {
      this.containerObserver = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          // NOTE: Detecting if it starts intersecting due to the user scrolling
          // upwards, in which case the `boundingClientRect.y` would be less than zero.
          // `boundingClientRect.y < 0` means the element's top left point is above the
          // tracked root (a viewport in our case) top bound.
          // We just stick with this practical assumption in here - it would work unless
          // target area is of 0-ish height (in which case it would become `> 0` right
          // after having been intersected after upscroll).
          const isUpScroll = entry.boundingClientRect.y < 0;
          if (isUpScroll) this.makeLastItemActive();
        } else {
          this.clearActiveItem();
        }
      }, {
        rootMargin: '-50% 0px 0px 0px',
        threshold: 0
      });
      this.containerObserver.observe(this.element);
      this.itemsObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.setActiveItem(this.items.find(i => i.id === entry.target.id));
          } else {
            // NOTE: Detecting if it stops intersecting due to the user scrolling
            // upwards, in which case the `y` would be something non-zero.
            // `y == 0` means user just scrolls past the element to the bottom direction.
            const isUpScroll = entry.intersectionRect.y > 0;

            if (isUpScroll && this.activeItem) {
              this.makePreviousItemActive();
            }
          }
        });
      }, {
        rootMargin: '0px 0px -50% 0px',
        threshold: [0.75, 1]
      });
      this.itemElements.forEach(itemElement => {
        if (this.itemsObserver && itemElement) {
          this.itemsObserver.observe(itemElement);
        }
      });
    }

    willRemove() {
      if (this.containerObserver) {
        this.containerObserver.disconnect();
      }

      if (this.itemsObserver) {
        this.itemsObserver.disconnect();
      }
    }

    setActiveItem(activeItem) {
      this.activeItem = activeItem;
      this.onUpdate(activeItem);
    }

    makeLastItemActive() {
      if (this.items.length === 0) return;
      this.activeItem = this.items[this.items.length - 1];
      this.onUpdate(this.activeItem);
    }

    makePreviousItemActive() {
      if (!this.activeItem) return;
      const activeItemIndex = this.items.indexOf(this.activeItem);
      this.setActiveItem(activeItemIndex > 0 ? this.items[activeItemIndex - 1] : undefined);
    }

    clearActiveItem() {
      this.activeItem = undefined;
      this.onUpdate(undefined);
    }

    onUpdate(activeItem) {
      const [onUpdate] = this.args.positional;

      if (onUpdate) {
        onUpdate(activeItem);
      }
    }

  }

  _exports.default = DidActiveItemUpdateModifier;
});