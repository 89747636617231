define("@clark/cms-ui/components/magazine-header/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "night-rider": "#333",
    "sticky-header": "_sticky-header_wg2nbs",
    "header": "_header_wg2nbs",
    "floating-button-wrapper": "_floating-button-wrapper_wg2nbs",
    "clark-logo": "_clark-logo_wg2nbs",
    "font-medium": "'CeraRoundPro-Medium'",
    "tiempos-headline": "'TiemposHeadline'",
    "cursor-pointer": "_cursor-pointer_173r4h",
    "overflow-hidden": "_overflow-hidden_173r4h",
    "flex-vertical-centered": "_flex-vertical-centered_173r4h",
    "logo-wrapper": "_logo-wrapper_173r4h",
    "magazine-label": "_magazine-label_173r4h",
    "label-small": "_label-small_173r4h",
    "cta-button-wrapper": "_cta-button-wrapper_173r4h",
    "slide-down": "_slide-down_173r4h"
  };
  _exports.default = _default;
});