define("@clark/cms-ui/components/numbered-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GpcTIkgA",
    "block": "{\"symbols\":[\"@orientation\",\"&attrs\",\"@number\",\"@text\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[\"\\n    numbered-list-item\\n    orientation-\",[30,[36,0],[[32,1],\"horizontal\"],null],\"\\n  \"],null]],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/styles\"]]]]]],[17,2],[24,\"data-test-numbered-list-item\",\"\"],[16,\"data-test-numbered-list-item-number-value\",[32,3]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[\"icon\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/styles\"]]]]]],[14,\"data-test-numbered-list-item-icon\",\"\"],[12],[2,\"\\n    \"],[8,\"numbered-list/item/number-icon\",[],[[\"@number\",\"@desktopFluidWidth\"],[[32,3],[30,[36,3],[[32,1],\"desktop-vertical\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[15,0,[31,[[30,[36,2],[\"text\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/styles\"]]]]]],[14,\"data-test-numbered-list-item-text\",\"\"],[12],[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"concat\",\"local-class\",\"eq\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/numbered-list/item/template.hbs"
    }
  });

  _exports.default = _default;
});