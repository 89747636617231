define("@clark/cms-ui/components/table-of-contents/list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "from-xlarge": "only screen and (min-width: 1380px)",
    "font-medium": "'CeraRoundPro-Medium'",
    "link-color-default": "white",
    "link-color-default-desktop": "#203151",
    "link-color-minimal": "#000000",
    "bullet-color": "#080f8c",
    "bullet-size": "7px",
    "bullet-spacing": "8px",
    "list": "_list_y14c3o",
    "item": "_item_y14c3o",
    "link": "_link_y14c3o",
    "is-active": "_is-active_y14c3o",
    "link-text": "_link-text_y14c3o",
    "link-bullet": "_link-bullet_y14c3o",
    "link-arrow": "_link-arrow_y14c3o",
    "appearance-minimal": "_appearance-minimal_y14c3o"
  };
  _exports.default = _default;
});