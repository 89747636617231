define("@clark/cms-ui/components/cms-footer/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CmsFooterComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = (_temp = class CmsFooterComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "cookieBanner", _descriptor2, this);

      _initializerDefineProperty(this, "config", _descriptor3, this);

      _defineProperty(this, "socialMediaLinks", this.config.getConfig('socialMediaLinks'));

      _defineProperty(this, "informationLinks", [{
        url: '/cms/so-funktionierts/',
        label: this.intl.t('footer.information.function')
      }
      /* Blog is currently under maintenance. We are going to need this once we have the blog back online.
       {
        url: 'https://blog.clark.de/',
        label: this.intl.t('footer.information.magazine')
      } */
      ]);

      _defineProperty(this, "companyLinks", [{
        url: '/cms/ueber-uns/',
        label: this.intl.t('footer.companies.about_us')
      }, {
        url: '/careers/',
        label: this.intl.t('footer.companies.career')
      }, {
        url: 'https://clark.pr.co/',
        label: this.intl.t('footer.companies.press')
      }, {
        url: '/cms/partnerships/',
        label: this.intl.t('footer.companies.partnerships')
      }]);

      _defineProperty(this, "bottomLinks", [{
        url: '/agb',
        label: this.intl.t('footer.copyright.conditions')
      }, {
        url: '/impressum',
        label: this.intl.t('footer.copyright.imprint')
      }, {
        url: '/datenschutz',
        label: this.intl.t('footer.copyright.data_protection')
      }, {
        url: '/erstinformation',
        label: this.intl.t('footer.copyright.initial_information')
      }, {
        url: '/nachhaltigkeitsinformationen',
        label: this.intl.t('footer.copyright.sustainablity_information')
      }]);

      _defineProperty(this, "trustpilotWidgetConfig", this.config.getConfig('trustPilotConfig.footer'));
    }

    get enabledBottomLinks() {
      const disabledLinks = this.config.getConfig('disabledFooterLinks');

      if (disabledLinks) {
        this.bottomLinks = this.bottomLinks.filter(function (link) {
          return !disabledLinks.includes(link.url);
        });
      }

      return this.bottomLinks;
    }

    showCookieBanner() {
      this.cookieBanner.toggleShowCookieBanner(true, true);
      this.cookieBanner.toggleIsUpdate(true);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cookieBanner", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showCookieBanner", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showCookieBanner"), _class.prototype)), _class));
  _exports.default = CmsFooterComponent;
});