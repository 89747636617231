define("@clark/cms-ui/components/swiper-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8VQ7SYq3",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"swiper-container\"],[17,1],[4,[38,0],[[32,0,[\"initializeSwiperJS\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"swiper-wrapper\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/swiper-container/template.hbs"
    }
  });

  _exports.default = _default;
});