define("@clark/cms-ui/components/thumbnail-list/thumbnail/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "image-height": "142px",
    "thumbnail-container": "_thumbnail-container_ql5hc4",
    "thumbnail-image": "_thumbnail-image_ql5hc4",
    "thumbnail-title": "_thumbnail-title_ql5hc4"
  };
  _exports.default = _default;
});