define("@clark/cms-ui/components/trustpilot/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vDpOdIVm",
    "block": "{\"symbols\":[\"@templateId\",\"@height\",\"@width\",\"@theme\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,\"data-test-trustpilot-widget\",\"\"],[24,0,\"trustpilot-widget\"],[16,\"data-template-id\",[32,1]],[16,\"data-style-height\",[32,2]],[16,\"data-schema-type\",[32,0,[\"trustpilotWidgetConfig\",\"dataSchemaType\"]]],[16,\"data-locale\",[32,0,[\"trustpilotWidgetConfig\",\"locale\"]]],[16,\"data-businessunit-id\",[32,0,[\"trustpilotWidgetConfig\",\"businessunitId\"]]],[16,\"data-style-width\",[30,[36,0],[[32,3],[32,0,[\"trustpilotWidgetConfig\",\"width\"]]],null]],[16,\"data-theme\",[30,[36,0],[[32,4],[32,0,[\"trustpilotWidgetConfig\",\"theme\"]]],null]],[16,\"data-tags\",[32,0,[\"trustpilotWidgetConfig\",\"tags\"]]],[16,\"data-stars\",[32,0,[\"trustpilotWidgetConfig\",\"stars\"]]],[17,5],[4,[38,1],[[32,0,[\"loadTrustpilotWidget\"]]],null],[12],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"did-insert\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/trustpilot/widget/template.hbs"
    }
  });

  _exports.default = _default;
});