define("@clark/cms-ui/components/table-of-contents/dropdown-floater/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T8MM6/4Q",
    "block": "{\"symbols\":[\"transitionClass\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"dropdown-floater-sentinel\"],[[\"from\"],[\"@clark/cms-ui/components/table-of-contents/dropdown-floater/styles\"]]]]]],[24,\"data-test-table-of-contents-dropdown-floater-sentinel\",\"\"],[24,\"data-table-of-contents-dropdown-floater-sentinel\",\"\"],[4,[38,1],null,[[\"onScrollBelow\",\"onBackIntoView\"],[[32,0,[\"show\"]],[32,0,[\"hide\"]]]]],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"dropdown-floater\"],[[\"from\"],[\"@clark/cms-ui/components/table-of-contents/dropdown-floater/styles\"]]]]]],[14,\"data-test-table-of-contents-dropdown-floater\",\"\"],[12],[2,\"\\n  \"],[8,\"transition-class-provider\",[],[[\"@duration\",\"@in\",\"@isMountable\"],[200,[32,0,[\"isVisible\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[30,[36,2],[\"dropdown-floater-content \",[32,1]],null]],[[\"from\"],[\"@clark/cms-ui/components/table-of-contents/dropdown-floater/styles\"]]]]]],[14,\"data-test-table-of-contents-dropdown-floater-content\",\"\"],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"on-viewport-relative-scroll\",\"concat\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/table-of-contents/dropdown-floater/template.hbs"
    }
  });

  _exports.default = _default;
});