define("@clark/cms-ui/components/modal/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AeQFp8da",
    "block": "{\"symbols\":[\"@describedBy\",\"@description\",\"@labelledBy\",\"@title\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[\"header\"],[[\"from\"],[\"@clark/cms-ui/components/modal/header/styles\"]]]]]],[12],[2,\"\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h2\"],[15,1,[32,3]],[15,0,[30,[36,0],[\"h2 text-center\"],null]],[12],[2,\"\\n      \"],[1,[32,4]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[15,1,[32,1]],[15,0,[30,[36,0],[\"c2 text-center\"],null]],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"typo\",\"local-class\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/header/template.hbs"
    }
  });

  _exports.default = _default;
});