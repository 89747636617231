define("@clark/cms-ui/components/form-builder/input-field/check-box/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CheckBoxInputField extends _component.default {}

  _exports.default = CheckBoxInputField;
});