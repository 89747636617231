define("@clark/cms-ui/components/breadcrumbs/component", ["exports", "@glimmer/component", "@clark/cms-ui/utils/strings"], function (_exports, _component, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeBreadcrumbsFromUrl = makeBreadcrumbsFromUrl;
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PLACEHOLDER_BREADCRUMB = {
    label: '…'
  };

  function makeBreadcrumbsFromUrl(url) {
    return url.split('?')[0].replace(/^\/*|\/*$/g, '').split('/').filter(path => path.indexOf('#') !== 0).map((segment, index, array) => {
      return {
        // avoiding self linking for last breadcrumb
        url: index !== array.length - 1 ? `/${array.slice(0, index + 1).join('/')}/` : undefined,
        label: (0, _strings.titleize)(segment)
      };
    });
  }

  let BreadcrumbsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class BreadcrumbsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }

    get breadcrumbs() {
      const ROOT_BREADCRUMB = {
        url: '/',
        label: this.intl.t('breadcrumbs.root_label')
      };
      const value = [ROOT_BREADCRUMB, ...makeBreadcrumbsFromUrl(this.router.currentURL)];
      const {
        max = 3
      } = this.args;

      if (value.length > max) {
        value.splice(1, value.length - max, PLACEHOLDER_BREADCRUMB);
      }

      return value;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BreadcrumbsComponent;
});