define("@clark/cms-ui/components/numbered-list/item/number-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/zKPPHQu",
    "block": "{\"symbols\":[\"@desktopFluidWidth\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[\"\\n    number-icon\\n    \",[30,[36,0],[[32,1],\"desktop-fluid-width\"],null],\"\\n  \"],null]],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/number-icon/styles\"]]]]]],[17,2],[24,\"data-test-number-icon\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[\"positioning-container\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/number-icon/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[\"ellipse\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/number-icon/styles\"]]]]]],[14,\"data-test-number-icon-ellipse\",\"\"],[12],[13],[2,\"\\n\\n    \"],[10,\"span\"],[15,0,[31,[[30,[36,2],[\"number\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/item/number-icon/styles\"]]]]]],[14,\"data-test-number-icon-number\",\"\"],[12],[2,\"\\n      \"],[1,[32,0,[\"number\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/numbered-list/item/number-icon/template.hbs"
    }
  });

  _exports.default = _default;
});