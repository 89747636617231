define("@clark/cms-ui/styles/foundation/border-radius", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "border-radius-normal": "0.25rem",
    "border-radius-large": "0.5rem"
  };
  _exports.default = _default;
});