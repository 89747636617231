define("ember-svg-jar/inlined/checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.839 2.867c-4.765 4.399-10.846 14.78-10.907 14.885a.5.5 0 01-.755.13c-.045-.038-4.534-3.834-5.988-4.99a.5.5 0 11.623-.783c1.188.946 4.373 3.628 5.56 4.628 1.305-2.185 6.475-10.621 10.79-14.604a.5.5 0 01.677.735zm-10.91 17.096l-.217.03-.192.007h-.02a2.505 2.505 0 01-1.61-.587c-.483-.41-4.604-3.89-5.945-4.956a2.5 2.5 0 013.11-3.916c.783.624 2.377 1.953 3.824 3.168A89.18 89.18 0 0118.562 3H2.5A1.5 1.5 0 001 4.5v18A1.5 1.5 0 002.5 24h18a1.5 1.5 0 001.5-1.5V6.676a102.73 102.73 0 00-8.34 12.084 2.49 2.49 0 01-1.731 1.203z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});