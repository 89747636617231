define("@clark/cms-ui/components/youtube-content/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "tiempos-headline": "'TiemposHeadline'",
    "rich-black": "#01051C",
    "container": "_container_ilppk5",
    "container-layout": "_container-layout_ilppk5",
    "headline": "_headline_ilppk5",
    "headline-wrapper": "_headline-wrapper_ilppk5",
    "subtext": "_subtext_ilppk5",
    "fallback-image": "_fallback-image_ilppk5",
    "fallback-image-wrapper": "_fallback-image-wrapper_ilppk5",
    "iframe-wrapper": "_iframe-wrapper_ilppk5",
    "iframe-has-cta": "_iframe-has-cta_ilppk5",
    "youtube-iframe": "_youtube-iframe_ilppk5",
    "button-wrapper": "_button-wrapper_ilppk5",
    "hide": "_hide_ilppk5"
  };
  _exports.default = _default;
});