define("@clark/cms-ui/components/form-builder/input-field/check-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input-checkbox-border": "#d6d6d6",
    "input-checked": "#2B6CDE",
    "input-form-checkbox-wrapper": "_input-form-checkbox-wrapper_1pvxx5",
    "input-form-checkbox-container": "_input-form-checkbox-container_1pvxx5",
    "input-form-checkbox": "_input-form-checkbox_1pvxx5",
    "input-form-checkmark": "_input-form-checkmark_1pvxx5"
  };
  _exports.default = _default;
});