define("@clark/cms-ui/components/responsive-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YykUGO//",
    "block": "{\"symbols\":[\"picture\",\"@desktopImage\",\"@defaultImage\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"responsive-image\"],[[\"from\"],[\"@clark/cms-ui/components/responsive-image/styles\"]]]]]],[14,\"data-test-responsive-image\",\"\"],[12],[2,\"\\n  \"],[8,\"multi-source-image\",[[24,\"data-test-responsive-image-element\",\"\"],[4,[38,4],null,[[\"maxWidth\"],[[30,[36,2],[[30,[36,1],[\"isBeforeDesktop\"],null],[32,0,[\"defaultImageMaxWidth\"]],[32,0,[\"desktopImageMaxWidth\"]]],null]]]]],[[\"@fallbackImgSource\",\"@imgClass\"],[[30,[36,3],null,[[\"url\",\"altText\"],[[32,2,[\"url\"]],[30,[36,2],[[30,[36,1],[\"isBeforeDesktop\"],null],[32,3,[\"altText\"]],[32,2,[\"altText\"]]],null]]]],[30,[36,0],[\"image-default\"],[[\"from\"],[\"@clark/cms-ui/components/responsive-image/styles\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"source\"]],[[16,\"media\",[30,[36,5],[[32,1,[\"mediaQueries\"]],\"beforeDesktop\"],null]],[16,\"srcset\",[32,3,[\"url\"]]]],[[\"@src\",\"@includeModernFormat\"],[[32,3,[\"url\"]],true]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"media\",\"if\",\"hash\",\"style\",\"get\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/responsive-image/template.hbs"
    }
  });

  _exports.default = _default;
});