define("@clark/cms-ui/components/common/app-store/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ezUMsZzd",
    "block": "{\"symbols\":[\"@appStoreLink\",\"@playStoreLink\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"app-store-container\"],[[\"from\"],[\"@clark/cms-ui/components/common/app-store/styles\"]]]]]],[12],[2,\"\\n  \"],[8,\"link\",[[24,\"aria-label\",\"Link to Apple App Store (opens in a new window)\"],[24,\"data-test-app-store-ios\",\"\"]],[[\"@url\",\"@isExternal\",\"@hideExternalIcon\"],[[32,1],true,true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"picture\"],[12],[2,\"\\n      \"],[10,\"source\"],[14,\"srcset\",\"/assets/images/appstore.webp\"],[14,4,\"image/webp\"],[12],[13],[2,\"\\n      \"],[10,\"img\"],[14,\"loading\",\"lazy\"],[14,\"src\",\"/assets/images/appstore.jpg\"],[14,\"alt\",\"App Store\"],[14,\"role\",\"presentation\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link\",[[24,\"aria-label\",\"Link to Android Play Store (opens in a new window)\"],[24,\"data-test-app-store-android\",\"\"]],[[\"@url\",\"@isExternal\",\"@hideExternalIcon\"],[[32,2],true,true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"picture\"],[12],[2,\"\\n      \"],[10,\"source\"],[14,\"srcset\",\"/assets/images/playstore.webp\"],[14,4,\"image/webp\"],[12],[13],[2,\"\\n      \"],[10,\"img\"],[14,\"loading\",\"lazy\"],[14,\"src\",\"/assets/images/playstore.jpg\"],[14,\"alt\",\"Google Play Store\"],[14,\"role\",\"presentation\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/app-store/template.hbs"
    }
  });

  _exports.default = _default;
});