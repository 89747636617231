define("ember-svg-jar/inlined/back-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.264 6.373a1.273 1.273 0 00-1.8 0l-5.09 5.09a1.27 1.27 0 00-.001 1.8l5.09 5.092a1.273 1.273 0 001.8-1.8l-2.918-2.919h7.291a1.273 1.273 0 000-2.545h-7.29l2.918-2.918a1.273 1.273 0 000-1.8z\" fill=\"#203151\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});