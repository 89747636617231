define("@clark/cms-ui/styles/foundation/spacing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "spacing-none": "0",
    "spacing-extra-tight": "0.25rem",
    "spacing-tight": "0.5rem",
    "spacing-normal": "1rem",
    "spacing-loose": "1.5rem",
    "spacing-extra-loose": "2rem"
  };
  _exports.default = _default;
});