define("@clark/cms-ui/components/common/divider/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-secondary-border": "#DADDEC",
    "divider": "_divider_1c0abd"
  };
  _exports.default = _default;
});