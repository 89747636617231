define("@clark/cms-ui/components/table-of-contents/sidebar-floater/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "from-xlarge": "only screen and (min-width: 1380px)",
    "header-height": "80px",
    "content-width": "758px",
    "content-spacing": "24px",
    "floater-top-spacing": "calc(80px + 24px)",
    "table-of-contents-sidebar-floater": "_table-of-contents-sidebar-floater_dvv8ti",
    "table-of-contents-sidebar-floater-content": "_table-of-contents-sidebar-floater-content_dvv8ti"
  };
  _exports.default = _default;
});