define("@clark/cms-ui/components/stack/item/component", ["exports", "@glimmer/component", "@clark/cms-ui/components/stack/styles"], function (_exports, _component, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StackItemComponent extends _component.default {
    get classes() {
      const value = [_styles.default.item];

      if (this.args.fill) {
        value.push(_styles.default['item-fill']);
      }

      return value.join(' ');
    }

  }

  _exports.default = StackItemComponent;
});