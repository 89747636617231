define("@clark/cms-ui/components/form-builder/input-field/phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bzxze+0S",
    "block": "{\"symbols\":[\"@placeholder\",\"@value\",\"@name\",\"&attrs\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"form-input-phone-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/phone/styles\"]]]]]],[12],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[\"form-input-phone-icon-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/phone/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"icon\",[[16,0,[31,[[30,[36,0],[\"form-input-phone-icon\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/phone/styles\"]]]]]]],[[\"@source\"],[\"de-square\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"input\"],[16,0,[31,[[30,[36,0],[\"form-input\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/phone/styles\"]]]]]],[16,\"placeholder\",[32,1]],[16,2,[32,2]],[16,3,[32,3]],[17,4],[4,[38,1],[\"input\",[32,0,[\"onChange\"]]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"on\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/phone/template.hbs"
    }
  });

  _exports.default = _default;
});