define("@clark/cms-ui/components/partner-header/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "c-white": "#FFFFFF",
    "c-copy": "rgba(32, 49, 81, 0.7)",
    "cta-secondary-border": "#DADDEC",
    "background-light-gray": "#F5F6FA",
    "background-dark": "#203151",
    "font-medium": "'CeraRoundPro-Medium'",
    "font-light": "'CeraRoundPro-Light'",
    "font-regular": "'CeraRoundPro-Regular'",
    "breakpoint-medium": "769px",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "header-background": "#FFFFFF",
    "header-border": "#DADDEC",
    "header-mobile-border": "#DADDEC",
    "header-mobile-sub-nav-bg": "#F5F6FA",
    "header-nav-icon": "#203151",
    "header-padding": "1rem",
    "header-height": "80px",
    "sticky-shadow": "rgba(173,182,217,0.5)",
    "breaker-height": "15px",
    "mobile-bottom-background": "#F5F6FA",
    "header-text": "rgba(32, 49, 81, 0.7)",
    "mobile-status-bar-bottom": "80px",
    "logo-color": "#0439d7",
    "sticky-header": "_sticky-header_wg2nbs",
    "header": "_header_wg2nbs",
    "floating-button-wrapper": "_floating-button-wrapper_wg2nbs",
    "clark-logo": "_clark-logo_wg2nbs",
    "nav-boundary": "_nav-boundary_wg2nbs",
    "floating-button": "_floating-button_wg2nbs",
    "breaker": "_breaker_wg2nbs",
    "move-up": "_move-up_wg2nbs",
    "link": "_link_wg2nbs",
    "desktop-nav": "_desktop-nav_wg2nbs",
    "mobile-nav-container": "_mobile-nav-container_wg2nbs",
    "mobile-nav": "_mobile-nav_wg2nbs",
    "burger-icon": "_burger-icon_wg2nbs",
    "mobile-nav-close": "_mobile-nav-close_wg2nbs",
    "mobile-nav-close-container": "_mobile-nav-close-container_wg2nbs",
    "mobile-links": "_mobile-links_wg2nbs",
    "mobile-link": "_mobile-link_wg2nbs",
    "mobile-link-flex": "_mobile-link-flex_wg2nbs",
    "mobile-links-item": "_mobile-links-item_wg2nbs",
    "mobile-sub-menu": "_mobile-sub-menu_wg2nbs",
    "mobile-drawer": "_mobile-drawer_wg2nbs",
    "mobile-button-wrapper": "_mobile-button-wrapper_wg2nbs",
    "mobile-bottom-links": "_mobile-bottom-links_wg2nbs",
    "dropdown-menu": "_dropdown-menu_wg2nbs",
    "dropdown-row": "_dropdown-row_wg2nbs",
    "dropdown-row-title": "_dropdown-row-title_wg2nbs",
    "dropdown-row-links": "_dropdown-row-links_wg2nbs",
    "dropdown-row-sub-links": "_dropdown-row-sub-links_wg2nbs",
    "dropdown-row-link": "_dropdown-row-link_wg2nbs",
    "partner-logo": "_partner-logo_wg2nbs"
  };
  _exports.default = _default;
});