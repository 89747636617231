define("ember-svg-jar/inlined/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Service-2</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#203151\" width=\"48\" height=\"48\" rx=\"24\"/><path d=\"M34.56 13.96H13.44c-.795 0-1.44.645-1.44 1.44v15.36c0 .795.645 1.44 1.44 1.44h12.157c.106 0 .209.036.293.1l4.729 3.543a.96.96 0 001.541-.764V32.2h2.4c.795 0 1.44-.645 1.44-1.44V15.4c0-.795-.645-1.44-1.44-1.44zm-7.34 11.66a.48.48 0 11-.68.68L24 23.758 21.46 26.3a.48.48 0 01-.68-.678l2.541-2.541-2.54-2.54a.48.48 0 11.678-.68L24 22.402l2.54-2.54a.48.48 0 11.68.679l-2.541 2.54 2.54 2.54z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});