define("@clark/cms-ui/components/responsive-image/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "responsive-image": "_responsive-image_1qytfm",
    "image-default": "_image-default_1qytfm",
    "image-desktop": "_image-desktop_1qytfm"
  };
  _exports.default = _default;
});