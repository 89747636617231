define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path d=\"M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-2.182c5.422 0 9.818-4.396 9.818-9.818S17.422 2.182 12 2.182 2.182 6.578 2.182 12 6.578 21.818 12 21.818z\" id=\"a\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><use xlink:href=\"#a\"/><path d=\"M16.108 7.892c.34.34.34.893 0 1.234L13.233 12l2.875 2.875a.873.873 0 01-1.234 1.234l-2.875-2.875-2.873 2.875a.873.873 0 11-1.234-1.234L10.765 12 7.892 9.127a.873.873 0 111.234-1.234L12 10.765l2.875-2.873a.873.873 0 011.234 0z\" fill=\"currentColor\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});