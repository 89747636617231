define("@clark/cms-ui/components/cms-footer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "c-white": "#FFFFFF",
    "c-title": "rgba(32, 49, 81, 1)",
    "background-light-gray": "#F5F6FA",
    "cta-secondary-border": "#DADDEC",
    "footer-link-hover": "#2B6CDE",
    "font-light": "'CeraRoundPro-Light'",
    "font-medium": "'CeraRoundPro-Medium'",
    "font-regular": "'CeraRoundPro-Regular'",
    "breakpoint-medium": "769px",
    "c3": "_c3_16bl1i _copy-text_16bl1i",
    "footer-background": "#FFFFFF",
    "copyright-background": "#F5F6FA",
    "footer-text-color": "rgba(32, 49, 81, 1)",
    "footer-copyright-border": "#DADDEC",
    "footer": "_footer_j7sg6p",
    "footer-link-typography": "_footer-link-typography_j7sg6p _c3_16bl1i _copy-text_16bl1i",
    "footer-copy": "_footer-copy_j7sg6p _footer-link-typography_j7sg6p _c3_16bl1i _copy-text_16bl1i",
    "footer-link": "_footer-link_j7sg6p _footer-link-typography_j7sg6p _c3_16bl1i _copy-text_16bl1i",
    "footer-list": "_footer-list_j7sg6p",
    "legal-links": "_legal-links_j7sg6p",
    "legal-link-item": "_legal-link-item_j7sg6p",
    "social-network": "_social-network_j7sg6p _d-flex_17fzo8",
    "network": "_network_j7sg6p",
    "copyright-container": "_copyright-container_j7sg6p",
    "mobile-margin": "_mobile-margin_j7sg6p",
    "data-center-icon": "_data-center-icon_j7sg6p",
    "trustpilot-container": "_trustpilot-container_j7sg6p"
  };
  _exports.default = _default;
});