define("@clark/cms-ui/styles/foundation/shadows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "shadow-faint": "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.02)",
    "shadow-normal": "0 0.125rem 0.125rem 0 rgba(173, 182, 217, 0.5)",
    "shadow-layer": "0 0.125rem 0.25rem 0 rgba(173, 182, 217, 0.5)",
    "shadow-overlay": "0 0.25rem 0.5rem 0 rgba(173, 182, 217, 0.4)",
    "shadow-button": "0 0.125rem 0.125rem rgba(8,15,140,.5)"
  };
  _exports.default = _default;
});