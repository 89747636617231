define("@clark/cms-ui/components/crosslink/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kAEZvmao",
    "block": "{\"symbols\":[\"@items\",\"@title\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"crosslink-section\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/section/styles\"]]]]]],[14,\"data-test-crosslink-section\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"layout-container\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/section/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/section/styles\"]]]]]],[14,\"data-test-crosslink-section-title\",\"\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,2]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[\"isFromMedium\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"crosslink/carousel\",[],[[\"@items\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"crosslink/appendable-list\",[],[[\"@items\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"html-safe\",\"media\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/crosslink/section/template.hbs"
    }
  });

  _exports.default = _default;
});