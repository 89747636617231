define("@clark/cms-ui/components/spinner/component", ["exports", "@glimmer/component", "@clark/cms-ui/components/spinner/styles"], function (_exports, _component, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SpinnerComponent extends _component.default {
    get classes() {
      const value = [_styles.default[`size-${this.args.size || 'medium'}`], _styles.default[`color-${this.args.color || 'blue'}`]];
      return value.join(' ');
    }

  }

  _exports.default = SpinnerComponent;
});