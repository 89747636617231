define("@clark/cms-ui/components/modal/footer/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "spacing-normal": "1rem",
    "footer": "_footer_1uc9yr",
    "content": "_content_1uc9yr"
  };
  _exports.default = _default;
});