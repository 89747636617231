define("@clark/cms-ui/components/table-of-contents/dropdown-floater/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "from-xlarge": "only screen and (min-width: 1380px)",
    "header-height": "80px",
    "content-width": "758px",
    "content-spacing": "24px",
    "floater-top-spacing": "calc(80px + 24px)",
    "dropdown-floater": "_dropdown-floater_6b5jxz",
    "dropdown-floater-sentinel": "_dropdown-floater-sentinel_6b5jxz",
    "dropdown-floater-content": "_dropdown-floater-content_6b5jxz",
    "enter": "_enter_6b5jxz",
    "exit": "_exit_6b5jxz",
    "exit-active": "_exit-active_6b5jxz",
    "enter-active": "_enter-active_6b5jxz"
  };
  _exports.default = _default;
});