define("@clark/cms-ui/components/faq/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fVk6EqLU",
    "block": "{\"symbols\":[\"section\",\"item\",\"@header\",\"@items\"],\"statements\":[[8,\"common/cms-section\",[[16,0,[31,[[30,[36,0],[\"faq\"],[[\"from\"],[\"@clark/cms-ui/components/faq/styles\"]]]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[],[[\"@title\"],[[32,3,[\"title\"]]]],null],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[[16,0,[31,[[30,[36,1],[\"row align-items-md-end\"],null],\" \",[30,[36,0],[\"faq-list\"],[[\"from\"],[\"@clark/cms-ui/components/faq/styles\"]]]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"faq-list-item\"],[[\"from\"],[\"@clark/cms-ui/components/faq/styles\"]]]]]],[12],[2,\"\\n        \"],[8,\"faq/faq-item\",[],[[\"@title\",\"@description\"],[[32,2,[\"title\"]],[32,2,[\"description\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"grid\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/faq/template.hbs"
    }
  });

  _exports.default = _default;
});