define("@clark/cms-ui/components/common/accordion/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jDHP/BfH",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"accordion-item\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[8,\"input\",[[16,0,[31,[[30,[36,0],[\"accordion-item-checkbox\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/styles\"]]]]]],[4,[38,1],[\"change\",[32,0,[\"animateAccordion\"]]],null]],[[\"@id\",\"@type\"],[[32,0,[\"id\"]],\"checkbox\"]],null],[2,\"\\n\\n  \"],[18,2,[[30,[36,3],null,[[\"Label\",\"Content\"],[[30,[36,2],[\"common/accordion/item/label\"],[[\"for\"],[[32,0,[\"id\"]]]]],[30,[36,2],[\"common/accordion/item/content\"],[[\"for\"],[[32,0,[\"id\"]]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"on\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/accordion/item/template.hbs"
    }
  });

  _exports.default = _default;
});