define("@clark/cms-ui/helpers/highlight/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validateArgs([args]) {
    (false && !(typeof args.title === 'string') && Ember.assert('Highlight: Missing title argument', typeof args.title === 'string'));
    (false && !(typeof args.description === 'string') && Ember.assert('Highlight: Missing description argument', typeof args.description === 'string'));
  }

  var _default = Ember.Helper.helper(validateArgs);

  _exports.default = _default;
});