define("@clark/cms-ui/components/common/accordion/item/label/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accordion-item-label": "_accordion-item-label_106e5r",
    "accordion-item-label-icon": "_accordion-item-label-icon_106e5r"
  };
  _exports.default = _default;
});