define("@clark/cms-ui/components/numbered-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFeSxS3d",
    "block": "{\"symbols\":[\"item\",\"index\",\"@layout\",\"&attrs\",\"@items\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[[30,[36,5],[\"\\n    numbered-list\\n    layout-\",[30,[36,4],[[32,3],\"rows\"],null],\"\\n  \"],null]],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/styles\"]]]]]],[17,4],[24,\"data-test-numbered-list\",\"\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"item-container\"],[[\"from\"],[\"@clark/cms-ui/components/numbered-list/styles\"]]]]]],[14,\"data-test-numbered-list-item-container\",\"\"],[12],[2,\"\\n      \"],[8,\"numbered-list/item\",[],[[\"@number\",\"@text\",\"@orientation\"],[[30,[36,1],[[32,2],1],null],[32,1,[\"text\"]],[30,[36,3],[[30,[36,2],[[32,3],\"desktop-tiles\"],null],\"desktop-vertical\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"add\",\"eq\",\"if\",\"or\",\"concat\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/numbered-list/template.hbs"
    }
  });

  _exports.default = _default;
});