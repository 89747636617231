define("ember-svg-jar/inlined/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.364 0C7.334 0 0 7.315 0 16.32c0 9.562 9.587 24.768 14.092 30.566A2.844 2.844 0 0016.364 48c.847 0 1.694-.365 2.271-1.114C23.14 41.107 32.727 25.94 32.727 16.32 32.727 7.315 25.393 0 16.364 0zm.75 45.715a.974.974 0 01-.75.365.974.974 0 01-.751-.365C11.147 39.955 1.925 25.19 1.925 16.32c0-7.949 6.469-14.4 14.439-14.4s14.438 6.451 14.438 14.4c0 8.909-9.221 23.654-13.688 29.395zm-.75-38.035c-3.716 0-6.738 3.014-6.738 6.72s3.022 6.72 6.738 6.72c3.715 0 6.738-3.014 6.738-6.72s-3.023-6.72-6.738-6.72zm0 11.52a4.808 4.808 0 01-4.813-4.8c0-2.65 2.156-4.8 4.813-4.8a4.808 4.808 0 014.813 4.8c0 2.65-2.157 4.8-4.813 4.8z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "33",
      "height": "48",
      "viewBox": "0 0 33 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});