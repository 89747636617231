define("@clark/cms-ui/components/cms-header/account-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "switch-button-spacing": "16px",
    "button-switcher-container": "_button-switcher-container_z3plha",
    "is-switched": "_is-switched_z3plha"
  };
  _exports.default = _default;
});