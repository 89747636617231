define("@clark/cms-ui/components/common/flyout/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hYFjLTR4",
    "block": "{\"symbols\":[\"@menu\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,0],[[32,1,[\"open\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[\"menu \",[30,[36,0],[[32,1,[\"closing\"]],\" menu-slide-out\",\"menu-slide-in\"],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/common/flyout/menu/styles\"]]]]]],[17,2],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/flyout/menu/template.hbs"
    }
  });

  _exports.default = _default;
});