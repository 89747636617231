define("@clark/cms-ui/components/key-listener/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/2j5ZU5/",
    "block": "{\"symbols\":[\"@handler\",\"@key\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"validate\"]]],null]],[2,\"\\n\"],[1,[30,[36,1],[[32,0,[\"validate\"]],[32,2],[32,1]],null]],[2,\"\\n\"],[1,[30,[36,2],[\"keyup\",[32,0,[\"handleKeyEvent\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"on-document\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/key-listener/template.hbs"
    }
  });

  _exports.default = _default;
});