define("@clark/cms-ui/components/common/cms-section/header/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "section-header-container": "_section-header-container_2m0rn3",
    "title": "_title_2m0rn3"
  };
  _exports.default = _default;
});