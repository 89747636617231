define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>user-a</title><path d=\"M13 2a1.976 1.976 0 00-1 .278 1.982 1.982 0 010 3.444A1.976 1.976 0 0013 6a2 2 0 000-4zM13 9a3.954 3.954 0 00-1 .142A3.996 3.996 0 0115 13v3.5a.5.5 0 01-.5.5H14a1 1 0 00-1 1v5.5a.5.5 0 01-.5.5h2a.5.5 0 00.5-.5V18a1 1 0 011-1h.5a.5.5 0 00.5-.5V13a4.005 4.005 0 00-4-4z\" fill=\"#e8e5fe\"/><path d=\"M13 7a3 3 0 10-3-3 3 3 0 003 3zm0-5a2 2 0 11-2 2 2.002 2.002 0 012-2zM13 8a5 5 0 00-5 5v3.5A1.5 1.5 0 009.5 18h.5v5.5a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5V18h.5a1.5 1.5 0 001.5-1.5V13a5 5 0 00-5-5zm4 8.5a.5.5 0 01-.5.5H16a1 1 0 00-1 1v5.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V18a1 1 0 00-1-1h-.5a.5.5 0 01-.5-.5V13a4 4 0 018 0z\" fill=\"#8075f2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});