define("@clark/cms-ui/components/form-builder/input-field/toggle-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-primary": "#2B6CDE",
    "c-grey-light": "#d6d6d6",
    "c-white": "#FFFFFF",
    "slider-input": "_slider-input_5q33v2",
    "slider-input-checkbox": "_slider-input-checkbox_5q33v2",
    "slider": "_slider_5q33v2",
    "round": "_round_5q33v2"
  };
  _exports.default = _default;
});