define("@clark/cms-ui/components/crosslink/item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "c-white": "#FFFFFF",
    "text-color": "#203151",
    "crosslink-item": "_crosslink-item_74oin4",
    "image-container": "_image-container_74oin4",
    "image-overlay": "_image-overlay_74oin4",
    "image-wrapper": "_image-wrapper_74oin4",
    "image": "_image_74oin4",
    "body": "_body_74oin4",
    "title": "_title_74oin4",
    "content": "_content_74oin4",
    "view-article-link": "_view-article-link_74oin4"
  };
  _exports.default = _default;
});