define("@clark/cms-ui/styles/foundation/border-width", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "border-width-normal": "1px",
    "border-width-thick": "2px",
    "border-width-thicker": "3px"
  };
  _exports.default = _default;
});