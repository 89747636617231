define("@clark/cms-ui/components/greenhouse-board/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AFz5doGS",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,1,\"grnhse_app\"],[4,[38,0],[[32,0,[\"initializeGreenhouse\"]]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/greenhouse-board/template.hbs"
    }
  });

  _exports.default = _default;
});