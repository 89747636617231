define("@clark/cms-ui/components/cms-header/account-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P/0W2GvP",
    "block": "{\"symbols\":[\"@isScrolled\",\"&attrs\",\"@adjustLink\",\"@isUserAuthenticated\"],\"statements\":[[6,[37,0],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"button\",[[17,2],[24,\"data-test-account-link-button\",\"\"]],[[\"@url\"],[[30,[36,7],[\"app/manager\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],[\"header.cta.my_account\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[[30,[36,1],[\"\\n    button-switcher-container\\n    \",[30,[36,0],[[32,1],\"is-switched\"],null],\"\\n  \"],null]],[[\"from\"],[\"@clark/cms-ui/components/cms-header/account-button/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"button\",[[17,2],[24,\"data-test-login-button\",\"\"],[16,\"aria-hidden\",[32,1]]],[[\"@url\"],[\"/login\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,3],[\"header.cta.login\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"button\",[[17,2],[24,\"data-test-adjust-link-signup-button\",\"\"],[16,\"aria-hidden\",[30,[36,6],[[32,1]],null]]],[[\"@url\",\"@appearance\"],[[30,[36,0],[[30,[36,5],[[30,[36,4],[\"any\"],null]],null],[32,3],\"/signup\"],null],\"primary\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[[30,[36,5],[[30,[36,4],[\"any\"],null]],null],[30,[36,3],[\"header.cta.mobile_install\"],null],[30,[36,3],[\"header.cta.start\"],null]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"local-class\",\"t\",\"is-mobile\",\"or\",\"not\",\"localize-url\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/cms-header/account-button/template.hbs"
    }
  });

  _exports.default = _default;
});