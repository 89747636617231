define("@clark/cms-api/services/session", ["exports", "ember-concurrency-decorators", "ember-concurrency-ts"], function (_exports, _emberConcurrencyDecorators, _emberConcurrencyTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SessionService = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, (_class = (_temp = class SessionService extends Ember.Service.extend(Ember.Evented) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isAuthenticated", _descriptor, this);

      _initializerDefineProperty(this, "trackingPreference", _descriptor2, this);

      _initializerDefineProperty(this, "api", _descriptor3, this);
    }

    get isLoading() {
      return (0, _emberConcurrencyTs.taskFor)(this.load).isRunning;
    }

    get trackingEnabled() {
      return Boolean(this.trackingPreference);
    }

    init() {
      super.init();
      (0, _emberConcurrencyTs.taskFor)(this.load).perform();
    }

    *load() {
      let response;

      try {
        response = yield this.api.get('current_user');
      } catch {
        return;
      }

      this.isAuthenticated = response.user !== undefined;

      if (this.isAuthenticated) {
        this.trackingPreference = response.user.third_party_tracking;
        this.trigger('authenticationSucceeded');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isAuthenticated", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "trackingPreference", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "api", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "load", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype)), _class));
  _exports.default = SessionService;
});