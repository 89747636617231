define("@clark/cms-ui/components/form-builder/input-field/email/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input-border-danger": "#D51E50",
    "input-form-error": "_input-form-error_fs920b",
    "input-form-error-message": "_input-form-error-message_fs920b"
  };
  _exports.default = _default;
});