window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"FEATURES":{"EMBER_NATIVE_DECORATOR_SUPPORT":true,"EMBER_METAL_TRACKED_PROPERTIES":true,"EMBER_GLIMMER_ANGLE_BRACKET_NESTED_LOOKUP":true,"EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS":true,"EMBER_GLIMMER_FN_HELPER":true,"EMBER_GLIMMER_ON_MODIFIER":true},"EXTEND_PROTOTYPES":{"Date":false},"_APPLICATION_TEMPLATE_WRAPPER":false,"_JQUERY_INTEGRATION":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true});

var runningTests = false;


