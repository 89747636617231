define("@clark/cms-ui/components/cms-footer/iconed-content/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "container": "_container_5r8gk0",
    "media-container": "_media-container_5r8gk0",
    "content-container": "_content-container_5r8gk0"
  };
  _exports.default = _default;
});