define("@clark/cms-ui/components/crosslink/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IBVCRVX3",
    "block": "{\"symbols\":[\"item\",\"&attrs\",\"@items\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"crosslink-list\"],[[\"from\"],[\"@clark/cms-ui/components/crosslink/list/styles\"]]]]]],[24,\"data-test-crosslink-list\",\"\"],[17,2],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"crosslink/item\",[],[[\"@link\",\"@title\",\"@content\",\"@imageSource\"],[[32,1,[\"link\"]],[32,1,[\"title\"]],[32,1,[\"content\"]],[32,1,[\"image\",\"url\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/crosslink/list/template.hbs"
    }
  });

  _exports.default = _default;
});