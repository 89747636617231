define("@clark/cms-ui/components/modal/close-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-primary-hover": "#094FCB",
    "cta-primary-pressed": "#103C8A",
    "icon-color": "#C3CBD9",
    "button-size": "4.4rem",
    "close-button": "_close-button_a4x43x",
    "placement-modal": "_placement-modal_a4x43x",
    "placement-screen": "_placement-screen_a4x43x"
  };
  _exports.default = _default;
});