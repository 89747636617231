define("@clark/cms-ui/components/key-listener/component", ["exports", "@glimmer/component", "shim-keyboard-event-key"], function (_exports, _component, _shimKeyboardEventKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // NOTE:: This component should be used for global keypresses only as it is not
  // scoped. This could for instance be listening for an Escape press in a Modal
  // in order to close it on Escape.
  let KeyListenerComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class KeyListenerComponent extends _component.default {
    handleKeyEvent(event) {
      if (event.key === this.args.key) {
        const areModifiersValid = ['alt', 'ctrl', 'meta', 'shift'].every(modifierName => {
          const expectedValue = this.args[modifierName];
          const isModifierPressed = event[`${modifierName}Key`];
          return expectedValue == null || expectedValue === isModifierPressed;
        });

        if (areModifiersValid) {
          this.args.handler(event);
        }
      }
    }

    validate() {
      (false && !(this.args.key != null) && Ember.assert('KeyListener: Must specify argument `key`', this.args.key != null));
      (false && !(this.args.handler != null) && Ember.assert('KeyListener: Must specify argument `handler`', this.args.handler != null));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleKeyEvent", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype)), _class));
  _exports.default = KeyListenerComponent;
});