define("ember-svg-jar/inlined/heimat-chevron-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.324 9.92l.117.122 9.278 10.673c.327.376.465.852.422 1.312.038.408-.067.83-.32 1.182l-.102.13-9.278 10.672-.117.122A1.75 1.75 0 0116.7 31.84l.1-.125 8.421-9.689-8.421-9.688a1.75 1.75 0 012.524-2.418z\"/>",
    "attrs": {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});