define("@zestia/ember-auto-focus/modifiers/auto-focus", ["exports", "ember-modifier", "@zestia/ember-auto-focus/utils/focus"], function (_exports, _emberModifier, _focus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AutoFocusModifier extends _emberModifier.default {
    didInstall() {
      const {
        disabled
      } = this.args.named;

      if (disabled) {
        return;
      }

      let el = this.element;
      const selector = this.args.positional[0];

      if (selector) {
        el = el.querySelector(selector);
      }

      if (el) {
        Ember.run.scheduleOnce('afterRender', this, _focus.default, el);
      }
    }

  }

  _exports.default = AutoFocusModifier;
});