define("@clark/cms-ui/utils/strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleize = titleize;

  function titleize(value = '') {
    return value.split(/(?:^|\s|-|\/)/).map(word => Ember.String.capitalize(word)).join(' ');
  }
});