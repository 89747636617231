define("@clark/cms-ui/components/ui/text-field/component", ["exports", "@glimmer/component", "@clark/cms-ui/components/ui/hint/component", "@clark/cms-ui/components/ui/labeled-field/component"], function (_exports, _component, _component2, _component3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * A text field is an input field that users can type into. It has a range of
   * options and supports several text formats including numbers.
   */
  let TextFieldComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class TextFieldComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isFocused", _descriptor, this);

      _defineProperty(this, "inputNode", void 0);
    }

    get id() {
      return this.args.id || Ember.guidFor(this);
    }

    get prefixId() {
      return `${this.id}:prefix`;
    }

    get suffixId() {
      return `${this.id}:suffix`;
    }

    get labelledBy() {
      if (!this.args.prefix && !this.args.suffix) {
        return null;
      }

      const value = [];

      if (this.args.prefix) {
        value.push(this.prefixId);
      }

      if (this.args.suffix) {
        value.push(this.suffixId);
      }

      value.unshift((0, _component3.labelId)(this.id));
      return value.join(' ');
    }

    get describedBy() {
      if (!this.args.errors && !this.args.helpText) {
        return null;
      }

      const value = [];

      if (this.args.errors && this.args.errors.length > 0) {
        this.args.errors.forEach((_, index) => {
          value.push((0, _component2.hintId)(this.id, 'error', index + 1));
        });
      }

      if (this.args.helpText) {
        value.push((0, _component2.hintId)(this.id));
      }

      return value.join(' ');
    }

    get inputType() {
      if (this.args.type === 'currency') {
        return 'text';
      }

      return this.args.type || 'text';
    }

    handleChange({
      currentTarget
    }) {
      this.args.onChange && this.args.onChange(currentTarget.value);
    }

    handleInput({
      currentTarget
    }) {
      this.args.onInput && this.args.onInput(currentTarget.value);
    }

    handleClick() {
      this.inputNode.focus();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isFocused", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype)), _class));
  _exports.default = TextFieldComponent;
});