define("@clark/cms-ui/components/numbered-list/item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "text-color": "#203151",
    "numbered-list-item": "_numbered-list-item_52ggcz",
    "icon": "_icon_52ggcz",
    "text": "_text_52ggcz",
    "orientation-vertical": "_orientation-vertical_52ggcz",
    "orientation-desktop-vertical": "_orientation-desktop-vertical_52ggcz"
  };
  _exports.default = _default;
});