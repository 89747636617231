define("@clark/cms-ui/components/common/cms-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+GiajcDW",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"section\"],[16,0,[31,[[30,[36,1],[\"container\"],null],\" \",[30,[36,0],[\"section-container\"],[[\"from\"],[\"@clark/cms-ui/components/common/cms-section/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,3],null,[[\"header\",\"body\",\"footer\"],[[30,[36,2],[\"common/cms-section/header\"],null],[30,[36,2],[\"common/cms-section/body\"],null],[30,[36,2],[\"common/cms-section/footer\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"grid\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/cms-section/template.hbs"
    }
  });

  _exports.default = _default;
});