define("@clark/cms-ui/components/form-builder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rMo08Hv/",
    "block": "{\"symbols\":[\"field\",\"@form\",\"&attrs\",\"@inputFields\"],\"statements\":[[1,[30,[36,3],[[32,0,[\"args\"]]],null]],[2,\"\\n\\n\"],[11,\"form\"],[16,0,[30,[36,0],[\"row\"],null]],[17,3],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[30,[36,0],[\"col-12 col-md-6\"],null]],[12],[2,\"\\n      \"],[8,\"form-builder/input-field\",[[16,\"data-test-input-field\",[32,1,[\"name\"]]]],[[\"@label\",\"@name\",\"@value\",\"@placeholder\",\"@fieldType\",\"@onChange\"],[[32,1,[\"label\"]],[32,1,[\"name\"]],[30,[36,1],[[30,[36,1],[[32,2],[32,1,[\"name\"]]],null],\"value\"],null],[32,1,[\"placeholder\"]],[32,1,[\"fieldType\"]],[30,[36,2],[[32,0,[\"updateValue\"]],[32,1,[\"name\"]]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"grid\",\"get\",\"fn\",\"form-builder/validate-args\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/template.hbs"
    }
  });

  _exports.default = _default;
});