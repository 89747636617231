define("@clark/cms-ui/components/common/flyout/menu/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "c-white": "#FFFFFF",
    "menu": "_menu_orgkrr",
    "menu-slide-in": "_menu-slide-in_orgkrr",
    "slidein": "_slidein_orgkrr",
    "menu-slide-out": "_menu-slide-out_orgkrr",
    "slideout": "_slideout_orgkrr"
  };
  _exports.default = _default;
});