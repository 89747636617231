define("@clark/cms-api/errors/response", ["exports", "@clark/cms-api/errors/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Thrown when the HTTP status code is not okay or the JSON response contains
   * errors.
   */
  class ResponseError extends _api.default {
    constructor(request, response, body) {
      super(request, response);

      _defineProperty(this, "body", void 0);

      _defineProperty(this, "response", void 0);

      this.body = body;
    }

    get message() {
      let message;

      if (typeof this.body === 'string') {
        message = 'Received non-JSON response';
      } else if (typeof this.body === 'object') {
        if ('error' in this.body) {
          message = this.body.error;
        } else if ('errors' in this.body) {
          const errors = this.body.errors;

          switch (errors.length) {
            case 0:
              message = 'unknown error';
              break;

            case 1:
              [message] = errors;
              break;

            default:
              message = [`${errors.length} errors occured:`, errors].join('\n  -> ');
          }
        } // eslint-disable-next-line no-negated-condition

      } else if (!this.response.ok) {
        message = 'HTTP status code is not okay';
      } else {
        message = 'unknown error';
      }

      return this.response ? `${this.response.status} - ${this.response.statusText} - ${this.url}: ${message}` : message;
    }

    get status() {
      return this.response.status;
    }

    get sentryExtra() {
      return Ember.getProperties(this, 'request', 'response', 'body', 'requestHeaders', 'responseHeaders');
    }

  }

  _exports.default = ResponseError;
});