define("@clark/cms-ui/components/cms-header/nav-item/dropdown-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZeXZY/D/",
    "block": "{\"symbols\":[\"@position\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,2],[[30,[36,0],[\"dropdown-menu \",[30,[36,1],[[32,1],[30,[36,0],[\"menu-\",[32,1]],null]],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/cms-header/nav-item/dropdown-menu/styles\"]]]]]],[17,2],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/cms-header/nav-item/dropdown-menu/template.hbs"
    }
  });

  _exports.default = _default;
});