define("@clark/cms-ui/components/form-builder/input-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HpUAFDyW",
    "block": "{\"symbols\":[\"@placeholder\",\"@value\",\"@name\",\"&attrs\",\"@onChange\",\"@fieldType\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"input-field-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/styles\"]]]]]],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,6],\"phone\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"form-builder/input-field/phone\",[[17,4]],[[\"@placeholder\",\"@value\",\"@name\",\"@onChange\"],[[32,1],[32,2],[32,3],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,6],\"email\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"form-builder/input-field/email\",[[16,0,[31,[[30,[36,0],[\"form-input\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/styles\"]]]]]],[17,4]],[[\"@placeholder\",\"@value\",\"@name\",\"@onChange\"],[[32,1],[32,2],[32,3],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"input\"],[16,0,[31,[[30,[36,0],[\"form-input\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/styles\"]]]]]],[16,\"placeholder\",[32,1]],[16,2,[32,2]],[16,3,[32,3]],[17,4],[4,[38,1],[\"input\",[32,0,[\"onChange\"]]],null],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"on\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/template.hbs"
    }
  });

  _exports.default = _default;
});