define("@clark/cms-ui/components/responsive-image/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeMaxWidthValue = normalizeMaxWidthValue;
  _exports.default = void 0;

  function normalizeMaxWidthValue(sourceValue) {
    if (!sourceValue) return undefined;
    const numberSourceValue = Number(sourceValue);

    if (!Number.isNaN(numberSourceValue)) {
      return `${numberSourceValue}px`;
    }

    if (typeof sourceValue === 'string' && sourceValue.endsWith('%')) {
      return sourceValue;
    } // Consider all other variants invalid and fallback to default styling


    return undefined;
  }

  class ResponsiveImageComponent extends _component.default {
    get desktopImage() {
      if (!this.args.desktopImage) {
        return this.args.defaultImage;
      }

      return this.args.desktopImage;
    }

    get defaultImageMaxWidth() {
      return normalizeMaxWidthValue(this.args.defaultImageMaxWidth);
    }

    get desktopImageMaxWidth() {
      return normalizeMaxWidthValue(this.args.desktopImageMaxWidth);
    }

  }

  _exports.default = ResponsiveImageComponent;
});