define("ember-composable-helpers/helpers/pick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pick = pick;
  _exports.default = void 0;

  function pick([path, action]
  /*, hash*/
  ) {
    return function (event) {
      let value = Ember.get(event, path);

      if (!action) {
        return value;
      }

      action(value);
    };
  }

  var _default = Ember.Helper.helper(pick);

  _exports.default = _default;
});