define("@clark/cms-ui/helpers/contact-box/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateArgs = validateArgs;
  _exports.default = void 0;

  function validateArgs([args]) {
    (false && !(typeof args.avatar.url === 'string') && Ember.assert('Contact box: Need an image path for avatar [MISSING AVATAR IMAGE]', typeof args.avatar.url === 'string'));
    (false && !(Object.keys(args.title).length > 0) && Ember.assert('Contact box: Need a title [MISSING TITLE]', Object.keys(args.title).length > 0));
    (false && !(Boolean(args.description)) && Ember.assert('Contact box: Need a description [MISSING DESCRIPTION]', Boolean(args.description)));
  }

  var _default = Ember.Helper.helper(validateArgs);

  _exports.default = _default;
});