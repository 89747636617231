define("@clark/cms-ui/components/modal/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nmd7PBjN",
    "block": "{\"symbols\":[\"Wrapper\",\"&default\",\"@isSectioned\",\"&attrs\",\"@isLoading\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,3],[\"body-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/modal/body/styles\"]]]]]],[12],[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[[30,[36,3],[\"body\"],[[\"from\"],[\"@clark/cms-ui/components/modal/body/styles\"]]]]]],[17,4],[12],[2,\"\\n\"],[6,[37,0],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[[30,[36,3],[\"spinner\"],[[\"from\"],[\"@clark/cms-ui/components/modal/body/styles\"]]]]]],[12],[2,\"\\n        \"],[8,\"spinner\",[],[[\"@size\",\"@accessibilityLabel\"],[\"large\",[30,[36,4],[\"modal.spinner_accessibility_label\"],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,0],[[32,3],\"modal/section\",\"blank-component\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[18,2,null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"let\",\"local-class\",\"t\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/body/template.hbs"
    }
  });

  _exports.default = _default;
});