define("ember-svg-jar/inlined/siegel-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.092 19.668l-4.2 4.192a.48.48 0 01-.818-.302L3.846 20.6a.48.48 0 00-.441-.441l-2.962-.227a.48.48 0 01-.302-.817l3.595-3.589a10.094 10.094 0 005.356 4.14zm14.767-.552l-3.328-3.323a10.09 10.09 0 01-5.522 3.975l4.099 4.092a.48.48 0 00.818-.302l.228-2.957a.48.48 0 01.441-.441l2.962-.227a.48.48 0 00.302-.817zM12 5.792a4.316 4.316 0 00-4.32 4.313A4.316 4.316 0 0012 14.417c2.386 0 4.32-1.93 4.32-4.312A4.321 4.321 0 0012 5.792zm0 13.417c-5.037 0-9.12-4.076-9.12-9.104C2.88 5.076 6.962 1 12 1c5.037 0 9.12 4.076 9.12 9.105-.005 5.025-4.085 9.098-9.12 9.104zm5.28-9.104A5.276 5.276 0 0012 4.833a5.276 5.276 0 00-5.28 5.272 5.276 5.276 0 005.28 5.27c1.4 0 2.743-.555 3.734-1.543a5.266 5.266 0 001.546-3.727z\" fill=\"#17203D\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});