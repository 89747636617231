define("@clark/cms-ui/components/common/divider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8fEZimuH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"hr\"],[15,0,[31,[[30,[36,0],[\"divider\"],[[\"from\"],[\"@clark/cms-ui/components/common/divider/styles\"]]]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/divider/template.hbs"
    }
  });

  _exports.default = _default;
});