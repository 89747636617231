define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de-at", {
    "404": {
      "cta": "zur Startseite",
      "title": "Sorry! Wir konnten die gesuchte Seite nicht finden."
    },
    "app-store": {
      "free": "Kostenlos",
      "in-app-store": "Im App Store",
      "insurance-manager": "Versicherungsmanager",
      "link-to-mobile-app": "Link zur mobilen App"
    },
    "breadcrumbs": {
      "accessibility_label": "Breadcrumbs",
      "root_label": "Clark"
    },
    "button": {
      "spinner_accessibility_label": "Wird geladen"
    },
    "carousel": {
      "navigation": {
        "next": "Nächste Ansicht",
        "previous": "Vorherige Ansicht"
      }
    },
    "common": {
      "new_window_accessibility_label": "(öffnet ein neues Fenster)"
    },
    "cookie_banner": {
      "accept_all_cta": "Alle Akzeptieren",
      "accept_cta": "Akzeptieren",
      "configure_cta": "Konfigurieren",
      "deny_cta": "Alle Ablehnen",
      "description": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Wähle ”Akzeptieren”, um alle gewählten Arten von Cookies zu akzeptieren.",
      "description_collapsed": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Die gesammelten Daten werden zur Optimierung der Website und App genutzt, um deine Erfahrung zu optimieren und personalisieren. Die Daten werden im Rahmen unserer EU-weiten Tätigkeit genutzt. Wähle ”Akzeptieren”, um alle Cookies zu akzeptieren.",
      "error": "Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
      "header": "Wir verwenden Cookies",
      "more_info_link": "/datenschutz",
      "more_info_text": "Mehr erfahren",
      "save_cta": "Speichern",
      "tracking": {
        "analytics": {
          "description": "Diese Cookies sind notwendig, um dir eine sichere und funktionale Nutzung von Clark zu ermöglichen. Du kannst sie nicht de-aktivieren.",
          "header": "Funktionale Cookies"
        },
        "marketing": {
          "description": "Diese Cookies helfen uns zu verstehen, wie effektiv unsere Marketingkampagnen sind und helfen uns, Clark für dich zu optimieren. Wir übermitteln dazu pseudonymisierte Daten an unsere Marketingpartner.",
          "header": "Tracking & Marketing"
        }
      }
    },
    "crosslink": {
      "appendable-list": {
        "reveal-button-text": "weitere Artikel"
      },
      "item": {
        "view-article-link": "zum Artikel"
      }
    },
    "footer": {
      "authorization": {
        "approval": "Kund:innenzufriedenheit",
        "title": "Ausgezeichnete"
      },
      "companies": {
        "about_us": "Über uns",
        "career": "Karriere",
        "partnerships": "CLARK Partnerprogramm",
        "press": "Presse",
        "title": "Unternehmen"
      },
      "contact": {
        "email": "service@goclark.at",
        "phone": "01 3860870",
        "timings": "(Mo. - Fr. 08 - 19 Uhr)",
        "title": "Kontakt"
      },
      "copyright": {
        "conditions": "AGB",
        "consent_management": "Cookie Einstellungen",
        "data_protection": "Datenschutz",
        "imprint": "Impressum",
        "initial_information": "Erstinformation",
        "sustainablity_information": "Nachhaltigkeitsinformationen",
        "title": "&#169; Clark GMBH 2019"
      },
      "datacenter": {
        "location": "in Deutschland",
        "title": "Rechenzentrum"
      },
      "information": {
        "function": "So funktioniert’s",
        "magazine": "CLARK Magazine",
        "partner": "Partner",
        "title": "Informationen"
      }
    },
    "forms": {
      "email": {
        "error_message": "Ungültige E-Mailadresse"
      }
    },
    "header": {
      "cta": {
        "get_an_offer": "Angebot erhalten",
        "login": "Einloggen",
        "mobile_install": "Installieren",
        "my_account": "Mein Konto",
        "start": "Jetzt starten"
      },
      "links": {
        "how": "So funktioniert’s",
        "insurances": {
          "subMenu": {
            "health": {
              "links": {
                "disability": "Berufsunfähigkeitsversicherung",
                "private": "Private Krankenzusatzversicherung"
              },
              "title": "Gesundheit & Existenz"
            },
            "pension": {
              "links": {
                "company_scheme": "Betriebliche Altersvorsorge",
                "private_scheme": "Private Altersvorsorge & Vermögensaufbau",
                "risky_scheme": "Risikolebensversicherung"
              },
              "title": "Altersvorsorge"
            },
            "property": {
              "links": {
                "car": "Kfz-Versicherung",
                "house": "Haushaltsversicherung",
                "law": "Rechtsschutzversicherung"
              },
              "title": "Besitz\t& Eigentum"
            }
          },
          "title": "Versicherungen"
        }
      }
    },
    "lead-gen": {
      "high-margin-form": {
        "email": {
          "label": "E-Mail"
        },
        "first-name": {
          "label": "Vorname"
        },
        "last-name": {
          "label": "Nachname"
        },
        "phone-number": {
          "label": "Telefonnummer"
        }
      }
    },
    "lead_gen": {
      "terms_and_condition": "Ich bin damit einverstanden, dass CLARK mich per E-mail und zur Klärung von Rückfragen telefonisch kontaktiert. Es gelten unsere Bestimmungen für den Datenschutz."
    },
    "magazine": {
      "home-page": "CLARK Startseite",
      "title": "Magazine"
    },
    "name": "Clark",
    "next-steps": {
      "title": "Nächste Schritte"
    },
    "partners": {
      "partner_of": "Partner von",
      "partner_of_label": "Partner von {name}"
    },
    "preview": {
      "error-screen": {
        "hint": "Stelle sicher, dass du eine valide ID für einen existierenden Eintrag verwendest.",
        "message": "Konnte keine Vorschau für den angeforderten Eintrag generieren"
      },
      "not-found-screen": {
        "message": "Hoppla! Sorry, hierfür gibt’s leider keine Vorschau"
      }
    }
  }], ["de-de", {
    "404": {
      "cta": "zur Startseite",
      "title": "Sorry! Wir konnten die gesuchte Seite nicht finden."
    },
    "app-store": {
      "free": "Kostenlos",
      "in-app-store": "Im App Store",
      "insurance-manager": "Versicherungsmanager",
      "link-to-mobile-app": "Link zur mobilen App"
    },
    "breadcrumbs": {
      "accessibility_label": "Breadcrumbs",
      "root_label": "Clark"
    },
    "button": {
      "spinner_accessibility_label": "Wird geladen"
    },
    "carousel": {
      "navigation": {
        "next": "Nächste Ansicht",
        "previous": "Vorherige Ansicht"
      }
    },
    "common": {
      "close": "Schließen",
      "new_window_accessibility_label": "(öffnet ein neues Fenster)"
    },
    "cookie_banner": {
      "accept_all_cta": "Alle Cookies akzeptieren",
      "accept_cta": "Alle Cookies akzeptieren",
      "configure_cta": "Mehr Informationen",
      "deny_cta": "Ablehnen",
      "description": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Wähle ”Akzeptieren”, um alle gewählten Arten von Cookies zu akzeptieren.",
      "description_collapsed": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Die gesammelten Daten werden zur Optimierung der Website und App genutzt, um deine Erfahrung zu optimieren und personalisieren. Die Daten werden im Rahmen unserer EU-weiten Tätigkeit genutzt. Wähle ”Akzeptieren”, um alle Cookies zu akzeptieren.",
      "error": "Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
      "header": "Einstellungen zum Datenschutz",
      "more_info_link": "/datenschutz",
      "more_info_text": "Mehr erfahren",
      "save_cta": "Auswahl bestätigen",
      "tracking": {
        "analytics": {
          "description": "Diese Cookies sind notwendig, um dir eine sichere und funktionale Nutzung von Clark zu ermöglichen. Du kannst sie nicht de-aktivieren.",
          "header": "Funktionale Cookies"
        },
        "marketing": {
          "description": "Diese Cookies helfen uns zu verstehen, wie effektiv unsere Marketingkampagnen sind und helfen uns, Clark für dich zu optimieren. Wir übermitteln dazu pseudonymisierte Daten an unsere Marketingpartner.",
          "header": "Tracking & Marketing"
        }
      }
    },
    "crosslink": {
      "appendable-list": {
        "reveal-button-text": "weitere Artikel"
      },
      "item": {
        "view-article-link": "zum Artikel"
      }
    },
    "footer": {
      "authorization": {
        "approval": "Kund:innenzufriedenheit",
        "title": "Ausgezeichnete"
      },
      "companies": {
        "about_us": "Über uns",
        "career": "Karriere",
        "partnerships": "CLARK Partnerprogramm",
        "press": "Presse",
        "title": "Unternehmen"
      },
      "contact": {
        "email": "kundenservice@clark.de",
        "phone": "069 153 229 339",
        "timings": "(Mo. - Fr. 08 - 20 Uhr, Sa. 09 - 16 Uhr)",
        "title": "Kontakt"
      },
      "copyright": {
        "conditions": "AGB",
        "consent_management": "Cookie Einstellungen",
        "data_protection": "Datenschutz",
        "imprint": "Impressum",
        "initial_information": "Erstinformation",
        "sustainablity_information": "Nachhaltigkeitsinformationen",
        "title": "&#169; Clark Germany GMBH 2019"
      },
      "datacenter": {
        "location": "in Deutschland",
        "title": "Rechenzentrum"
      },
      "information": {
        "function": "So funktioniert’s",
        "magazine": "CLARK Magazine",
        "partner": "Partner",
        "title": "Informationen"
      }
    },
    "forms": {
      "email": {
        "error_message": "Ungültige E-Mailadresse"
      }
    },
    "header": {
      "cta": {
        "get_an_offer": "Angebot erhalten",
        "login": "Einloggen",
        "mobile_install": "Installieren",
        "my_account": "Mein Konto",
        "start": "Jetzt starten"
      },
      "links": {
        "how": "So funktioniert’s",
        "insurances": {
          "subMenu": {
            "health": {
              "links": {
                "disability": "Berufsunfähigkeitsversicherung",
                "private": "Private Krankenversicherung"
              },
              "title": "Gesundheit & Eigentum"
            },
            "pension": {
              "links": {
                "company_scheme": "Betriebliche Altersvorsorge",
                "private_scheme": "Private Altersvorsorge",
                "risky_scheme": "Risikolebensversicherung"
              },
              "title": "Altersvorsorge"
            },
            "property": {
              "links": {
                "car": "Kfz-Versicherung",
                "house": "Hausratversicherung",
                "law": "Rechtsschutzversicherung",
                "liability": "Privathaftpflicht­versicherung"
              },
              "title": "Besitz\t& Eigentum"
            }
          },
          "title": "Versicherungen"
        }
      }
    },
    "lead-gen": {
      "high-margin-form": {
        "email": {
          "label": "E-Mail"
        },
        "first-name": {
          "label": "Vorname"
        },
        "last-name": {
          "label": "Nachname"
        },
        "phone-number": {
          "label": "Telefonnummer"
        }
      }
    },
    "lead_gen": {
      "terms_and_condition": "Ich bin damit einverstanden, dass CLARK Germany mich per E-mail und zur Klärung von Rückfragen telefonisch kontaktiert. Es gelten unsere Bestimmungen für den Datenschutz."
    },
    "magazine": {
      "home-page": "CLARK Startseite",
      "title": "Magazine"
    },
    "modal": {
      "spinner_accessibility_label": "Wird geladen"
    },
    "name": "Clark",
    "next-steps": {
      "title": "Nächste Schritte"
    },
    "partners": {
      "partner_of": "Partner von",
      "partner_of_label": "Partner von {name}"
    },
    "payback": {
      "total": "Gesamt: {total} Punkte"
    },
    "preview": {
      "error-screen": {
        "hint": "Stelle sicher, dass du eine valide ID für einen existierenden Eintrag verwendest.",
        "message": "Konnte keine Vorschau für den angeforderten Eintrag generieren"
      },
      "not-found-screen": {
        "message": "Hoppla! Sorry, hierfür gibt’s leider keine Vorschau"
      }
    }
  }], ["en-us", {
    "404": {
      "cta": "zur Startseite",
      "title": "Sorry! Wir konnten die gesuchte Seite nicht finden."
    },
    "app-store": {
      "free": "Free",
      "in-app-store": "In App Store",
      "insurance-manager": "Insurance manager",
      "link-to-mobile-app": "Link to mobile App"
    },
    "breadcrumbs": {
      "accessibility_label": "Breadcrumbs",
      "root_label": "Clark"
    },
    "button": {
      "spinner_accessibility_label": "Loading"
    },
    "carousel": {
      "navigation": {
        "next": "Next slide",
        "previous": "Previous slide"
      }
    },
    "common": {
      "close": "Close",
      "new_window_accessibility_label": "(opens a new window)"
    },
    "cookie_banner": {
      "accept_all_cta": "Alle Akzeptieren",
      "accept_cta": "Akzeptieren",
      "configure_cta": "Konfigurieren",
      "deny_cta": "Alle Ablehnen",
      "description": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Wähle ”Akzeptieren”, um alle gewählten Arten von Cookies zu akzeptieren.",
      "description_collapsed": "Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Die gesammelten Daten werden zur Optimierung der Website und App genutzt, um deine Erfahrung zu optimieren und personalisieren. Die Daten werden im Rahmen unserer EU-weiten Tätigkeit genutzt. Wähle ”Akzeptieren”, um alle Cookies zu akzeptieren.",
      "error": "Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
      "header": "Wir verwenden Cookies",
      "more_info_link": "/datenschutz",
      "more_info_text": "Mehr erfahren",
      "save_cta": "Speichern",
      "tracking": {
        "analytics": {
          "description": "Diese Cookies sind notwendig, um dir eine sichere und funktionale Nutzung von Clark zu ermöglichen. Du kannst sie nicht de-aktivieren.",
          "header": "Funktionale Cookies"
        },
        "marketing": {
          "description": "Diese Cookies helfen uns zu verstehen, wie effektiv unsere Marketingkampagnen sind und helfen uns, Clark für dich zu optimieren. Wir übermitteln dazu pseudonymisierte Daten an unsere Marketingpartner.",
          "header": "Tracking & Marketing"
        }
      }
    },
    "crosslink": {
      "appendable-list": {
        "reveal-button-text": "More Articles"
      },
      "item": {
        "view-article-link": "Read Article"
      }
    },
    "header": {
      "cta": {
        "get_an_offer": "Get an Offer"
      }
    },
    "lead-gen": {
      "high-margin-form": {
        "email": {
          "label": "E-Mail"
        },
        "first-name": {
          "label": "First name"
        },
        "last-name": {
          "label": "Last name"
        },
        "phone-number": {
          "label": "Phone number"
        }
      }
    },
    "lead_gen": {
      "terms_and_condition": "Ich bin damit einverstanden, dass CLARK Germany mich per E-mail und zur Klärung von Rückfragen telefonisch kontaktiert. Es gelten unsere Bestimmungen für den Datenschutz."
    },
    "magazine": {
      "home-page": "CLARK Homepage",
      "title": "Magazine"
    },
    "modal": {
      "spinner_accessibility_label": "Loading"
    },
    "name": "Clark",
    "next-steps": {
      "title": "Next Steps"
    },
    "partners": {
      "partner_of": "Partner of",
      "partner_of_label": "Partner of {name}"
    },
    "payback": {
      "total": "Total: {total} points"
    },
    "preview": {
      "error-screen": {
        "hint": "Make sure you're using a valid ID of existing entry.",
        "message": "Couldn't render a preview for the requested entry"
      },
      "not-found-screen": {
        "message": "Oops! Sorry, nothing to preview here"
      }
    }
  }]];
  _exports.default = _default;
});