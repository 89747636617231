define("@clark/cms-ui/modifiers/on-viewport-relative-scroll", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OnViewportRelativeScroll extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "observer", void 0);
    }

    didInstall() {
      this.observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          this.onBackIntoView();
        } else {
          // Since sentinel is of `height: 0` in CSS, its sufficient to check
          // the `boundingClientRect`'s `top` peroperty - if it's negative, the
          // element is hidden beneath the top bound of the root
          // (viewport in our case).
          const isScrollBelow = entry.boundingClientRect.top < 0;

          if (isScrollBelow) {
            this.onScrollBelow();
          }
        }
      }, {
        rootMargin: '0px',
        threshold: 0
      });
      this.observer.observe(this.element);
    }

    willRemove() {
      if (this.observer) {
        this.observer.disconnect();
      }
    } // NOTE: The rAF in these methods is necessary so that the effect
    // of a callback invocation doesn't interfere with the rendering
    // or internal scheduling and doesn't get out of sync easily
    // (e.g., the way `TransitionClassProvider` applies animation classes
    // gets out of sync). We can't fix this per use-cases in the components
    // because components shouldn't be aware of rAF at all and this
    // seems to be a nice place to wrap into it.
    // Feel free to create a ticket once better solution is found.


    onScrollBelow() {
      const {
        onScrollBelow
      } = this.args.named;

      if (onScrollBelow) {
        window.requestAnimationFrame(() => {
          if (!this.isDestroyed) onScrollBelow();
        });
      }
    }

    onBackIntoView() {
      const {
        onBackIntoView
      } = this.args.named;

      if (onBackIntoView) {
        window.requestAnimationFrame(() => {
          if (!this.isDestroyed) onBackIntoView();
        });
      }
    }

  }

  _exports.default = OnViewportRelativeScroll;
});