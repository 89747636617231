define("@clark/cms-ui/components/hero/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "breakpoint-medium": "769px",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "image-height-mobile": "384px",
    "container-height": "720px",
    "hero": "_hero_dnormc",
    "hero-container": "_hero-container_dnormc",
    "hero-image-container": "_hero-image-container_dnormc",
    "hero-image-wrapper": "_hero-image-wrapper_dnormc",
    "hero-image": "_hero-image_dnormc",
    "hero-content": "_hero-content_dnormc",
    "hero-title": "_hero-title_dnormc",
    "hero-description": "_hero-description_dnormc",
    "hero-buttons": "_hero-buttons_dnormc"
  };
  _exports.default = _default;
});