define("@clark/cms-ui/components/form-builder/input-field/phone/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PhoneInput = (_dec = Ember._action, (_class = class PhoneInput extends _component.default {
    isValid(value) {
      return value.length > 2;
    }

    onChange(event) {
      let {
        target: {
          value
        }
      } = event;
      value = value.replace(/[^\d]/gi, ''); // eslint-disable-next-line no-param-reassign

      event.target.value = value;
      this.args.onChange(value, this.isValid(value));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onChange", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
  _exports.default = PhoneInput;
});