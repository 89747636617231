define("@clark/cms-ui/helpers/seo-hero/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateSeoHeroArgs = validateSeoHeroArgs;
  _exports.default = void 0;

  function validateSeoHeroArgs([args]) {
    (false && !(Boolean(args.title)) && Ember.assert(`Seo Hero missing title`, Boolean(args.title)));
    (false && !(Boolean(args.description)) && Ember.assert(`Seo Hero missing description`, Boolean(args.description)));
  }

  var _default = Ember.Helper.helper(validateSeoHeroArgs);

  _exports.default = _default;
});