define("@clark/cms-ui/components/button/content/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ButtonContent extends _component.default {
    get spinnerColor() {
      return String(this.args.appearance) === 'primary' ? 'white' : 'blue';
    }

  }

  _exports.default = ButtonContent;
});