define("@clark/cms-ui/components/unstyled-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+VTBupzs",
    "block": "{\"symbols\":[\"@url\",\"@isExternal\",\"@download\",\"&attrs\",\"&default\"],\"statements\":[[11,\"a\"],[16,6,[32,1]],[16,\"target\",[30,[36,0],[[32,2],\"_blank\"],null]],[16,\"rel\",[30,[36,0],[[32,2],\"noopener noreferrer\"],null]],[16,\"download\",[32,3]],[17,4],[12],[2,\"\\n  \"],[18,5,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/unstyled-link/template.hbs"
    }
  });

  _exports.default = _default;
});