define("@clark/cms-ui/components/button/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rOEyip6l",
    "block": "{\"symbols\":[\"&default\",\"@icon\",\"@isLoading\"],\"statements\":[[10,\"span\"],[15,0,[31,[[30,[36,0],[\"content\"],[[\"from\"],[\"@clark/cms-ui/components/button/content/styles\"]]]]]],[12],[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[\"spinner\"],[[\"from\"],[\"@clark/cms-ui/components/button/content/styles\"]]]]]],[12],[2,\"\\n      \"],[8,\"spinner\",[],[[\"@color\",\"@accessibilityLabel\"],[[32,0,[\"spinnerColor\"]],[30,[36,2],[\"button.spinner_accessibility_label\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[\"icon\"],[[\"from\"],[\"@clark/cms-ui/components/button/content/styles\"]]]]]],[12],[2,\"\\n      \"],[8,\"icon\",[],[[\"@source\"],[[30,[36,1],[[32,3],\"placeholder\",[32,2]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/button/content/template.hbs"
    }
  });

  _exports.default = _default;
});