define("@clark/cms-ui/components/cms-footer/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C4guWIwF",
    "block": "{\"symbols\":[\"&attrs\",\"@url\",\"@isExternal\",\"&default\"],\"statements\":[[11,\"li\"],[16,0,[31,[[30,[36,0],[\"link-item\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/link/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[8,\"link\",[[16,0,[31,[[30,[36,0],[\"link\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/link/styles\"]]]]]]],[[\"@url\",\"@isSubdued\",\"@isExternal\",\"@hideExternalIcon\"],[[32,2],true,[32,3],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,4,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/cms-footer/link/template.hbs"
    }
  });

  _exports.default = _default;
});