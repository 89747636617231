define("ember-svg-jar/inlined/house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>house</title><path d=\"M19.967 9.023V21.5a.5.5 0 01-.5.5H18v1h2.5a.5.5 0 00.5-.5V10h.033zM7 16h2v2H7z\" fill=\"#e8e5fe\"/><path d=\"M17.063 22H4v.5c0 .276.42.5.938.5H19v-1z\" fill=\"#e8e5fe\"/><path d=\"M24.838 12.131l-12-11a.501.501 0 00-.676 0l-12 11a.5.5 0 00.676.738L3 10.887V22.5A1.5 1.5 0 004.5 24h16a1.5 1.5 0 001.5-1.5V10.887l2.162 1.982a.5.5 0 10.676-.738zM13 23v-7h4v7zm8-13v12.5a.5.5 0 01-.5.5H18v-7a1 1 0 00-1-1h-4a1 1 0 00-1 1v7H4.5a.5.5 0 01-.5-.5V10h-.033L12.5 2.178 21.033 10z\" fill=\"#8075f2\"/><path d=\"M9 15H7a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1zm0 3H7v-2h2z\" fill=\"#8075f2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});