define("@clark/cms-ui/helpers/icon/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.iconValidateArgs = iconValidateArgs;
  _exports.default = void 0;

  function iconValidateArgs([args]) {
    (false && !(typeof args.source === 'string') && Ember.assert('Icon: Missing source argument', typeof args.source === 'string'));
  }

  var _default = Ember.Helper.helper(iconValidateArgs);

  _exports.default = _default;
});