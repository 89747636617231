define("ember-intl/-private/utils/parse", ["exports", "intl-messageformat-parser"], function (_exports, _intlMessageformatParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseString;

  /**
   * @private
   * @hide
   */
  function parseString(string) {
    return (0, _intlMessageformatParser.parse)(string, {
      normalizeHashtagInPlural: false
    });
  }
});