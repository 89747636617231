define("ember-svg-jar/inlined/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"50\" cy=\"50\" fill=\"none\" stroke=\"#2b6cde\" stroke-width=\"8\" r=\"35\" stroke-dasharray=\"164.93361431346415 56.97787143782138\" transform=\"rotate(95.363 50 50)\"><animateTransform attributeName=\"transform\" type=\"rotate\" calcMode=\"linear\" values=\"0 50 50;360 50 50\" keyTimes=\"0;1\" dur=\"1s\" begin=\"0s\" repeatCount=\"indefinite\"/></circle>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100",
      "preserveAspectRatio": "xMidYMid",
      "class": "lds-rolling",
      "style": "background:0 0"
    }
  };
  _exports.default = _default;
});