define("@clark/cms-ui/components/crosslink/carousel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "from-desktop": "only screen and (min-width: 1200px)",
    "rich-black": "#01051C",
    "medium-columns": "2",
    "desktop-columns": "3",
    "column-spacing": "32px",
    "arrow-spacing-small": "16px",
    "arrow-spacing-medium": "24px",
    "arrow-color-disabled": "#cfd3da",
    "item-focus-outline-width": "2px",
    "crosslink-carousel": "_crosslink-carousel_1o34jv",
    "prev-button": "_prev-button_1o34jv",
    "next-button": "_next-button_1o34jv",
    "nav-button-icon": "_nav-button-icon_1o34jv",
    "swiper-container": "_swiper-container_1o34jv"
  };
  _exports.default = _default;
});