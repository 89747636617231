define("ember-svg-jar/inlined/unfall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.06 8.132a2.5 2.5 0 000 3.535l1.586 1.587a.5.5 0 10.708-.707L1.768 10.96a1.5 1.5 0 010-2.121l7.071-7.071a1.5 1.5 0 012.121 0l1.586 1.586a.5.5 0 00.708-.708L11.667 1.06a2.5 2.5 0 00-3.535 0L1.06 8.133zM17.254 26.447a.5.5 0 00-.707.707l1.484 1.484a2.5 2.5 0 003.536 0l7.071-7.071a2.5 2.5 0 000-3.536l-1.484-1.484a.5.5 0 00-.707.707l1.484 1.484a1.5 1.5 0 010 2.122l-7.071 7.07a1.5 1.5 0 01-2.122.001l-1.484-1.485zM16 13a1 1 0 11-2 0 1 1 0 012 0zM17 16a1 1 0 100-2 1 1 0 000 2zM16 17a1 1 0 11-2 0 1 1 0 012 0zM13 16a1 1 0 100-2 1 1 0 000 2z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.567 1.06a2.5 2.5 0 00-3.536 0L1.061 18.032a2.5 2.5 0 000 3.536l7.07 7.07a2.5 2.5 0 003.536 0l16.97-16.97a2.5 2.5 0 000-3.535l-7.07-7.071zm-2.829.708a1.5 1.5 0 012.122 0l7.07 7.07a1.5 1.5 0 010 2.122L10.96 27.93a1.5 1.5 0 01-2.121 0l-7.071-7.07a1.5 1.5 0 010-2.122l16.97-16.97z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});