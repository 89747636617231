define("@clark/cms-ui/components/common/accordion/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AccordionItemComponent = (_dec = Ember._action, (_class = class AccordionItemComponent extends _component.default {
    get id() {
      return Ember.guidFor(this);
    }

    animateAccordion(event) {
      const element = document.querySelector(`[data-accordion-content-for=${this.id}]`);

      if (element) {
        const isActive = event.target.checked; // Setting max-height to 100% does not animate the container. Setting to a
        // fixed number will only animate the container. Hence setting the
        // max-height as inline style here.

        const height = isActive ? `${element.scrollHeight}px` : '0px';
        element.style.cssText = `max-height: ${height}`;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "animateAccordion", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "animateAccordion"), _class.prototype)), _class));
  _exports.default = AccordionItemComponent;
});