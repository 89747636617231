define('ember-in-viewport/mixins/in-viewport', ['exports', 'ember-in-viewport/utils/can-use-dom', 'ember-in-viewport/utils/can-use-raf', 'ember-in-viewport/utils/find-elem', 'ember-in-viewport/utils/can-use-intersection-observer', 'ember-in-viewport/utils/is-in-viewport', 'ember-in-viewport/utils/check-scroll-direction'], function (exports, _canUseDom, _canUseRaf, _findElem, _canUseIntersectionObserver, _isInViewport, _checkScrollDirection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const rAFIDS = {};
  const lastDirection = {};
  const lastPosition = {};

  exports.default = Ember.Mixin.create({
    /**
     * IntersectionObserverEntry
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
     *
     * @property intersectionObserver
     * @default null
     */
    intersectionObserver: null,
    /**
     * @property _debouncedEventHandler
     * @default null
     */
    _debouncedEventHandler: null,

    /**
     * unbinding listeners will short circuit rAF
     *
     * @property _stopListening
     * @default false
     */
    _stopListening: false,

    rAFPoolManager: Ember.inject.service('-in-viewport'),

    /**
     * @property viewportExited
     * @type Boolean
     */
    viewportExited: Ember.computed.not('viewportEntered').readOnly(),

    init() {
      // ensure this mixin runs first, then your component can override the options
      this._super(...arguments);

      const options = Ember.assign({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());

      Ember.setProperties(this, options);
      Ember.set(this, '_evtListenerClosures', []);
    },

    didInsertElement() {
      this._super(...arguments);

      if (!_canUseDom.default) {
        return;
      }

      const viewportEnabled = Ember.get(this, 'viewportEnabled');
      if (viewportEnabled) {
        this._startListening();
      }
    },

    willDestroyElement() {
      this._super(...arguments);
      this._unbindListeners();
    },

    _buildOptions(defaultOptions = {}) {
      const owner = Ember.getOwner(this);

      if (owner) {
        return Ember.assign(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },

    _startListening() {
      this._setInitialViewport();
      this._addObserverIfNotSpying();
      this._bindScrollDirectionListener(Ember.get(this, 'viewportScrollSensitivity'));

      if (!Ember.get(this, 'viewportUseIntersectionObserver') && !Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(listener => {
          let { context, event } = listener;
          context = Ember.get(this, 'scrollableArea') || context;
          this._bindListeners(context, event);
        });
      }
    },

    _addObserverIfNotSpying() {
      if (!Ember.get(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, this._unbindIfEntered);
      }
    },

    _setInitialViewport() {
      if (Ember.get(this, 'viewportUseIntersectionObserver')) {
        return Ember.run.scheduleOnce('afterRender', this, () => {
          this._setupIntersectionObserver();
        });
      } else {
        return Ember.run.scheduleOnce('afterRender', this, () => {
          this._setViewportEntered();
        });
      }
    },

    /**
     * @method _setupIntersectionObserver
     */
    _setupIntersectionObserver() {
      const scrollableArea = Ember.get(this, 'scrollableArea') ? document.querySelector(Ember.get(this, 'scrollableArea')) : null;

      const element = Ember.get(this, 'element');
      if (!element) {
        return;
      }

      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      // IntersectionObserver takes either a Document Element or null for `root`
      const { top = 0, left = 0, bottom = 0, right = 0 } = this.viewportTolerance;
      const options = {
        root: scrollableArea,
        rootMargin: `${top}px ${right}px ${bottom}px ${left}px`,
        threshold: Ember.get(this, 'intersectionThreshold')
      };

      this.intersectionObserver = new IntersectionObserver(Ember.run.bind(this, this._onIntersection), options);
      this.intersectionObserver.observe(element);
    },

    /**
     * used by rAF and scroll event listeners to determine if mixin is in viewport
     * Remember to set `viewportSpy` to true if you want to continuously observe your element
     *
     * @method _setViewportEntered
     */
    _setViewportEntered() {
      const scrollableArea = Ember.get(this, 'scrollableArea') ? document.querySelector(Ember.get(this, 'scrollableArea')) : null;

      const element = Ember.get(this, 'element');
      if (!element) {
        return;
      }

      const height = scrollableArea ? scrollableArea.offsetHeight + scrollableArea.getBoundingClientRect().top : window.innerHeight;
      const width = scrollableArea ? scrollableArea.offsetWidth : window.innerWidth;
      const boundingClientRect = element.getBoundingClientRect();

      if (boundingClientRect) {
        this._triggerDidAccessViewport((0, _isInViewport.default)(boundingClientRect, height, width, Ember.get(this, 'viewportTolerance')));

        if (Ember.get(this, 'viewportUseRAF') && !Ember.get(this, '_stopListening')) {
          let elementId = Ember.get(this, 'elementId');
          rAFIDS[elementId] = Ember.get(this, 'rAFPoolManager').add(elementId, Ember.run.bind(this, this._setViewportEntered));
        }
      }
    },

    /**
     * callback provided to IntersectionObserver
     *
     * @method _onIntersection
     * @param {Array} - entries
     */
    _onIntersection(entries) {
      const isTearingDown = this.isDestroyed || this.isDestroying;
      const [entry] = entries;
      let { isIntersecting, intersectionRatio } = entry;

      if (isIntersecting) {
        if (!isTearingDown) {
          Ember.set(this, 'viewportEntered', true);
        }
        this.trigger('didEnterViewport');
      } else if (intersectionRatio <= 0) {
        // exiting viewport
        if (!isTearingDown) {
          Ember.set(this, 'viewportEntered', false);
        }
        this.trigger('didExitViewport');
      }
    },

    /**
     * @method _triggerDidScrollDirection
     * @param contextEl
     * @param sensitivity
     */
    _triggerDidScrollDirection(contextEl = null, sensitivity = 1) {
      (false && !(contextEl) && Ember.assert('You must pass a valid context element to _triggerDidScrollDirection', contextEl));
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));


      const elementId = Ember.get(this, 'elementId');
      const lastDirectionForEl = lastDirection[elementId];
      const lastPositionForEl = lastPosition[elementId];
      const newPosition = {
        top: contextEl.scrollTop,
        left: contextEl.scrollLeft
      };

      const scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      const directionChanged = scrollDirection !== lastDirectionForEl;

      if (scrollDirection && directionChanged && Ember.get(this, 'viewportEntered')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }

      lastPosition[elementId] = newPosition;
    },

    /**
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     */
    _triggerDidAccessViewport(hasEnteredViewport = false) {
      const viewportEntered = Ember.get(this, 'viewportEntered');
      const didEnter = !viewportEntered && hasEnteredViewport;
      const didLeave = viewportEntered && !hasEnteredViewport;
      let triggeredEventName = '';

      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }

      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }

      if (Ember.get(this, 'viewportSpy') || !viewportEntered) {
        Ember.set(this, 'viewportEntered', hasEnteredViewport);
      }

      this.trigger(triggeredEventName);
    },

    _unbindIfEntered() {
      if (!Ember.get(this, 'viewportSpy') && Ember.get(this, 'viewportEntered')) {
        this._unbindListeners();
        this.removeObserver('viewportEntered', this, this._unbindIfEntered);
        Ember.set(this, 'viewportEntered', false);
      }
    },

    /**
     * General utility function
     *
     * @method _debouncedEvent
     */
    _debouncedEvent(methodName, ...args) {
      (false && !(methodName) && Ember.assert('You must pass a methodName to _debouncedEvent', methodName));
      (false && !(Ember.typeOf(methodName) === 'string') && Ember.assert('methodName must be a string', Ember.typeOf(methodName) === 'string'));


      Ember.run.debounce(this, () => this[methodName](...args), Ember.get(this, 'viewportRefreshRate'));
    },

    _bindScrollDirectionListener(sensitivity = 1) {
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));


      const contextEl = Ember.get(this, 'scrollableArea') || window;
      const elem = (0, _findElem.default)(contextEl);

      this._debouncedEventHandler = this._debouncedEvent.bind(this, '_triggerDidScrollDirection', elem, sensitivity);
      elem.addEventListener('scroll', this._debouncedEventHandler, false);
    },

    _unbindScrollDirectionListener() {
      const elementId = Ember.get(this, 'elementId');
      const context = Ember.get(this, 'scrollableArea') || window;
      const elem = (0, _findElem.default)(context);

      if (elem) {
        elem.removeEventListener('scroll', this._debouncedEventHandler, false);
        delete lastPosition[elementId];
        delete lastDirection[elementId];
      }
    },

    /**
     * Only if not using IntersectionObserver and rAF
     *
     * @method _bindListeners
     */
    _bindListeners(context = null, event = null) {
      (false && !(context) && Ember.assert('You must pass a valid context to _bindListeners', context));
      (false && !(event) && Ember.assert('You must pass a valid event to _bindListeners', event));


      let elem = (0, _findElem.default)(context);

      let evtListener = () => this._debouncedEvent('_setViewportEntered');
      this._evtListenerClosures.push({ event: event, evtListener });
      elem.addEventListener(event, evtListener);
    },

    /**
     * @method _unbindListeners
     */
    _unbindListeners() {
      Ember.set(this, '_stopListening', true);

      // 1.
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(this.element);
      }

      // 2.
      if (!Ember.get(this, 'viewportUseIntersectionObserver') && Ember.get(this, 'viewportUseRAF')) {
        const elementId = Ember.get(this, 'elementId');

        Ember.run.next(this, () => {
          Ember.get(this, 'rAFPoolManager').remove(elementId);
          delete rAFIDS[elementId];
        });
      }

      // 3.
      if (!Ember.get(this, 'viewportUseIntersectionObserver') && !Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(listener => {
          let { context, event } = listener;
          context = Ember.get(this, 'scrollableArea') || context;
          let elem = (0, _findElem.default)(context);
          let { evtListener } = this._evtListenerClosures.find(closure => event === closure.event) || {};

          elem.removeEventListener(event, evtListener);
        });
      }

      // 4.
      this._unbindScrollDirectionListener();
    }
  });
});