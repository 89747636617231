define("ember-svg-jar/inlined/de-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><circle id=\"a\" cx=\"12\" cy=\"12\" r=\"12\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><use fill=\"#D8D8D8\" xlink:href=\"#a\"/><path fill=\"#000\" mask=\"url(#b)\" d=\"M-5.25 0H28.5v8.25H-5.25z\"/><path fill=\"#F71F31\" mask=\"url(#b)\" d=\"M-5.25 8.25H28.5v8.25H-5.25z\"/><path fill=\"#F5B543\" mask=\"url(#b)\" d=\"M-5.25 16.5H28.5v8.25H-5.25z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});