define("@clark/cms-ui/components/stack/component", ["exports", "@glimmer/component", "@clark/cms-ui/components/stack/styles"], function (_exports, _component, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StackComponent extends _component.default {
    get classes() {
      const value = [_styles.default.stack];

      if (this.args.isVertical) {
        value.push(_styles.default.vertical);
      }

      if (this.args.spacing) {
        value.push(_styles.default[`spacing-${this.args.spacing}`]);
      }

      if (this.args.alignment) {
        value.push(_styles.default[`alignment-${this.args.alignment}`]);
      }

      if (this.args.distribution) {
        value.push(_styles.default[`distribution-${this.args.distribution}`]);
      }

      if (this.args.wrap === false) {
        value.push(_styles.default['no-wrap']);
      }

      return value.join(' ');
    }

  }

  _exports.default = StackComponent;
});