define("@clark/cms-ui/components/ui/hint/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hintId = hintId;
  _exports.default = void 0;

  function hintId(id, type = 'help-text', idSuffix = undefined) {
    const base = `${id}:${type}`;
    if (!idSuffix) return base;
    return `${base}:${idSuffix}`;
  }
  /**
   * The `Hint` component inserts an hint, usually associated with a form element,
   * which can appear as either a regular hint or as an error.
   *
   * An example of how to use the component:
   *
   * ```hbs
   * <Ui::Hint @for="email-field" @type="error">
   *   Please enter a valid email address
   * </Ui::Hint>
   * ```
   *
   * The `for` argument is required.
   */


  class HintComponent extends _component.default {
    get id() {
      return hintId(this.args.for, this.args.type, this.args.idSuffix);
    }

    get typeClass() {
      return `type-${this.args.type || 'help-text'}`;
    }

  }

  _exports.default = HintComponent;
});