define("@clark/cms-ui/styles/typo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "c-white": "#FFFFFF",
    "c-title": "rgba(32, 49, 81, 1)",
    "c-copy": "rgba(32, 49, 81, 0.7)",
    "rich-black": "#01051C",
    "text-color": "rgba(32, 49, 81, 0.7)",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "font-bold": "'CeraRoundPro-Bold'",
    "font-medium": "'CeraRoundPro-Medium'",
    "font-regular": "'CeraRoundPro-Regular'",
    "font-light": "'CeraRoundPro-Light'",
    "tiempos-headline": "'TiemposHeadline'",
    "heading": "_heading_16bl1i",
    "h1": "_h1_16bl1i _heading_16bl1i",
    "h2": "_h2_16bl1i _heading_16bl1i",
    "h3": "_h3_16bl1i _heading_16bl1i",
    "h4": "_h4_16bl1i _heading_16bl1i",
    "copy-text": "_copy-text_16bl1i",
    "c1": "_c1_16bl1i _copy-text_16bl1i",
    "c2": "_c2_16bl1i _copy-text_16bl1i",
    "c3": "_c3_16bl1i _copy-text_16bl1i",
    "c4": "_c4_16bl1i _copy-text_16bl1i",
    "text-left": "_text-left_16bl1i",
    "text-center": "_text-center_16bl1i",
    "text-md-center": "_text-md-center_16bl1i"
  };
  _exports.default = _default;
});