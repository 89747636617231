define("@clark/cms-ui/components/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/7PHU1YD",
    "block": "{\"symbols\":[\"@source\",\"@accessibilityLabel\",\"&attrs\"],\"statements\":[[1,[30,[36,3],[[32,0,[\"args\"]]],null]],[2,\"\\n\\n\"],[11,\"span\"],[16,0,[32,0,[\"classes\"]]],[24,\"role\",\"img\"],[16,\"aria-label\",[32,2]],[17,3],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1],\"placeholder\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"placeholder\"],[[\"from\"],[\"@clark/cms-ui/components/icon/styles\"]]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[32,1]],[[\"focusable\",\"aria-hidden\",\"class\"],[\"false\",\"true\",[30,[36,1],[[30,[36,0],[\"svg\"],[[\"from\"],[\"@clark/cms-ui/components/icon/styles\"]]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"concat\",\"svg-jar\",\"icon/validate-args\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/icon/template.hbs"
    }
  });

  _exports.default = _default;
});