define("@clark/cms-ui/components/quick-selection/quick-selection-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "quick-selection-border": "#DADDEC",
    "brand-primary": "#2B6CDE",
    "c-white": "#FFFFFF",
    "on-hover-card-box-shadow": "0 3px 10px 0px rgba(0,0,0,0.15)",
    "dark-blue": "#0439D7",
    "quick-selection-item-height": "140px",
    "quick-selection-item": "_quick-selection-item_gdzar7",
    "quick-selection-item-icon": "_quick-selection-item-icon_gdzar7",
    "selected-sign": "_selected-sign_gdzar7",
    "quick-selection-item-selected": "_quick-selection-item-selected_gdzar7"
  };
  _exports.default = _default;
});