define("@clark/cms-ui/components/icon/component", ["exports", "@glimmer/component", "@clark/cms-ui/components/icon/styles"], function (_exports, _component, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IconComponent extends _component.default {
    get classes() {
      const value = [_styles.default.icon, _styles.default[`size-${this.args.size || 'medium'}`]];
      return value.join(' ');
    }

  }

  _exports.default = IconComponent;
});