define("@clark/cms-ui/components/next-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u7t9D7L7",
    "block": "{\"symbols\":[\"@items\"],\"statements\":[[10,\"section\"],[15,0,[31,[[30,[36,0],[\"next-steps\"],[[\"from\"],[\"@clark/cms-ui/components/next-steps/styles\"]]]]]],[14,\"data-test-next-steps\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"layout-container\"],[[\"from\"],[\"@clark/cms-ui/components/next-steps/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"h2\"],[15,0,[31,[[30,[36,0],[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/next-steps/styles\"]]]]]],[14,\"data-test-next-steps-title\",\"\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"next-steps.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"numbered-list\",[],[[\"@items\"],[[32,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"t\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/next-steps/template.hbs"
    }
  });

  _exports.default = _default;
});