define("@clark/cms-ui/helpers/thumbnail/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateArgs = validateArgs;
  _exports.default = void 0;

  function validateArgs([args]) {
    (false && !(typeof args.imgSrc.url === 'string') && Ember.assert(`Thumbnail: Missing argument imgSrc to render thumbnail image`, typeof args.imgSrc.url === 'string'));
    (false && !(typeof args.title === 'string') && Ember.assert(`Thumbnail: Missing argument title`, typeof args.title === 'string'));
    (false && !(typeof args.description === 'string') && Ember.assert(`Thumbnail: Missing argument description`, typeof args.description === 'string'));
  }

  var _default = Ember.Helper.helper(validateArgs);

  _exports.default = _default;
});