define("@clark/cms-ui/components/textbox/textbox-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "icon-container": "_icon-container_147lhk",
    "title": "_title_147lhk"
  };
  _exports.default = _default;
});