define("@clark/cms-ui/components/ui/text-field/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "z-index-contents": "20",
    "z-index-backdrop": "10",
    "control-line-height": "20px",
    "control-height": "40px",
    "vertical-padding": "calc((40px - 20px - 2px) / 2)",
    "horizontal-spacing": "12px",
    "affix-horizontal-spacing": "8px",
    "placeholder-color": "#888da0",
    "text-field": "_text-field_1qsdb5",
    "disabled": "_disabled_1qsdb5",
    "input": "_input_1qsdb5",
    "has-prefix": "_has-prefix_1qsdb5",
    "has-suffix": "_has-suffix_1qsdb5",
    "backdrop": "_backdrop_1qsdb5",
    "focused": "_focused_1qsdb5",
    "has-error": "_has-error_1qsdb5",
    "inset-outline": "_inset-outline_1qsdb5",
    "icon": "_icon_1qsdb5",
    "prefix": "_prefix_1qsdb5",
    "suffix": "_suffix_1qsdb5"
  };
  _exports.default = _default;
});