define("@clark/cms-api/services/api", ["exports", "ember-concurrency-decorators", "ember-concurrency-ts", "ember-get-config", "fetch", "@clark/cms-api/errors", "@clark/cms-api/http-verb"], function (_exports, _emberConcurrencyDecorators, _emberConcurrencyTs, _emberGetConfig, _fetch, _errors, _httpVerb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const API_URL = (() => {
    (false && !(Boolean(Object.prototype.hasOwnProperty.call(_emberGetConfig.default.api, 'url'))) && Ember.assert(`Missing 'api.url' in config/environment`, Boolean(Object.prototype.hasOwnProperty.call(_emberGetConfig.default.api, 'url'))));
    return _emberGetConfig.default.api.url.endsWith('/') ? _emberGetConfig.default.api.url.slice(0, -1) : _emberGetConfig.default.api.url;
  })();

  let APIService = (_dec = Ember._tracked, (_class = (_temp = class APIService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "token", _descriptor, this);

      _defineProperty(this, "appName", _emberGetConfig.default.APP.name);

      _defineProperty(this, "versionMappings", {
        'authenticity-token': 'v2',
        'customer/current/consent': 'v5',
        features: 'v4',
        'categories/active': 'v4',
        'leads/lead_with_opportunity': 'v4',
        current_user: 'v2'
      });
    }

    getVersionForResource(resource) {
      const version = resource in this.versionMappings ? this.versionMappings[resource] : '';
      (false && !(Boolean(version)) && Ember.assert(`Could not find the API version for '${resource}`, Boolean(version)));
      return version;
    }

    makeHeaders({
      version,
      token,
      contentType = true
    }) {
      const headers = {
        Accept: `application/vnd.clark-${version}+json`,
        'X-Client': `${this.appName}`
      };

      if (contentType === 'string') {
        headers['Content-Type'] = contentType;
      } else if (contentType) {
        headers['Content-Type'] = 'application/json';
      }

      if (token) {
        headers['X-CSRF-Token'] = this.token;
      }

      return headers;
    }

    getURLForResource(resource) {
      return `${API_URL}/${resource}`;
    }

    serializePayload(payload) {
      const owner = Ember.getOwner(payload);
      return JSON.stringify(payload, (_key, value) => value === owner ? undefined : value);
    }

    makeRequest(resource, {
      method = _httpVerb.default.GET,
      version = this.getVersionForResource(resource),
      token = false,
      payload = null,
      headers = {},
      ...requestOptions
    } = {}) {
      return new _fetch.Request(this.getURLForResource(resource), {
        headers: { ...this.makeHeaders({
            version,
            token
          }),
          ...headers
        },
        body: payload ? this.serializePayload(payload) : null,
        method,
        ...requestOptions
      });
    }

    async request(request) {
      let response;

      try {
        response = await (0, _fetch.default)(request);
      } catch (error) {
        throw new _errors.TransportError(request, error);
      }

      let body;

      try {
        const shouldSupportJSON = response.headers.get('Content-Type') === 'application/json';
        body = await response[shouldSupportJSON ? 'json' : 'text']();
      } catch (error) {
        throw new _errors.FormatError(request, response, error);
      }

      if (!response.ok || typeof body === 'object' && (body.error || body.errors)) {
        throw new _errors.ResponseError(request, response, body);
      }

      return body;
    }

    requestFromResource(resource, requestOptions = {}) {
      const request = this.makeRequest(resource, requestOptions);
      return this.request(request);
    } // @ts-ignore


    async get(resource, options) {
      return this.requestFromResource(resource, options);
    }

    async post(resource, payload = {}, // eslint-disable-line default-param-last
    options) {
      return this.requestFromResource(resource, {
        token: true,
        ...options,
        method: _httpVerb.default.POST,
        payload
      });
    }

    *fetchToken() {
      const {
        token
      } = yield this.get('authenticity-token', {
        token: false
      });
      this.token = token;
      return token;
    }

    async fetchCSRFToken() {
      return (0, _emberConcurrencyTs.taskFor)(this.fetchToken).perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "token", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchToken", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchToken"), _class.prototype)), _class));
  _exports.default = APIService;
});