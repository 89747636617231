define("@clark/cms-ui/components/spinner/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "size-large": "_size-large_1p4w88",
    "size-medium": "_size-medium_1p4w88",
    "size-small": "_size-small_1p4w88",
    "color-white": "_color-white_1p4w88"
  };
  _exports.default = _default;
});