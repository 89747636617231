define("ember-svg-jar/inlined/heimat-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.613 10.15l.124.113L22 19.525l9.263-9.262.124-.114a1.75 1.75 0 012.464 2.464l-.114.124L24.475 22l9.262 9.263a1.75 1.75 0 01-2.35 2.588l-.124-.114L22 24.475l-9.263 9.262-.124.114a1.75 1.75 0 01-2.464-2.464l.114-.124L19.525 22l-9.262-9.263a1.75 1.75 0 012.35-2.588z\"/>",
    "attrs": {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});