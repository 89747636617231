define("ember-svg-jar/inlined/bandage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M23.192 6.671L8.999 20.864a.463.463 0 01-.655 0l-5.536-5.536-1.672 1.673a.463.463 0 000 .655l6.208 6.208a.463.463 0 00.655 0L23.864 8a.464.464 0 000-.655z\" fill=\"#e8e5fe\"/><path d=\"M24.571 6.637L18.363.429a1.463 1.463 0 00-2.07 0L.43 16.294a1.463 1.463 0 000 2.07l6.208 6.207a1.463 1.463 0 002.07 0L24.57 8.706a1.463 1.463 0 000-2.07zm-.707 1.362L8 23.864a.463.463 0 01-.655 0l-6.208-6.208a.463.463 0 010-.655L17 1.136a.463.463 0 01.655 0l6.208 6.208a.464.464 0 010 .655z\" fill=\"#8075f2\"/><circle cx=\"12.5\" cy=\"9.672\" r=\"1\" transform=\"rotate(-44.998 12.5 9.672)\" fill=\"#8075f2\"/><circle cx=\"15.328\" cy=\"12.5\" r=\"1\" transform=\"rotate(-45.002 15.328 12.5)\" fill=\"#8075f2\"/><circle cx=\"9.672\" cy=\"12.5\" r=\"1\" transform=\"rotate(-45.002 9.672 12.5)\" fill=\"#8075f2\"/><circle cx=\"12.5\" cy=\"15.328\" r=\"1\" transform=\"rotate(-44.998 12.5 15.328)\" fill=\"#8075f2\"/><path d=\"M24.572 16.294l-3.105-3.104-.69.69 3.104 3.104a.488.488 0 010 .69l-6.208 6.208a.488.488 0 01-.69 0l-3.104-3.104-.69.69 3.104 3.104a1.463 1.463 0 002.07 0l6.208-6.208a1.464 1.464 0 000-2.07zM3.533 11.81l.69-.69-3.105-3.104a.488.488 0 010-.69l6.208-6.208a.488.488 0 01.69 0l3.105 3.104.69-.69L8.705.43a1.463 1.463 0 00-2.07 0L.43 6.637a1.463 1.463 0 000 2.069z\" fill=\"#8075f2\"/>",
    "attrs": {
      "viewBox": "0 0 25 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});