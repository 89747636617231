define("@clark/cms-ui/styles/input-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-primary": "#2B6CDE",
    "cta-secondary": "#FFFFFF",
    "cta-secondary-border": "#DADDEC",
    "c-white": "#FFFFFF",
    "c-copy": "rgba(32, 49, 81, 0.7)",
    "font-medium": "'CeraRoundPro-Medium'",
    "text-white": "#FFFFFF",
    "border-white": "#FFFFFF",
    "secondary-cta-text": "rgba(32, 49, 81, 0.7)",
    "base-button": "_base-button_17xnva",
    "is-disabled": "_is-disabled_17xnva",
    "button-primary": "_button-primary_17xnva _base-button_17xnva",
    "button-secondary": "_button-secondary_17xnva _base-button_17xnva"
  };
  _exports.default = _default;
});