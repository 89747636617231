define("@zestia/ember-auto-focus/utils/focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = focus;

  function focus(element) {
    element.dataset.programmaticallyFocused = 'true';
    element.focus();
    Ember.run.next(() => delete element.dataset.programmaticallyFocused);
  }
});