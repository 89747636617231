define("@clark/cms-ui/components/table-of-contents/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "from-xlarge": "only screen and (min-width: 1380px)",
    "rich-black": "#01051C",
    "tiempos-headline": "'TiemposHeadline'",
    "font-medium": "'CeraRoundPro-Medium'",
    "background": "#080f8c",
    "background-desktop": "white",
    "border-color-desktop": "#0439d7",
    "title-color": "white",
    "title-color-desktop": "#080f8c",
    "link-color": "white",
    "link-color-desktop": "#203151",
    "bullet-color": "#080f8c",
    "bullet-size": "7px",
    "bullet-spacing": "8px",
    "toc-icon-container-size": "80px",
    "toc-icon-container-size-desktop": "62px",
    "toc-icon-width": "37px",
    "toc-icon-width-desktop": "27px",
    "table-of-contents": "_table-of-contents_pk44i1",
    "toc-section-icon-container": "_toc-section-icon-container_pk44i1",
    "toc-section-icon": "_toc-section-icon_pk44i1",
    "title": "_title_pk44i1",
    "list": "_list_pk44i1",
    "item": "_item_pk44i1",
    "link": "_link_pk44i1",
    "is-active": "_is-active_pk44i1",
    "link-text": "_link-text_pk44i1",
    "link-bullet": "_link-bullet_pk44i1",
    "link-arrow": "_link-arrow_pk44i1"
  };
  _exports.default = _default;
});