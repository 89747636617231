define("@clark/cms-ui/components/quick-selection/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "quick-selection": "_quick-selection_16e3ia",
    "quick-selection-container": "_quick-selection-container_16e3ia",
    "quick-selection-item": "_quick-selection-item_16e3ia"
  };
  _exports.default = _default;
});