define("@clark/cms-ui/components/modal/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cmpp9E4v",
    "block": "{\"symbols\":[\"@isSubdued\",\"@flush\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,3],[[30,[36,2],[\" \",[30,[36,1],[\"section\",[30,[36,0],[[32,2],\"flush\"],null],[30,[36,0],[[32,1],\"subdued\"],null]],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/modal/section/styles\"]]]],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"array\",\"join\",\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/section/template.hbs"
    }
  });

  _exports.default = _default;
});