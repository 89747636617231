define("@clark/cms-ui/services/valid-routes", ["exports", "ember-concurrency-decorators", "ember-concurrency-ts", "fetch"], function (_exports, _emberConcurrencyDecorators, _emberConcurrencyTs, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * ValidRoute service fetches the valid route list from `/content/valid_url.json`
   * These list of routes are the slugs which are published in contentful.
   */
  let ValidRoutes = (_class = class ValidRoutes extends Ember.Service {
    constructor() {
      super(...arguments); // eslint-disable-line prefer-rest-params

      (0, _emberConcurrencyTs.taskFor)(this.loadRoutes).perform();
    }

    get routes() {
      const loadRoutesTask = (0, _emberConcurrencyTs.taskFor)(this.loadRoutes);

      if (loadRoutesTask.isRunning) {
        return loadRoutesTask.last;
      }

      return loadRoutesTask.lastSuccessful && loadRoutesTask.lastSuccessful.value;
    }

    *loadRoutes() {
      const response = yield (0, _fetch.default)('/content/valid_url.json');
      const {
        routes
      } = yield response.json();
      return routes;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "loadRoutes", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadRoutes"), _class.prototype)), _class);
  _exports.default = ValidRoutes;
});