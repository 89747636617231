define("@clark/cms-ui/components/backdrop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iJhw5t2s",
    "block": "{\"symbols\":[\"transitionClass\",\"@isActive\",\"@isTransparent\",\"@onClick\"],\"statements\":[[8,\"transition-class-provider\",[],[[\"@duration\",\"@in\",\"@isMountable\"],[200,[32,2],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"  \"],[11,\"div\"],[16,0,[30,[36,3],[[30,[36,2],[\" \",[30,[36,1],[\"backdrop\",[32,1],[30,[36,0],[[32,3],\"transparent\"],null]],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/backdrop/styles\"]]]],[4,[38,4],null,null],[4,[38,6],[\"click\",[30,[36,5],[[32,4]],null]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"array\",\"join\",\"local-class\",\"lock-scroll\",\"optional\",\"on\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/backdrop/template.hbs"
    }
  });

  _exports.default = _default;
});