define("@clark/cms-ui/components/stack/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "spacing-none": "_spacing-none_wet9pm",
    "spacing-extra-tight": "_spacing-extra-tight_wet9pm",
    "spacing-tight": "_spacing-tight_wet9pm",
    "spacing-normal": "1rem",
    "spacing-loose": "_spacing-loose_wet9pm",
    "spacing-extra-loose": "_spacing-extra-loose_wet9pm",
    "stack": "_stack_wet9pm",
    "item": "_item_wet9pm",
    "no-wrap": "_no-wrap_wet9pm",
    "distribution-leading": "_distribution-leading_wet9pm",
    "distribution-trailing": "_distribution-trailing_wet9pm",
    "distribution-center": "_distribution-center_wet9pm",
    "distribution-equal-spacing": "_distribution-equal-spacing_wet9pm",
    "distribution-fill": "_distribution-fill_wet9pm",
    "distribution-fill-evenly": "_distribution-fill-evenly_wet9pm",
    "alignment-leading": "_alignment-leading_wet9pm",
    "alignment-trailing": "_alignment-trailing_wet9pm",
    "alignment-center": "_alignment-center_wet9pm",
    "alignment-fill": "_alignment-fill_wet9pm",
    "alignment-baseline": "_alignment-baseline_wet9pm",
    "vertical": "_vertical_wet9pm",
    "item-fill": "_item-fill_wet9pm"
  };
  _exports.default = _default;
});