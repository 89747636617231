define("ember-svg-jar/inlined/vintage-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#e8e5fe}.cls-2{fill:#8075f2}</style></defs><title>vintage-car</title><g id=\"budicon-vintage-car\"><path class=\"cls-1\" d=\"M19.93 18.07a1.482 1.482 0 01.07.43 1.5 1.5 0 01-1.5 1.5 1.482 1.482 0 01-.43-.07 1.496 1.496 0 101.86-1.86zM6.93 18.07a1.482 1.482 0 01.07.43A1.5 1.5 0 015.5 20a1.482 1.482 0 01-.43-.07 1.496 1.496 0 101.86-1.86zM2 18h3v1H2z\"/><path class=\"cls-1\" d=\"M8 18h10v1H8zM21 18h3v1h-3zM7 10v3H2.707A5.141 5.141 0 017 10z\"/><path class=\"cls-2\" d=\"M24.985 18.288l-.388-2.712A3 3 0 0021.627 13h-2.006a1.5 1.5 0 01-1.06-.44L15.879 9.88A3 3 0 0013.757 9H7c-3 0-6 3-6 7v3H.5a.5.5 0 000 1h3.55a2.5 2.5 0 004.9 0h8.1a2.5 2.5 0 004.9 0h1.55a1.5 1.5 0 001.485-1.712zM8 10h5.757a1.988 1.988 0 011.415.586L17.586 13H8zm-1 0v3H2.707A5.141 5.141 0 017 10zm-.5 11A1.5 1.5 0 118 19.5 1.502 1.502 0 016.5 21zm13 0a1.5 1.5 0 111.5-1.5 1.502 1.502 0 01-1.5 1.5zm4.377-2.172A.494.494 0 0123.5 19h-1.55a2.5 2.5 0 00-4.9 0h-8.1a2.5 2.5 0 00-4.9 0H2v-3a6.709 6.709 0 01.304-2h19.323a2.01 2.01 0 011.98 1.717l.388 2.712a.494.494 0 01-.118.399z\"/><path class=\"cls-2\" d=\"M13.5 16h-2a.5.5 0 000 1h2a.5.5 0 100-1z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});