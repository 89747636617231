define("@clark/cms-ui/components/transition-class-provider/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-decorators", "ember-concurrency-ts"], function (_exports, _component, _emberConcurrency, _emberConcurrencyDecorators, _emberConcurrencyTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const statusToClass = Object.freeze({
    enter: 'enter',
    entering: 'enter enter-active',
    entered: 'enter-done',
    exit: 'exit',
    exiting: 'exit exit-active',
    exited: 'exit-done',
    unmounted: null
  });
  /**
   * The `<TransitionClassProvider>` allows you to generate classes used for
   * more complicated CSS transitions in a declarative way. If necessary, the
   * component can also handle mounting and unmounting of its consumer based on
   * the transition state.
   *
   * An example of how to use the component:
   * ```hbs
   * <TransitionClassProvider
   *   @duration={{200}}
   *   @in={{@isActive}}
   *   as |transitionClass|
   * >
   *   ...
   * </TransitionClassProvider>
   * ```
   *
   * In the above example, the generated transition class is yielded as the
   * `transitionClass` value. That value can then be added as a class to whichever
   * DOM element you wish to animate.
   *
   * In order to control mounting of the consumer, set `isMountable` to `true` on
   * the component. The consumer will then only be mounted when `in` has been set
   * to `true`, and it will unmounted when `in` has been set to `false` and the
   * transition has finished.
   */

  let TransitionClassProviderComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = (0, _emberConcurrencyDecorators.dropTaskGroup)({
    maxConcurrency: 1
  }), _dec4 = (0, _emberConcurrencyDecorators.task)({
    group: 'transition'
  }), _dec5 = (0, _emberConcurrencyDecorators.task)({
    group: 'transition'
  }), (_class = (_temp = class TransitionClassProviderComponent extends _component.default {
    get transitionClass() {
      return statusToClass[this.status];
    }

    get isMounted() {
      return this.status !== 'unmounted';
    }

    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "status", _descriptor, this);

      _initializerDefineProperty(this, "transition", _descriptor2, this);

      (false && !(typeof this.args.duration === 'number') && Ember.assert('TransitionClassProvider: Missing argument duration', typeof this.args.duration === 'number'));

      if (this.args.isMountable) {
        this.status = 'unmounted';
      } else {
        this.status = 'exited';
      }

      if (this.args.in) {
        (0, _emberConcurrencyTs.taskFor)(this.enter).perform();
      }
    }

    update() {
      if (this.args.in) {
        if (this.status !== 'enter' && this.status !== 'entering' && this.status !== 'entered') {
          (0, _emberConcurrencyTs.taskFor)(this.enter).perform();
        }
      } else if (this.status === 'enter' || this.status === 'entering' || this.status === 'entered') {
        (0, _emberConcurrencyTs.taskFor)(this.exit).perform();
      }
    }

    *enter() {
      if (this.args.delay) {
        yield (0, _emberConcurrency.timeout)(this.getDelay('in'));
      }

      this.status = 'enter';
      yield (0, _emberConcurrency.timeout)(1);
      this.status = 'entering';
      yield (0, _emberConcurrency.timeout)(this.args.duration);
      this.status = 'entered';
    }

    *exit() {
      if (this.args.delay) {
        yield (0, _emberConcurrency.timeout)(this.getDelay('out'));
      }

      this.status = 'exit';
      yield (0, _emberConcurrency.timeout)(1);
      this.status = 'exiting';
      yield (0, _emberConcurrency.timeout)(this.args.duration);
      this.status = 'exited';

      if (this.args.isMountable) {
        yield (0, _emberConcurrency.timeout)(1);
        this.status = 'unmounted';
      }
    }

    getDelay(transition) {
      if (typeof this.args.delay === 'object') {
        (false && !(typeof this.args.delay.in === 'number' && typeof this.args.delay.out === 'number') && Ember.assert('TransitionClassProvider: If `delay` is passed as an object, it must have both `in` and `out` properties', typeof this.args.delay.in === 'number' && typeof this.args.delay.out === 'number'));
        return this.args.delay[transition];
      }

      return this.args.delay;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "update", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "enter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "enter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "exit"), _class.prototype)), _class));
  _exports.default = TransitionClassProviderComponent;
});