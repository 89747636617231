define("@clark/cms-ui/components/thumbnail-list/thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U8hWCaSK",
    "block": "{\"symbols\":[\"&attrs\",\"@imgSrc\",\"@title\",\"@description\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"args\"]]],null]],[2,\"\\n\"],[11,\"section\"],[16,0,[31,[[30,[36,1],[\"thumbnail-container\"],[[\"from\"],[\"@clark/cms-ui/components/thumbnail-list/thumbnail/styles\"]]]]]],[24,\"data-test-thumbnail-container\",\"\"],[17,1],[12],[2,\"\\n  \"],[8,\"multi-source-image\",[],[[\"@fallbackImgSource\",\"@imgClass\"],[[30,[36,2],null,[[\"url\",\"altText\"],[[32,2,[\"url\"]],[32,2,[\"altText\"]]]]],[30,[36,1],[\"thumbnail-image\"],[[\"from\"],[\"@clark/cms-ui/components/thumbnail-list/thumbnail/styles\"]]]]],null],[2,\"\\n  \"],[10,\"h3\"],[15,0,[31,[[30,[36,3],[\"h3\"],null],\" \",[30,[36,1],[\"thumbnail-title\"],[[\"from\"],[\"@clark/cms-ui/components/thumbnail-list/thumbnail/styles\"]]]]]],[12],[2,\"\\n    \"],[1,[30,[36,5],[[32,3]],[[\"class\"],[[30,[36,4],[\"reset-styles\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,5],[[32,4]],[[\"class\"],[[30,[36,3],[\"c2\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"thumbnail/validate-args\",\"local-class\",\"hash\",\"typo\",\"markdown\",\"markdown-to-html\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/thumbnail-list/thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});