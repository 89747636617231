define("@clark/cms-ui/components/crosslink/carousel/component", ["exports", "@glimmer/component", "@clark/cms-ui/breakpoints", "@clark/cms-ui/components/crosslink/carousel/styles"], function (_exports, _component, _breakpoints, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CrosslinkCarouselComponent extends _component.default {
    get swiperOptions() {
      const slideSpacingDefault = 24;
      const slideSpacingMedium = 32;
      return {
        slidesPerView: 1,
        initialSlide: 0,
        preloadImages: false,
        spaceBetween: slideSpacingDefault,
        navigation: {
          prevEl: `.${_styles.default['prev-button']}`,
          nextEl: `.${_styles.default['next-button']}`
        },
        breakpoints: {
          [_breakpoints.breakpoints.medium]: {
            slidesPerView: 2,
            spaceBetween: slideSpacingMedium
          },
          [_breakpoints.breakpoints.large]: {
            slidesPerView: 3,
            spaceBetween: slideSpacingMedium
          }
        }
      };
    }

  }

  _exports.default = CrosslinkCarouselComponent;
});