define("@clark/cms-ui/components/contact-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "background-light-gray": "#F5F6FA",
    "from-medium": "only screen and (min-width: 769px)",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "avatar-width": "270px",
    "avatar-height": "270px",
    "container-background": "#F5F6FA",
    "contact-box-container": "_contact-box-container_1t524",
    "avatar": "_avatar_1t524",
    "content-wrapper": "_content-wrapper_1t524",
    "contact-box-description": "_contact-box-description_1t524"
  };
  _exports.default = _default;
});