define("@clark/ember-brand-config/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const config = {
    brand: 'clark',
    brandFull: 'Clark',
    locale: {
      languageTag: 'de-de'
    },
    canonicalHost: 'clark.de',
    layoutId: 'layoutDE',
    mainMenuSlug: 'main-menu-de-de',
    homeSlug: '/',
    leadSuccessSlug: 'lead-success',
    sovendusSlug: 'sovendus',
    trustPilotConfig: {
      default: {
        height: '240px',
        templateId: '54ad5defc6454f065c28af8b'
      },
      seoHero: {
        height: '18px',
        templateId: '5419b732fbfb950b10de65e5'
      },
      leadGen: {
        width: '120px',
        height: '82px',
        templateId: '53aa8807dec7e10d38f59f32',
        theme: 'dark'
      },
      footer: {
        height: '18px',
        templateId: '5419b732fbfb950b10de65e5'
      },
      widget: {
        locale: 'de-De',
        businessunitId: '55c0d2ac0000ff000581c5b0',
        width: '100%',
        theme: 'light',
        stars: '4,5',
        url: 'https://de.trustpilot.com/review/clark.de',
        dataSchemaType: 'Organization'
      }
    },
    socialMediaLinks: [{
      url: 'https://www.facebook.com/ClarkGermany/',
      source: 'fb-ico'
    }, {
      url: 'https://twitter.com/ClarkGermany/',
      source: 'twitter-ico'
    }, {
      url: 'https://www.linkedin.com/company/clark-germany',
      source: 'linkedin-ico'
    }, {
      url: 'https://www.instagram.com/ClarkApp/',
      source: 'instagram-ico'
    }],
    disabledFooterLinks: undefined,
    adjustLink: 'https://app.adjust.com/m78t5mb_dk41g7t'
  };
  var _default = config;
  _exports.default = _default;
});