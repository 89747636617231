define("@clark/cms-ui/components/modal/close-button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ModalCloseButtonComponent extends _component.default {}

  _exports.default = ModalCloseButtonComponent;
});