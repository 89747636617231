define("@clark/cms-ui/components/common/flyout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tUPfq72/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[18,1,[[30,[36,1],null,[[\"trigger\",\"Menu\"],[[32,0,[\"trigger\"]],[30,[36,0],[\"common/flyout/menu\"],[[\"menu\"],[[32,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/flyout/template.hbs"
    }
  });

  _exports.default = _default;
});