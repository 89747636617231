define("@clark/cms-api/errors/format", ["exports", "@clark/cms-api/errors/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * `APIError` that wraps the underlying `TypeError`, when the response could not
   * be parsed.
   */
  class FormatError extends _api.default {
    constructor(request, response, originalError) {
      super(request, response);

      _defineProperty(this, "originalError", void 0);

      _defineProperty(this, "response", void 0);

      this.originalError = originalError;
    }

    get message() {
      return `${this.response.status} - ${this.response.statusText} - ${this.url}: ${this.originalError.message}`;
    }

    get sentryExtra() {
      return Ember.getProperties(this, 'request', 'response', 'originalError', 'requestHeaders', 'responseHeaders');
    }

  }

  _exports.default = FormatError;
});