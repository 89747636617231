define("@clark/cms-ui/components/control-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ysKBserX",
    "block": "{\"symbols\":[\"@accessibilityLabel\",\"@icon\",\"&attrs\",\"@onClick\"],\"statements\":[[11,\"button\"],[16,0,[31,[[30,[36,1],[[30,[36,0],[\"control-button \",[32,0,[\"colorStyle\"]]],null]],[[\"from\"],[\"@clark/cms-ui/components/control-button/styles\"]]]]]],[16,\"aria-label\",[32,1]],[16,\"data-test-control-button\",[32,2]],[17,3],[24,4,\"button\"],[4,[38,3],[\"click\",[30,[36,2],[[32,4]],null]],null],[12],[2,\"\\n  \"],[8,\"icon\",[],[[\"@source\",\"@size\"],[[32,2],\"large\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"local-class\",\"optional\",\"on\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/control-button/template.hbs"
    }
  });

  _exports.default = _default;
});