define("@clark/cms-ui/components/multi-source-image/component", ["exports", "@glimmer/component", "@clark/cms-ui/breakpoints"], function (_exports, _component, _breakpoints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MultiSourceImage extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "mediaQueries", _breakpoints.default);
    }

  }

  _exports.default = MultiSourceImage;
});