define("@clark/cms-ui/styles/foundation/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "duration-none": "0",
    "duration-fast": "100ms",
    "duration-normal": "200ms",
    "duration-slow": "300ms",
    "duration-slower": "400ms",
    "duration-slowest": "500ms"
  };
  _exports.default = _default;
});