define("ember-cli-meta-tags/initializers/router-head-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    if (true) {
      Ember.Router.reopen({
        headTags: Ember.inject.service(),

        init() {
          this._super(...arguments);

          this.on('routeDidChange', () => {
            Ember.get(this, 'headTags').collectHeadTags();

            this._super(...arguments);
          });
        }

      });
    } else {
      Ember.Router.reopen({
        headTags: Ember.inject.service(),

        didTransition() {
          Ember.get(this, 'headTags').collectHeadTags();

          this._super(...arguments);
        }

      });
    }
  }

  var _default = {
    name: 'router-head-tags',
    after: 'head-tags',
    initialize
  };
  _exports.default = _default;
});