define("@clark/cms-ui/components/common/accordion/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9a4liJ72",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[2,\"\\n\"],[11,\"div\"],[16,0,[31,[[30,[36,0],[\"accordion\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,2],null,[[\"Item\"],[[30,[36,1],[\"common/accordion/item\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/accordion/template.hbs"
    }
  });

  _exports.default = _default;
});