define("ember-svg-jar/inlined/clark-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.4 86C.4 38 38.1.3 86.6.3c25.6 0 49.5 11.1 64.6 29.6 2.8 3.5 3.8 5.8 3.8 8.3 0 4.8-4.3 8.8-9.3 8.8-3.3 0-6-1.3-8.3-4-13.1-16.3-30.1-24.6-50.5-24.6-37.7 0-66.1 29.1-66.1 67.6 0 38.4 28.4 67.6 66.1 67.6 20.3 0 37.4-8.5 50.5-24.6 2.3-2.8 5-4 8.3-4 5 0 9.3 4 9.3 8.8 0 2.5-1 4.8-3.8 8.3-14.6 18.6-38.7 29.6-64.6 29.6C38.1 171.7.4 134 .4 86m190.4 75.2v-148c0-5.8 4-10.3 9.8-10.3 5.5 0 9.8 4.5 9.8 10.3v140.7H281c5.3 0 9 3.8 9 8.8 0 4.8-3.8 8.8-9 8.8h-79.9c-6 0-10.3-4.6-10.3-10.3M532 81.8h37.9c20.9 0 33.4-11.6 33.4-30.4 0-18.6-12.8-30.6-33.4-30.6H532v61zm105.5 81.1c0 4.8-4 8.8-9.3 8.8-3 0-5.8-1.8-8.5-5.3l-50-67.1H532v62.3c0 5.5-4.3 10.1-9.8 10.1-5.8 0-9.8-4.5-9.8-10.1V13.2c0-5.5 4.3-10.1 10-10.1h48.2c31.7 0 53 19.3 53 48 0 22.1-12.6 38.7-32.9 45.2l43.5 59c2 2.6 3.3 5.1 3.3 7.6zm161.5 0c0 4.8-4 8.8-9 8.8-2.5 0-5-1.3-7.8-4l-79.1-80.9c-1.8-2-3-4.3-3-6.8s1.3-4.8 3.3-7l71.8-68.6c3-2.8 5-4 7.5-4 5 0 9 4 9 8.8 0 2.8-1.3 5-4.3 7.8l-65.7 63 73.6 75.6c2.7 2.3 3.7 4.3 3.7 7.3zM694.5 10.7v150.7c0 5.8-4.3 10.3-9.8 10.3-5.8 0-9.8-4.5-9.8-10.3V10.7c0-5.8 4-10.3 9.8-10.3 5.6 0 9.8 4.5 9.8 10.3zM474.4 155.5L410.9 6.8c-2-4.5-5-6.8-9.3-6.8-4.3 0-7.3 2.3-9.3 6.8l-63.6 148.7c-1 2.5-1.5 4.5-1.5 6.3 0 5 4.3 9.5 9.5 9.5 4.3 0 7.5-2.5 9.3-6.5l12.1-28.8.4-1.1 7.3-17.6 35.7-87.9 34.7 87.9 7.5 17.6 13.3 29.9c2 4 5 6.5 9.5 6.5 5 0 9.5-4.5 9.5-9.5.2-1.8-.3-3.8-1.6-6.3\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#0439d7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "800",
      "height": "172",
      "viewBox": "0 0 800 172"
    }
  };
  _exports.default = _default;
});