define("@clark/cms-ui/helpers/textbox/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateTextboxArgs = validateTextboxArgs;
  _exports.default = void 0;

  function validateTextboxArgs([args]) {
    (false && !(Object.keys(args.header.title).length > 0) && Ember.assert(`Textbox: Missing title argument`, Object.keys(args.header.title).length > 0));
    (false && !(args.items.length === 4) && Ember.assert(`Textbox: Total number of items is not equal to 4`, args.items.length === 4));
  }

  var _default = Ember.Helper.helper(validateTextboxArgs);

  _exports.default = _default;
});