define("@clark/cms-ui/components/common/accordion/item/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NR9DzNVG",
    "block": "{\"symbols\":[\"@for\",\"&attrs\",\"&default\"],\"statements\":[[11,\"label\"],[16,0,[31,[[30,[36,0],[\"accordion-item-label\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/label/styles\"]]]]]],[16,\"for\",[32,1]],[17,2],[24,\"data-test-accordion-item-label\",\"\"],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\\n  \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[\"accordion-item-label-icon\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/label/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"icon\",[],[[\"@source\"],[\"arrow-down\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/accordion/item/label/template.hbs"
    }
  });

  _exports.default = _default;
});