define("ember-router-scroll/services/router-scroll", ["exports", "ember-app-scheduler"], function (_exports, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ATTEMPTS = 0;
  const MAX_ATTEMPTS = 100; // rAF runs every 16ms ideally, so 60x a second

  let requestId;
  let callbackRequestId;
  /**
   * By default, we start checking to see if the document height is >= the last known `y` position
   * we want to scroll to.  This is important for content heavy pages that might try to scrollTo
   * before the content has painted
   *
   * @method tryScrollRecursively
   * @param {Function} fn
   * @param {Object} scrollHash
   * @param {Element} [element]
   * @void
   */

  function tryScrollRecursively(fn, scrollHash, element) {
    let documentHeight; // read DOM outside of rAF

    if (element) {
      documentHeight = Math.max(element.scrollHeight, element.offsetHeight, element.clientHeight);
    } else {
      const {
        body,
        documentElement: html
      } = document;
      documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    }

    callbackRequestId = window.requestAnimationFrame(() => {
      // write DOM (scrollTo causes reflow)
      if (documentHeight >= scrollHash.y || ATTEMPTS >= MAX_ATTEMPTS) {
        ATTEMPTS = 0;
        fn.call(null, scrollHash.x, scrollHash.y);
      } else {
        ATTEMPTS++;
        tryScrollRecursively(fn, scrollHash, element);
      }
    });
  } // to prevent scheduleOnce calling multiple times, give it the same ref to this function


  const CALLBACK = function (transition) {
    this.updateScrollPosition(transition);
  };

  let RouterScroll = (_dec = Ember.inject.service, _dec2 = Ember.computed, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class RouterScroll extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "key", void 0);

      _defineProperty(this, "targetElement", void 0);

      _defineProperty(this, "scrollElement", 'window');

      _defineProperty(this, "isFirstLoad", true);

      _defineProperty(this, "preserveScrollPosition", false);

      _defineProperty(this, "scrollWhenIdle", false);

      _defineProperty(this, "scrollWhenAfterRender", false);
    }

    get isFastBoot() {
      const fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }

    init(...args) {
      super.init(...args);

      this._loadConfig();

      Ember.set(this, 'scrollMap', {
        default: {
          x: 0,
          y: 0
        }
      }); // https://github.com/ember-app-scheduler/ember-app-scheduler/pull/773

      (0, _emberAppScheduler.setupRouter)(this.router);
      Ember.addListener(this.router, 'routeWillChange', this._routeWillChange);
      Ember.addListener(this.router, 'routeDidChange', this._routeDidChange);
    }

    destroy() {
      (0, _emberAppScheduler.reset)();
      Ember.removeListener(this.router, 'routeWillChange', this._routeWillChange);
      Ember.removeListener(this.router, 'routeDidChange', this._routeDidChange);

      if (requestId) {
        window.cancelAnimationFrame(requestId);
      }

      if (callbackRequestId) {
        window.cancelAnimationFrame(callbackRequestId);
      }

      super.destroy(...arguments);
    }
    /**
     * Updates the scroll position
     * it will be a single transition
     * @method updateScrollPosition
     * @param {transition|transition[]} transition If before Ember 3.6, this will be an array of transitions, otherwise
     */


    updateScrollPosition(transition) {
      const url = Ember.get(this, 'currentURL');
      const hashElement = url ? document.getElementById(url.split('#').pop()) : null;

      if (Ember.get(this, 'isFirstLoad')) {
        this.unsetFirstLoad();
      }

      let scrollPosition = Ember.get(this, 'position');

      if (url && url.indexOf('#') > -1 && hashElement) {
        scrollPosition = {
          x: hashElement.offsetLeft,
          y: hashElement.offsetTop
        };
      } // If `preserveScrollPosition` was not set on the controller, attempt fallback to `preserveScrollPosition` which was set on the router service.


      let preserveScrollPosition = (Ember.get(transition, 'router.currentRouteInfos') || []).some(routeInfo => Ember.get(routeInfo, 'route.controller.preserveScrollPosition')) || Ember.get(this, 'preserveScrollPosition');

      if (!preserveScrollPosition) {
        const {
          scrollElement,
          targetElement
        } = this;

        if (targetElement || 'window' === scrollElement) {
          tryScrollRecursively(window.scrollTo, scrollPosition);
        } else if ('#' === scrollElement.charAt(0)) {
          const element = document.getElementById(scrollElement.substring(1));

          if (element) {
            let fn = (x, y) => {
              element.scrollLeft = x;
              element.scrollTop = y;
            };

            tryScrollRecursively(fn, scrollPosition, element);
          }
        }
      }

      Ember.sendEvent(this, 'didScroll', transition);
    }

    _routeWillChange() {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      this.update();
    }

    _routeDidChange(transition) {
      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      const scrollWhenIdle = Ember.get(this, 'scrollWhenIdle');
      const scrollWhenAfterRender = Ember.get(this, 'scrollWhenAfterRender');

      if (!scrollWhenIdle && !scrollWhenAfterRender) {
        // out of the option, this happens on the tightest schedule
        Ember.run.scheduleOnce('render', this, CALLBACK, transition);
      } else if (scrollWhenAfterRender && !scrollWhenIdle) {
        // out of the option, this happens on the second tightest schedule
        Ember.run.scheduleOnce('afterRender', this, CALLBACK, transition);
      } else {
        (0, _emberAppScheduler.whenRouteIdle)().then(() => {
          this.updateScrollPosition(transition);
        });
      }
    }

    unsetFirstLoad() {
      Ember.set(this, 'isFirstLoad', false);
    }

    update() {
      if (Ember.get(this, 'isFastBoot') || Ember.get(this, 'isFirstLoad')) {
        return;
      }

      const scrollElement = Ember.get(this, 'scrollElement');
      const targetElement = Ember.get(this, 'targetElement');
      const scrollMap = Ember.get(this, 'scrollMap');
      const key = Ember.get(this, 'key');
      let x;
      let y;

      if (targetElement) {
        let element = document.querySelector(targetElement);

        if (element) {
          x = element.offsetLeft;
          y = element.offsetTop; // if we are looking to where to transition to next, we need to set the default to the position
          // of the targetElement on screen

          Ember.set(scrollMap, 'default', {
            x,
            y
          });
        }
      } else if ('window' === scrollElement) {
        x = window.scrollX;
        y = window.scrollY;
      } else if ('#' === scrollElement.charAt(0)) {
        let element = document.getElementById(scrollElement.substring(1));

        if (element) {
          x = element.scrollLeft;
          y = element.scrollTop;
        }
      } // only a `key` present after first load


      if (key && 'number' === Ember.typeOf(x) && 'number' === Ember.typeOf(y)) {
        Ember.set(scrollMap, key, {
          x,
          y
        });
      }
    }

    _loadConfig() {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config && config.routerScroll) {
        const scrollElement = config.routerScroll.scrollElement;
        const targetElement = config.routerScroll.targetElement;
        (false && !(!(scrollElement && targetElement)) && Ember.assert('You defined both scrollElement and targetElement in your config. We currently only support definining one of them', !(scrollElement && targetElement)));

        if ('string' === Ember.typeOf(scrollElement)) {
          Ember.set(this, 'scrollElement', scrollElement);
        }

        if ('string' === Ember.typeOf(targetElement)) {
          Ember.set(this, 'targetElement', targetElement);
        }

        const {
          scrollWhenIdle = false,
          scrollWhenAfterRender = false
        } = config.routerScroll;
        Ember.set(this, 'scrollWhenIdle', scrollWhenIdle);
        Ember.set(this, 'scrollWhenAfterRender', scrollWhenAfterRender);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isFastBoot", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isFastBoot"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeWillChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_routeWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeDidChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_routeDidChange"), _class.prototype)), _class));
  Object.defineProperty(RouterScroll.prototype, 'position', {
    configurable: true,

    get() {
      const scrollMap = Ember.get(this, 'scrollMap');
      const stateUuid = Ember.get(window, 'history.state.uuid');
      Ember.set(this, 'key', stateUuid);
      const key = Ember.get(this, 'key') || '-1';
      return Ember.get(scrollMap, key) || scrollMap.default;
    }

  });
  var _default = RouterScroll;
  _exports.default = _default;
});