define("@clark/cms-ui/components/modal/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "z-index-modal": "519",
    "duration-normal": "200ms",
    "shadow-layer": "0 0.125rem 0.25rem 0 rgba(173, 182, 217, 0.5)",
    "border-radius-normal": "0.25rem",
    "c-white": "#FFFFFF",
    "from-medium": "only screen and (min-width: 769px)",
    "vertical-spacing": "3.75rem",
    "medium-max-width": "47.5rem",
    "full-max-width": "none",
    "top-offset-slide-in-start": "12.5rem",
    "container": "_container_130r74",
    "modal": "_modal_130r74",
    "size-medium": "_size-medium_130r74",
    "size-full": "_size-full_130r74",
    "enter": "_enter_130r74",
    "exit": "_exit_130r74",
    "exit-active": "_exit-active_130r74",
    "enter-active": "_enter-active_130r74"
  };
  _exports.default = _default;
});