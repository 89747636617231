define("@clark/cms-ui/components/cms-footer/iconed-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BgsyJqD/",
    "block": "{\"symbols\":[\"&attrs\",\"@media\",\"&default\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"args\"]]],null]],[2,\"\\n\"],[11,\"div\"],[16,0,[31,[[30,[36,1],[\"container\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/iconed-content/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[\"media-container\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/iconed-content/styles\"]]]]]],[14,\"data-test-iconed-media\",\"\"],[12],[2,\"\\n    \"],[8,\"icon\",[],[[\"@source\"],[[32,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[\"content-container\"],[[\"from\"],[\"@clark/cms-ui/components/cms-footer/iconed-content/styles\"]]]]]],[14,\"data-test-iconed-content\",\"\"],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"iconed-content/validate-args\",\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/cms-footer/iconed-content/template.hbs"
    }
  });

  _exports.default = _default;
});