define("@clark/cms-ui/modifiers/grid", ["exports", "ember-modifier", "@clark/cms-ui/styles/grid"], function (_exports, _emberModifier, _grid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/new-module-imports
  var _default = (0, _emberModifier.modifier)((element, [classNames]) => {
    element.classList.add(...classNames.split(/\s+/g).map(c => _grid.default[c]).filter(Boolean)); // eslint-disable-next-line @typescript-eslint/no-empty-function

    return () => {};
  });

  _exports.default = _default;
});