define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.525 26.88c-.428 0-.875.058-1.342.173a4.741 4.741 0 00-3.305 3.168c-.622 2.035.117 3.897 1.478 5.069l-1.653 3.763a.975.975 0 00.934 1.267h7.776a.956.956 0 00.913-1.267l-1.652-3.763c1.05-.884 1.71-2.15 1.71-3.61 0-2.65-2.177-4.8-4.86-4.8zM19.07 38.4h-5.093l1.633-3.725-.992-.845c-.894-.768-1.224-1.92-.894-3.072.272-.883 1.03-1.612 1.944-1.862.292-.058.583-.096.856-.096 1.613 0 2.916 1.286 2.916 2.88 0 .826-.37 1.594-1.03 2.17l-.992.844L19.07 38.4zm11.062-19.2h-2.916v-8.64C27.217 4.723 22.435 0 16.525 0S5.832 4.723 5.832 10.56v8.64H2.916C1.303 19.2 0 20.486 0 22.08v23.04C0 46.714 1.303 48 2.916 48h27.217c1.614 0 2.916-1.286 2.916-2.88V22.08c0-1.594-1.302-2.88-2.916-2.88zM7.776 10.56c0-4.762 3.927-8.64 8.749-8.64 4.82 0 8.748 3.878 8.748 8.64v8.64H7.776v-8.64zm23.33 34.56c0 .538-.429.96-.973.96H2.916a.957.957 0 01-.972-.96V22.08c0-.538.428-.96.972-.96h27.217c.544 0 .972.422.972.96v23.04z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "34",
      "height": "48",
      "viewBox": "0 0 34 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});