define("@clark/cms-ui/components/lead-gen/consultant-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h5xH/Fvj",
    "block": "{\"symbols\":[\"@image\",\"@vertical\",\"&attrs\",\"@name\",\"@position\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[[30,[36,3],[\"consultant-card \",[30,[36,2],[[32,2],\"layout-vertical\"],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/lead-gen/consultant-card/styles\"]]]]]],[24,\"data-test-consultant-card\",\"\"],[17,3],[12],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"image-container\"],[[\"from\"],[\"@clark/cms-ui/components/lead-gen/consultant-card/styles\"]]]]]],[14,\"data-test-consultant-card-image-container\",\"\"],[12],[2,\"\\n      \"],[8,\"multi-source-image\",[[24,\"data-test-consultant-card-image\",\"\"]],[[\"@fallbackImgSource\",\"@imgClass\"],[[30,[36,1],null,[[\"url\",\"altText\"],[[32,1,[\"url\"]],[32,1,[\"altText\"]]]]],[30,[36,0],[\"image\"],[[\"from\"],[\"@clark/cms-ui/components/lead-gen/consultant-card/styles\"]]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"body\"],[[\"from\"],[\"@clark/cms-ui/components/lead-gen/consultant-card/styles\"]]]]]],[14,\"data-test-consultant-card-body\",\"\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"name\"],[[\"from\"],[\"@clark/cms-ui/components/lead-gen/consultant-card/styles\"]]]]]],[14,\"data-test-consultant-card-name\",\"\"],[12],[2,\"\\n      \"],[1,[32,4]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"position\"],[[\"from\"],[\"@clark/cms-ui/components/lead-gen/consultant-card/styles\"]]]]]],[14,\"data-test-consultant-card-position\",\"\"],[12],[2,\"\\n      \"],[1,[32,5]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"hash\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/lead-gen/consultant-card/template.hbs"
    }
  });

  _exports.default = _default;
});