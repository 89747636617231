define("@clark-bi/tracking/helpers/track", ["exports", "lodash.mapvalues"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _class2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * This helper returns an action that, when called, creates a tracking event
   * using the `tracking` service.
   *
   * Sending the bare event:
   *
   * ```hbs
   * <button {{on "click" (track "customer:account:login")}}>
   * ```
   *
   * Remember: You can have multiple `{{on}}` modifiers, one for your business logic,
   * the second for tracking.
   *
   * Sending the event with additional properties:
   *
   * ```hbs
   * <button {{on "click" (track "contracts:details:view-document" document_id="1744")}}>
   * ```
   */
  let TrackHelper = (_dec = Ember.inject.service, (_class = (_temp = _class2 = class TrackHelper extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "tracking", _descriptor, this);
    }

    partitionDataAndMapping(object) {
      const data = {};
      const mapping = {};

      for (const [key, value] of Object.entries(object)) {
        if (key.startsWith(TrackHelper.MAPPING_SIGIL)) {
          mapping[key.slice(TrackHelper.MAPPING_SIGIL.length)] = value;
        } else {
          data[key] = value;
        }
      }

      return [data, mapping];
    }

    mapProperties(object, mapping) {
      return (0, _lodash.default)(mapping, key => Ember.get(object, key));
    }

    compute([eventName], dataAndMapping) {
      const [data, mapping] = this.partitionDataAndMapping(dataAndMapping);
      return primary => {
        const mappingData = primary instanceof Object ? this.mapProperties(primary, mapping) : {}; // @ts-ignore

        this.tracking.track(eventName, { ...data,
          ...mappingData
        });
      };
    }

  }, _defineProperty(_class2, "MAPPING_SIGIL", '$'), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tracking", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TrackHelper;
});