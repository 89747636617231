define("@clark/cms-ui/components/table-of-contents/dropdown/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "from-desktop": "only screen and (min-width: 1200px)",
    "duration-normal": "200ms",
    "header-height": "80px",
    "layout-max-width": "1200px",
    "layout-side-spacing": "24px",
    "trigger-button-height": "64px",
    "button-text-color": "#000000",
    "button-arrow-color": "#203151",
    "button-outline-color": "#0439d7",
    "content-shadow-width": "2px",
    "dropdown": "_dropdown_mjwct9",
    "layout-container": "_layout-container_mjwct9",
    "dropdown-trigger-button": "_dropdown-trigger-button_mjwct9",
    "dropdown-trigger-button-text": "_dropdown-trigger-button-text_mjwct9",
    "dropdown-trigger-button-arrow": "_dropdown-trigger-button-arrow_mjwct9",
    "is-expanded": "_is-expanded_mjwct9",
    "dropdown-content-container": "_dropdown-content-container_mjwct9",
    "dropdown-content": "_dropdown-content_mjwct9",
    "enter": "_enter_mjwct9",
    "exit": "_exit_mjwct9",
    "exit-active": "_exit-active_mjwct9",
    "enter-active": "_enter-active_mjwct9"
  };
  _exports.default = _default;
});