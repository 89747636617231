define("@clark/cms-ui/helpers/transform-rich-text-header", ["exports", "@contentful/rich-text-html-renderer", "@clark/cms-ui/styles/typo"], function (_exports, _richTextHtmlRenderer, _typo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const headings = {};
  new Array(6).fill('').forEach((_, i) => {
    const index = i + 1;
    const tag = `h${index}`;

    headings[`heading-${index}`] = (headerNode, next) => {
      let content = next(headerNode.content);
      content = content.replace(/\n/g, '<br/>');
      return `<${tag} class='${_typo.default[tag]}'>
      ${content}
    </${tag}>`;
    };
  });

  function transformRichTextHeader(parameters) {
    const [node] = parameters;
    if (!node) return '';
    const options = {
      renderNode: { ...headings
      }
    };
    return Ember.String.htmlSafe((0, _richTextHtmlRenderer.documentToHtmlString)(node, options)).toString();
  }

  var _default = Ember.Helper.helper(transformRichTextHeader);

  _exports.default = _default;
});