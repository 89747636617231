define("@clark/cms-ui/components/control-button/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ControlButtonComponent extends _component.default {
    get colorStyle() {
      return this.args.color ? `color-${this.args.color}` : '';
    }

  }

  _exports.default = ControlButtonComponent;
});