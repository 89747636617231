define("@clark/cms-ui/components/success-message/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "success-message-container": "_success-message-container_cdi8bl",
    "success-message-image": "_success-message-image_cdi8bl",
    "success-message-header": "_success-message-header_cdi8bl",
    "success-message-description": "_success-message-description_cdi8bl"
  };
  _exports.default = _default;
});