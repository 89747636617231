define("@clark/cms-ui/helpers/iconed-content/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateMediaAndSizeArgs = validateMediaAndSizeArgs;
  _exports.default = void 0;

  function validateMediaAndSizeArgs([args]) {
    const sizes = ['sm', 'md', 'lg']; // assert(`size and media arguments should be passed`, isPresent(args));

    (false && !(Ember.isPresent(args.media)) && Ember.assert(`ember-svg-jar: Missing inline SVG for ${args.media}`, Ember.isPresent(args.media)));
    (false && !(sizes.includes(args.size)) && Ember.assert(`Iconed content svg size should fall under ${sizes.join(', ')}`, sizes.includes(args.size)));
  }

  var _default = Ember.Helper.helper(validateMediaAndSizeArgs);

  _exports.default = _default;
});