define("@clark/cms-api/errors/api", ["exports", "lodash.frompairs"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function serializeHeadersForLog(headers) {
    return (0, _lodash.default)([...headers.entries()]);
  }
  /**
   * Base class for all API errors.
   */


  class APIError extends Error {
    constructor(request, response) {
      super();

      _defineProperty(this, "request", void 0);

      _defineProperty(this, "response", void 0);

      this.request = request;
      this.response = response;
    }

    get message() {
      return `Could not load '${this.request.url}'`;
    }

    get name() {
      return this.constructor.name;
    }

    get url() {
      return this.request.url;
    }

    get requestHeaders() {
      return serializeHeadersForLog(this.request.headers);
    }

    get responseHeaders() {
      if (this.response) {
        return serializeHeadersForLog(this.response.headers);
      }

      return undefined;
    }

    get sentryExtra() {
      return Ember.getProperties(this, 'request', 'response', 'requestHeaders', 'responseHeaders');
    }

  }

  _exports.default = APIError;
});