define("@clark/cms-api/http-verb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HTTPVerb;

  (function (HTTPVerb) {
    HTTPVerb["GET"] = "GET";
    HTTPVerb["POST"] = "POST";
    HTTPVerb["PATCH"] = "PATCH";
    HTTPVerb["PUT"] = "PUT";
    HTTPVerb["DELETE"] = "DELETE";
  })(HTTPVerb || (HTTPVerb = {}));

  var _default = HTTPVerb;
  _exports.default = _default;
});