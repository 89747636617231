define("ember-click-outside/utils", ["exports", "ember-click-outside/-private/matches-selector"], function (_exports, _matchesSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.closest = closest;
  _exports.printConsoleMessage = printConsoleMessage;
  _exports.ios = _exports.documentOrBodyContains = void 0;

  /* eslint no-console: "off" */
  function closest(element, selector) {
    if ((0, _matchesSelector.matches)(element, selector)) {
      return element;
    }

    while (element.parentNode) {
      element = element.parentNode;

      if ((0, _matchesSelector.matches)(element, selector)) {
        return element;
      }
    }
  }

  const documentOrBodyContains = element => {
    // https://github.com/zeppelin/ember-click-outside/issues/30
    if (typeof document.contains === 'function') {
      return document.contains(element);
    } else {
      return document.body.contains(element);
    }
  };

  _exports.documentOrBodyContains = documentOrBodyContains;

  const ios = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }; // https://github.com/mike-north/ember-deprecated/blob/master/addon/utils.js


  _exports.ios = ios;

  function printConsoleMessage(msg) {
    if (console.trace) {
      if (console.groupCollapsed) {
        console.groupCollapsed(msg);
        console.trace();
        console.groupEnd();
      } else {
        console.warn(msg);
        console.trace();
      }
    } else {
      console.warn(msg);
    }
  }
});