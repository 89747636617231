define("@clark/cms-ui/components/link/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-primary": "#2B6CDE",
    "cta-primary-hover": "#094FCB",
    "cta-primary-pressed": "#103C8A",
    "link": "_link_6qm1ms",
    "subdued": "_subdued_6qm1ms",
    "monochrome": "_monochrome_6qm1ms",
    "icon-lockup": "_icon-lockup_6qm1ms",
    "icon-layout": "_icon-layout_6qm1ms"
  };
  _exports.default = _default;
});