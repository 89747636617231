define("@clark/cms-ui/components/lead-gen/section/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "header-height": "80px",
    "trigger-button-height": "64px",
    "c-white": "#FFFFFF",
    "rich-black": "#01051C",
    "tiempos-headline": "'TiemposHeadline'",
    "pane-background-color-default": "#0439d7",
    "pane-background-color-success": "#FFFFFF",
    "title-color-default": "#FFFFFF",
    "text-color-default": "#FFFFFF",
    "text-color-success": "#203151",
    "shadow-default": "0px 2px 2px rgba(173, 182, 217, 0.5)",
    "fixed-header-scroll-offset": "calc(80px + 64px + 30px)",
    "lead-gen-section": "_lead-gen-section_4y7bxr",
    "layout-container": "_layout-container_4y7bxr",
    "box": "_box_4y7bxr",
    "aside-content": "_aside-content_4y7bxr",
    "aside-content-main": "_aside-content-main_4y7bxr",
    "image-container": "_image-container_4y7bxr",
    "image-wrapper": "_image-wrapper_4y7bxr",
    "image": "_image_4y7bxr",
    "body": "_body_4y7bxr",
    "content-pane": "_content-pane_4y7bxr",
    "title": "_title_4y7bxr",
    "text": "_text_4y7bxr",
    "text-centered": "_text-centered_4y7bxr",
    "actions": "_actions_4y7bxr",
    "cta-button": "_cta-button_4y7bxr",
    "low-margin-content-pane": "_low-margin-content-pane_4y7bxr",
    "success-pane": "_success-pane_4y7bxr",
    "consultant-text": "_consultant-text_4y7bxr",
    "trustpilot-backdrop": "_trustpilot-backdrop_4y7bxr",
    "trustpilot-widget": "_trustpilot-widget_4y7bxr"
  };
  _exports.default = _default;
});