define("@clark/cms-ui/components/textbox/textbox-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4bb2X/eL",
    "block": "{\"symbols\":[\"&attrs\",\"@icon\",\"@title\",\"@description\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"icon-container\"],[[\"from\"],[\"@clark/cms-ui/components/textbox/textbox-item/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"icon\",[],[[\"@source\",\"@size\"],[[32,2],\"extra-large\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h4\"],[15,0,[31,[[30,[36,1],[\"h4\"],null],\" \",[30,[36,0],[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/textbox/textbox-item/styles\"]]]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],[[32,3]],[[\"class\"],[[30,[36,2],[\"reset-styles\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,3],[[32,4]],[[\"class\"],[[30,[36,1],[\"c2\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"typo\",\"markdown\",\"markdown-to-html\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/textbox/textbox-item/template.hbs"
    }
  });

  _exports.default = _default;
});