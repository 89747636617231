define("@clark/cms-ui/helpers/cms-section/validate-header-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateSectionTitleArgument = validateSectionTitleArgument;
  _exports.default = void 0;

  function validateSectionTitleArgument([args]) {
    (false && !(Object.keys(args.title).length > 0) && Ember.assert(`cms-section/header: Missing title argument`, Object.keys(args.title).length > 0));
  }

  var _default = Ember.Helper.helper(validateSectionTitleArgument);

  _exports.default = _default;
});