define("@clark/cms-ui/modifiers/dropdown-menu", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * THIS  MODIFIER IS ADOPTED FROM JQUERY-MENU-AIM PROJECT.
   * almost 90% code is copied from there.
   * https://github.com/kamens/jQuery-menu-aim
   */

  /* eslint @typescript-eslint/no-empty-function: 0 */
  const MOUSE_LOCS_TRACKED = 3; // number of past mouse locations to track

  const DELAY = 300; // ms delay when user appears to be entering submenu

  const DEFAULTS = {
    rowSelector: 'li',
    submenuSelector: '*',
    submenuDirection: 'below',
    tolerance: 100,
    // bigger = more forgivey when entering submenu
    enter: () => {},
    exit: () => {},
    activate: () => {},
    deactivate: () => {}
  }; // eslint-disable-next-line ember/new-module-imports

  var _default = (0, _emberModifier.modifier)((element, positional) => {
    const [options_] = positional;
    const options = Object.assign(DEFAULTS, options_);
    const menu = element;
    const menuRows = menu.querySelectorAll(options.rowSelector);
    let activeRow = null;
    const mouseLocs = [];
    let lastDelayLoc = null;
    let timeoutId = null;
    /**
     * Return the amount of time that should be used as a delay before the
     * currently hovered row is activated.
     *
     * Returns 0 if the activation should happen immediately. Otherwise,
     * returns the number of milliseconds that should be delayed before
     * checking again to see if the row should be activated.
     */

    const activationDelay = function () {
      if (!activeRow) {
        // If there is no other submenu row already active, then
        // go ahead and activate immediately.
        return 0;
      }

      const offsetMenu = activeRow.getBoundingClientRect();
      const offsetSubMenu = activeRow.querySelector(options.submenuSelector).getBoundingClientRect();
      const upperLeft = {
        x: offsetSubMenu.left,
        y: offsetMenu.top - options.tolerance
      };
      const upperRight = {
        x: offsetSubMenu.left + offsetSubMenu.width,
        y: upperLeft.y
      };
      const lowerLeft = {
        x: offsetSubMenu.left,
        y: offsetMenu.top + offsetSubMenu.height + options.tolerance
      };
      const lowerRight = {
        x: offsetSubMenu.left + offsetSubMenu.width,
        y: lowerLeft.y
      };
      const loc = mouseLocs[mouseLocs.length - 1];
      let [previousLoc] = mouseLocs;

      if (!loc) {
        return 0;
      }

      if (!previousLoc) {
        previousLoc = loc;
      }

      if (previousLoc.x < offsetSubMenu.left || previousLoc.x > lowerRight.x || previousLoc.y < offsetMenu.top || previousLoc.y > lowerRight.y) {
        // If the previous mouse location was outside of the entire
        // menu's bounds, immediately activate.
        return 0;
      }

      if (lastDelayLoc && loc.x === lastDelayLoc.x && loc.y === lastDelayLoc.y) {
        // If the mouse hasn't moved since the last time we checked
        // for activation status, immediately activate.
        return 0;
      } // Detect if the user is moving towards the currently activated
      // submenu.
      //
      // If the mouse is heading relatively clearly towards
      // the submenu's content, we should wait and give the user more
      // time before activating a new row. If the mouse is heading
      // elsewhere, we can immediately activate a new row.
      //
      // We detect this by calculating the slope formed between the
      // current mouse location and the upper/lower right points of
      // the menu. We do the same for the previous mouse location.
      // If the current mouse location's slopes are
      // increasing/decreasing appropriately compared to the
      // previous's, we know the user is moving toward the submenu.
      //
      // Note that since the y-axis increases as the cursor moves
      // down the screen, we are looking for the slope between the
      // cursor and the upper right corner to decrease over time, not
      // increase (somewhat counterintuitively).


      function slope(a, b) {
        return (b.y - a.y) / (b.x - a.x);
      }

      let decreasingCorner = upperRight;
      let increasingCorner = lowerRight; // Our expectations for decreasing or increasing slope values
      // depends on which direction the submenu opens relative to the
      // main menu. By default, if the menu opens on the right, we
      // expect the slope between the cursor and the upper right
      // corner to decrease over time, as explained above. If the
      // submenu opens in a different direction, we change our slope
      // expectations.

      if (options.submenuDirection === 'left') {
        decreasingCorner = lowerLeft;
        increasingCorner = upperLeft;
      } else if (options.submenuDirection === 'below') {
        decreasingCorner = lowerRight;
        increasingCorner = lowerLeft;
      } else if (options.submenuDirection === 'above') {
        decreasingCorner = upperLeft;
        increasingCorner = upperRight;
      }

      const decreasingSlope = slope(loc, decreasingCorner);
      const increasingSlope = slope(loc, increasingCorner);
      const previousDecreasingSlope = slope(previousLoc, decreasingCorner);
      const previousIncreasingSlope = slope(previousLoc, increasingCorner);

      if (decreasingSlope < previousDecreasingSlope || increasingSlope > previousIncreasingSlope) {
        // Mouse is moving from previous location towards the
        // currently activated submenu. Delay before activating a
        // new menu row, because user may be moving into submenu.
        lastDelayLoc = loc;
        return DELAY;
      }

      lastDelayLoc = null;
      return 0;
    };
    /**
     * Activate a menu row.
     */


    const activate = function (row) {
      if (row === activeRow) {
        return;
      }

      if (activeRow) {
        options.deactivate(activeRow);
      }

      options.activate(row);
      activeRow = row;
    };
    /**
     * Possibly activate a menu row. If mouse movement indicates that we
     * shouldn't activate yet because user may be trying to enter
     * a submenu's content, then delay and check again later.
     */


    const possiblyActivate = function (row) {
      const delay = activationDelay();

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (delay) {
        timeoutId = setTimeout(function () {
          possiblyActivate(row);
        }, delay);
      } else {
        activate(row);
      }
    };
    /**
     * Keep track of the last few locations of the mouse.
     */


    const mousemoveDocument = function (event) {
      // this value becoming negative when user scrolls down to page.
      const scrollFromTop = document.body.getBoundingClientRect().y;
      mouseLocs.push({
        x: event.pageX,
        y: event.pageY + scrollFromTop
      });

      if (mouseLocs.length > MOUSE_LOCS_TRACKED) {
        mouseLocs.shift();
      }
    };
    /**
     * Cancel possible row activations when leaving the menu entirely
     */


    const mouseleaveMenu = function () {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (activeRow) {
        options.deactivate(activeRow);
      }

      activeRow = null;
    };
    /**
     * Trigger a possible row activation whenever entering a new row.
     */


    const mouseenterRow = function () {
      if (timeoutId) {
        // Cancel any previous activation delays
        clearTimeout(timeoutId);
      }

      options.enter(this);
      possiblyActivate(this);
    };
    /**
     * Immediately activate a row if the user clicks on it.
     */


    const clickRow = function () {
      activate(this);
    };
    /**
     * Deactivate menu when user out of it
     */


    const mouseleaveRow = function () {
      const delay = activationDelay(); // if delay returns higher than 0 then
      // user is intented to move cursor to the menu.

      if (!delay) {
        options.deactivate(activeRow);
        activeRow = null;
      }
    };
    /**
     * Hook up initial menu events
     */


    menu.addEventListener('mouseleave', mouseleaveMenu, false);
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/NodeList#Example
     * However, some older browsers have not implemented NodeList.forEach()
     * */

    Array.prototype.forEach.call(menuRows, menuRow => {
      menuRow.addEventListener('mouseenter', mouseenterRow, false);
      menuRow.addEventListener('mouseleave', mouseleaveRow, false);
      menuRow.addEventListener('click', clickRow, false);
    });
    window.addEventListener('mousemove', mousemoveDocument, false);
    return () => {};
  });

  _exports.default = _default;
});