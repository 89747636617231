define("@clark/cms-ui/components/form-builder/input-field/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input-border-default": "#A8AAB2",
    "input-border-active": "#2B6CDE",
    "input-field-wrapper": "_input-field-wrapper_80q2i0",
    "form-input": "_form-input_80q2i0"
  };
  _exports.default = _default;
});