define("ember-is-mobile/services/is-mobile", ["exports", "ember-is-mobile"], function (_exports, _emberIsMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * The attributes returned by isMobile are accessible. However, they should be
   * accessed using the `get` helper, since they may be undefined if the user
   * agent header is blank.
   */
  let IsMobileService = (_dec = Ember.computed, (_class = (_temp = class IsMobileService extends Ember.Service {
    get fastboot() {
      return Ember.getOwner(this).lookup('service:fastboot');
    }

    constructor() {
      super(...arguments);

      _defineProperty(this, "tests", {});

      let tests;

      if (this.fastboot !== undefined && this.fastboot.isFastBoot) {
        let headers = this.fastboot.request.headers;
        let userAgent = headers.headers['user-agent']; // isMobile tries to fetch `navigator` if we give it a blank user agent.

        if (Ember.isBlank(userAgent)) {
          return;
        }

        tests = (0, _emberIsMobile.default)(userAgent[0]);
      } else {
        tests = (0, _emberIsMobile.default)();
      }

      for (let media in tests) {
        this[media] = tests[media];
      }

      this.tests = tests;
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "fastboot"), _class.prototype)), _class));
  _exports.default = IsMobileService;
});