define("ember-svg-jar/inlined/crosslink-chevron-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.733 7.137A1 1 0 1110.27 5.86l4.997 6a1 1 0 010 1.279l-4.997 6a1 1 0 01-1.537-1.278l4.464-5.361-4.464-5.362z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});