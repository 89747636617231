define("ember-svg-jar/inlined/user-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M37.026 37.876a240.15 240.15 0 00-4.932-2.967H36c.393 0 .742-.196.938-.545.197-.328.197-.742.022-1.091-1.2-2.16-2.05-9.557-2.05-18C34.91 6.85 28.057 0 19.635 0 11.215 0 4.364 6.85 4.364 15.273c0 8.443-.851 15.84-2.051 18.021-.197.328-.175.742.022 1.092.196.327.545.523.938.523h3.6c-1.79 1.07-3.47 2.117-4.713 2.946A4.695 4.695 0 000 41.803v.087a4.692 4.692 0 003.927 4.647C7.833 47.171 14.007 48 19.637 48c5.628 0 11.803-.83 15.709-1.462a4.692 4.692 0 003.927-4.647c0-1.636-.851-3.164-2.248-4.015zM4.886 32.727c1.2-3.97 1.658-11.541 1.658-17.454 0-7.222 5.87-13.091 13.091-13.091 7.222 0 13.091 5.869 13.091 13.09 0 5.914.459 13.484 1.659 17.455h-6.022c-.044 0-.088.022-.131.022-.698-.37-1.375-.72-1.986-1.004 2.27-2.465 3.819-6.087 4.19-9.992a1.08 1.08 0 001.156-.917 1.07 1.07 0 00-.939-1.221c-3.905-.546-7.178-3.23-8.356-6.83-.13-.48-.567-.829-1.025-.785-.48 0-.895.306-1.026.764-1.483 4.778-6.327 7.745-11.28 6.742a1.094 1.094 0 00-1.287.872c-.11.59.262 1.157.85 1.266.11.022.197.022.285.043.349 3.884 1.876 7.484 4.101 9.971a33.79 33.79 0 00-2.181 1.091H4.887v-.022zm14.75.066c-4.146 0-7.92-4.997-8.597-10.91a11.834 11.834 0 0010.276-6.108c1.528 2.596 4.037 4.538 6.982 5.476-.458 6.196-4.385 11.542-8.662 11.542zm15.337 11.585c-4.014.655-9.948 1.44-15.338 1.44-5.389 0-11.323-.785-15.338-1.44-1.222-.196-2.116-1.243-2.116-2.574 0-.851.436-1.659 1.156-2.117 5.847-3.796 9.404-5.65 11.346-6.436 1.483 1.09 3.163 1.745 4.952 1.745 1.746 0 3.382-.61 4.844-1.658 1.898.851 5.433 2.727 11.41 6.415a2.464 2.464 0 011.2 2.138c0 1.244-.894 2.29-2.116 2.487z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "40",
      "height": "48",
      "viewBox": "0 0 40 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});