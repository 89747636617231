define("@clark/cms-ui/components/spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ge3ajaXL",
    "block": "{\"symbols\":[\"@accessibilityLabel\",\"&attrs\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"args\"]]],null]],[2,\"\\n\\n\"],[11,\"div\"],[17,2],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/svgs/spinner.svg\"],[14,\"alt\",\"\"],[14,\"draggable\",\"false\"],[15,0,[32,0,[\"classes\"]]],[12],[13],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"visually-hidden\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"spinner/validate-args\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/spinner/template.hbs"
    }
  });

  _exports.default = _default;
});