define("@clark/cms-ui/components/cms-footer/link/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "link-item": "_link-item_a5mqta",
    "link": "_link_a5mqta _footer-link_j7sg6p _footer-link-typography_j7sg6p _c3_16bl1i _copy-text_16bl1i"
  };
  _exports.default = _default;
});