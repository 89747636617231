define("@clark/cms-ui/components/numbered-list/item/number-icon/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NumberIconComponent extends _component.default {
    get number() {
      const {
        number = 0
      } = this.args;
      return `${number}`.padStart(2, '0');
    }

  }

  _exports.default = NumberIconComponent;
});