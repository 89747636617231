define("@clark/cms-ui/components/lead-gen/high-margin-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "c-white": "#FFFFFF",
    "form-cell-spacing": "20px",
    "form-error-text-color": "#203151",
    "form-error-bg": "#f9dde5",
    "form-error-icon-color": "#d51e50",
    "description": "_description_m250e8",
    "form-layout": "_form-layout_m250e8",
    "form-body": "_form-body_m250e8",
    "form-block": "_form-block_m250e8",
    "form-cell": "_form-cell_m250e8",
    "direction-horizontal": "_direction-horizontal_m250e8",
    "form-actions": "_form-actions_m250e8",
    "form-submit-button": "_form-submit-button_m250e8",
    "form-control": "_form-control_m250e8",
    "form-error": "_form-error_m250e8",
    "form-error-icon": "_form-error-icon_m250e8",
    "form-error-content": "_form-error-content_m250e8"
  };
  _exports.default = _default;
});