define("@clark/cms-ui/components/common/table/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-secondary-border": "#DADDEC",
    "dark-blue": "#0439D7",
    "c-white": "#FFFFFF",
    "c-title": "rgba(32, 49, 81, 1)",
    "background-light-gray": "#F5F6FA",
    "from-medium": "only screen and (min-width: 769px)",
    "min-cell-width": "200px",
    "table-full-width": "_table-full-width_1hkprn",
    "table-wrapper": "_table-wrapper_1hkprn",
    "table-horizontal": "_table-horizontal_1hkprn"
  };
  _exports.default = _default;
});