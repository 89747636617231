define("@clark/cms-ui/helpers/spinner/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateSpinnerArgs = validateSpinnerArgs;
  _exports.default = void 0;

  function validateSpinnerArgs([args]) {
    const availableSizes = ['small', 'medium', 'large'];
    const availableColor = ['white', 'blue'];
    (false && !(!args.size || availableSizes.includes(args.size)) && Ember.assert(`Invalid value for size ${args.size}, choose one of possible: ${availableSizes.join(', ')}`, !args.size || availableSizes.includes(args.size)));
    (false && !(!args.color || availableColor.includes(args.color)) && Ember.assert(`Invalid value for color ${args.color}, choose one of possible: ${availableColor.join(', ')}`, !args.color || availableColor.includes(args.color)));
  }

  var _default = Ember.Helper.helper(validateSpinnerArgs);

  _exports.default = _default;
});