define("ember-svg-jar/inlined/phv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.821 1.493C1.186.884 1.878.5 3 .5h11.5c.366 0 .869.14 1.284.54.428.41.716 1.05.716 1.96v.029l-1.507 13.06-.008.032A2.5 2.5 0 0112.5 18H9v10.5h5.5a.5.5 0 010 1h-12a.5.5 0 010-1H8V18H4.958c-.47 0-1.06 0-1.592-.228-.594-.254-1.054-.756-1.34-1.614l-.017-.05L.5 3.029v-.056c0-.323 0-.942.321-1.48zm.68 1.478L2.99 15.89c.212.607.494.846.768.964.327.14.718.147 1.241.147h7.5c.902 0 1.372-.6 1.507-1.092L15.5 2.972c-.006-.671-.214-1.024-.409-1.211A.907.907 0 0014.5 1.5H8.75L9 2 6 5l2 3.5-2 3 .5-3L4 5l3-3-.5-.5H3c-.878 0-1.186.282-1.321.507-.164.273-.178.622-.179.964z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "17",
      "height": "30",
      "viewBox": "0 0 17 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});