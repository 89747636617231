define("@clark/cms-ui/components/icon/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "icon": "_icon_1wj0f1",
    "svg": "_svg_1wj0f1",
    "placeholder": "_placeholder_1wj0f1",
    "size-extra-small": "_size-extra-small_1wj0f1",
    "size-small": "_size-small_1wj0f1",
    "size-medium": "_size-medium_1wj0f1",
    "size-large": "_size-large_1wj0f1",
    "size-extra-large": "_size-extra-large_1wj0f1"
  };
  _exports.default = _default;
});