define("@clark/cms-ui/helpers/crosslink-item/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateArgs = validateArgs;
  _exports.default = void 0;

  function validateArgs([args]) {
    (false && !(Boolean(args.title)) && Ember.assert(`Crosslink item missing title`, Boolean(args.title)));
    (false && !(Boolean(args.content)) && Ember.assert(`Crosslink item missing content`, Boolean(args.content)));
    (false && !(Boolean(args.link)) && Ember.assert(`Crosslink item missing link`, Boolean(args.link)));
  }

  var _default = Ember.Helper.helper(validateArgs);

  _exports.default = _default;
});