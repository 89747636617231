define('ember-in-viewport/initializers/viewport-config', ['exports', 'ember-in-viewport/utils/can-use-dom'], function (exports, _canUseDom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  const defaultConfig = {
    viewportEnabled: true,
    viewportSpy: false,
    viewportScrollSensitivity: 1,
    viewportRefreshRate: 100,
    viewportListeners: [{ context: window, event: 'scroll' }, { context: window, event: 'resize' }],
    viewportTolerance: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    },
    intersectionThreshold: 0,
    scrollableArea: null // defaults to layout view (document.documentElement)
  };

  if (_canUseDom.default) {
    defaultConfig.viewportListeners.push({
      context: document,
      event: 'touchmove'
    });
  }

  function initialize() {
    const application = arguments[1] || arguments[0];
    const config = application.resolveRegistration('config:environment');
    const { viewportConfig = {} } = config;
    const mergedConfig = Ember.assign({}, defaultConfig, viewportConfig);

    application.register('config:in-viewport', mergedConfig, { instantiate: false });
  }

  exports.default = {
    name: 'viewport-config',
    initialize: initialize
  };
});