define("@clark/cms-ui/components/cms-header/nav-item/dropdown-menu/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header-height": "80px",
    "header-padding": "1rem",
    "c-white": "#FFFFFF",
    "dropdown-bg": "#FFFFFF",
    "dropdown-shadow": "rgba(173,182,217,0.5)",
    "dropdown-menu": "_dropdown-menu_m08kgf",
    "dropdown-menu-show": "_dropdown-menu-show_m08kgf"
  };
  _exports.default = _default;
});