define("@clark/cms-ui/components/control-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "dark-brand-primary": "#0439D8",
    "dark-brand-primary-translucent": "rgba(4, 57, 216, 0.33)",
    "dark-brand-c-white": "#FFFFFF",
    "dark-brand-c-white-translucent": "rgba(255, 255, 255, 0.33)",
    "control-button": "_control-button_jknuzc",
    "color-white": "_color-white_jknuzc"
  };
  _exports.default = _default;
});