define("@clark/cms-ui/components/seo-hero/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "breakpoint-medium": "769px",
    "container-height": "480px",
    "mobile-container-height": "480px",
    "image-container-height": "320px",
    "c-white": "#FFFFFF",
    "c-black": "#000000",
    "seo-hero-container": "_seo-hero-container_yln1a8",
    "seo-hero-image": "_seo-hero-image_yln1a8",
    "seo-hero-content": "_seo-hero-content_yln1a8",
    "seo-title": "_seo-title_yln1a8",
    "seo-description": "_seo-description_yln1a8"
  };
  _exports.default = _default;
});