define("@clark/cms-ui/components/partner-header/nav-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MGPWiwp2",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"span\"],[16,0,[31,[[30,[36,0],[\"nav-item\"],[[\"from\"],[\"@clark/cms-ui/components/partner-header/nav-item/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,2],null,[[\"Menu\"],[[30,[36,1],[\"partner-header/nav-item/dropdown-menu\"],[[\"item\"],[[32,0]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/partner-header/nav-item/template.hbs"
    }
  });

  _exports.default = _default;
});