define("ember-svg-jar/inlined/instagram-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.625 7.75c0 .655-.53 1.188-1.188 1.188H17.25a1.188 1.188 0 01-1.188-1.188V6.562c0-.655.532-1.187 1.188-1.187h1.188c.656 0 1.187.532 1.187 1.188V7.75zm0 10.688c0 .656-.53 1.187-1.188 1.187H6.563a1.187 1.187 0 01-1.187-1.188V12.5c0-.656.53-1.188 1.188-1.188H7.92a4.67 4.67 0 00-.17 1.188 4.75 4.75 0 009.5 0c0-.413-.07-.806-.169-1.188h1.357c.656 0 1.187.532 1.187 1.188v5.938zM12.5 10.124c2.187 0 2.375 2.375 2.375 2.375s.07 2.375-2.375 2.375c-2.444 0-2.375-2.375-2.375-2.375s.188-2.375 2.375-2.375zM19.625 3H5.375A2.376 2.376 0 003 5.375v14.25A2.376 2.376 0 005.375 22h14.25A2.376 2.376 0 0022 19.625V5.375A2.376 2.376 0 0019.625 3z\" fill=\"#17203D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});