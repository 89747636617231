define("@clark/cms-ui/components/common/app-store/banner/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "c-grey-lightest": "#f6f6f6",
    "input-border-default": "#A8AAB2",
    "brand-primary": "#2B6CDE",
    "c-white": "#FFFFFF",
    "font-medium": "'CeraRoundPro-Medium'",
    "font-light": "'CeraRoundPro-Light'",
    "black-coral": "#575970",
    "wrapper": "_wrapper_1nq94f",
    "slide-in": "_slide-in_1nq94f",
    "inner-wrapper": "_inner-wrapper_1nq94f",
    "close-button": "_close-button_1nq94f",
    "banner-content-wrapper": "_banner-content-wrapper_1nq94f",
    "banner-content": "_banner-content_1nq94f",
    "logo-wrapper": "_logo-wrapper_1nq94f",
    "description": "_description_1nq94f",
    "title": "_title_1nq94f",
    "sub-title": "_sub-title_1nq94f",
    "label": "_label_1nq94f",
    "cta-button": "_cta-button_1nq94f"
  };
  _exports.default = _default;
});