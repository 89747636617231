define("@clark/cms-ui/components/crosslink/appendable-list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "item-focus-outline-width": "2px",
    "crosslink-appendable-list": "_crosslink-appendable-list_rxhtha",
    "list-container": "_list-container_rxhtha",
    "unrevealed-list": "_unrevealed-list_rxhtha",
    "crosslink-appendable-list-container": "_crosslink-appendable-list-container_rxhtha",
    "visible-container": "_visible-container_rxhtha",
    "reveal-button": "_reveal-button_rxhtha",
    "reveal-button-text": "_reveal-button-text_rxhtha",
    "reveal-button-icon": "_reveal-button-icon_rxhtha"
  };
  _exports.default = _default;
});