define("@clark/cms-ui/components/crosslink/section/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "tiempos-headline": "'TiemposHeadline'",
    "rich-black": "#01051C",
    "title-accent-color": "#0439d7",
    "crosslink-section": "_crosslink-section_19ibww",
    "layout-container": "_layout-container_19ibww",
    "title": "_title_19ibww",
    "title-accent": "_title-accent_19ibww"
  };
  _exports.default = _default;
});