define("@clark/cms-ui/components/modal/body/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "spacing-normal": "1rem",
    "body-wrapper": "_body-wrapper_1lerdr",
    "body": "_body_1lerdr",
    "spinner": "_spinner_1lerdr"
  };
  _exports.default = _default;
});