define("@clark/cms-ui/components/trust-seal-section/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "background-light-gray": "#F5F6FA",
    "c-black": "#000000",
    "font-medium": "'CeraRoundPro-Medium'",
    "from-medium": "only screen and (min-width: 769px)",
    "max-content-width": "calc(758px + 16px * 2)",
    "trust-seal-section": "_trust-seal-section_e28q0y",
    "layout-container": "_layout-container_e28q0y",
    "items": "_items_e28q0y",
    "item": "_item_e28q0y",
    "image": "_image_e28q0y"
  };
  _exports.default = _default;
});