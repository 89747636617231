define("@clark/cms-ui/components/tab-layout/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "font-regular": "'CeraRoundPro-Regular'",
    "c-grey-lightest": "#f6f6f6",
    "c-white": "#FFFFFF",
    "brand-primary": "#2B6CDE",
    "layout-container": "_layout-container_1v3fnl",
    "wrapper": "_wrapper_1v3fnl",
    "title": "_title_1v3fnl",
    "tab-contents": "_tab-contents_1v3fnl",
    "tabs": "_tabs_1v3fnl",
    "tab": "_tab_1v3fnl",
    "tab-active": "_tab-active_1v3fnl",
    "tab-label": "_tab-label_1v3fnl",
    "arrow": "_arrow_1v3fnl"
  };
  _exports.default = _default;
});