define("@clark/cms-ui/helpers/form-builder/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validateFormBuilderArgs([args]) {
    (false && !(args.inputFields.length > 0) && Ember.assert('FormBuilder: Minimum of one input fields should be passed', args.inputFields.length > 0));
    (false && !(typeof args.update === 'function') && Ember.assert('FormBuilder: Update function should be passed to update form', typeof args.update === 'function'));
    (false && !(Object.keys(args.form).some(field => !(Object.prototype.hasOwnProperty.call(field, 'value') && Object.prototype.hasOwnProperty.call('isValid')))) && Ember.assert('FomrBuilder: The Form object should contain value and isValid props', Object.keys(args.form).some(field => !(Object.prototype.hasOwnProperty.call(field, 'value') && Object.prototype.hasOwnProperty.call('isValid')))));
  }

  var _default = Ember.Helper.helper(validateFormBuilderArgs);

  _exports.default = _default;
});