define("@clark/cms-ui/components/modal/section/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "spacing-normal": "1rem",
    "border-width-normal": "1px",
    "cta-secondary-border": "#DADDEC",
    "background-light-gray": "#F5F6FA",
    "section": "_section_1cbsiw",
    "subdued": "_subdued_1cbsiw",
    "flush": "_flush_1cbsiw"
  };
  _exports.default = _default;
});