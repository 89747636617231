define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M47.161 16.447l-15.34-2.472L24.901.538c-.2-.359-.54-.538-.9-.538s-.7.18-.88.538l-6.94 13.437-15.34 2.472c-.82.139-1.14 1.156-.52 1.714l11.64 10.406-3.2 16.088c-.12.657.4 1.196 1 1.196.16 0 .3-.04.46-.12l13.72-7.057 13.72 7.057c.16.08.3.12.46.12.58 0 1.1-.538.98-1.196l-3.1-16.108 11.66-10.406c.62-.538.32-1.555-.5-1.694zm-12.48 10.625l-.84.757.22 1.117 2.72 14.094-11.9-6.12-.92-.479-.92.479-11.88 6.12 2.8-14.054.22-1.117-.84-.757-10.12-9.03 13.28-2.134 1-.16.46-.896 6.04-11.722 6.04 11.702.46.897 1 .18 13.28 2.132-10.1 8.99z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "48",
      "height": "46",
      "viewBox": "0 0 48 46",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});