define("ember-svg-jar/inlined/login-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#5C6277\" fill-rule=\"nonzero\"><path d=\"M22.565 12c0-6.15-4.773-11-10.912-11-3.256 0-6.275 1.418-8.18 3.809C3.117 5.253 3 5.543 3 5.868c0 .615.54 1.127 1.18 1.127.422 0 .76-.153 1.046-.512 1.653-2.101 3.812-3.16 6.393-3.16 4.773 0 8.366 3.74 8.366 8.677 0 4.936-3.593 8.677-8.366 8.677-2.58 0-4.74-1.093-6.393-3.16a1.277 1.277 0 00-1.045-.512c-.641 0-1.181.512-1.181 1.127 0 .325.135.615.472 1.059C5.311 21.582 8.38 23 11.652 23c6.14-.017 10.913-4.85 10.913-11z\"/><path d=\"M13.317 12.858H2.89a.89.89 0 110-1.78h10.37l-2.37-2.56a.926.926 0 010-1.257.794.794 0 011.164 0l3.494 3.773c.082.09.144.19.185.298a.887.887 0 01.03 1.241.922.922 0 01-.215.393l-3.494 3.773a.794.794 0 01-1.165 0 .926.926 0 010-1.257l2.43-2.624z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});