define("@clark/cms-ui/utils/image-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeOptimizedImageUrl = makeOptimizedImageUrl;
  const queryParametersMap = new Map([['width', 'w'], ['height', 'h'], ['quality', 'q'], ['format', 'fm'], ['type', 'fl'], ['focus', 'f'], ['background', 'bg'], ['cropRadius', 'r'], ['resizeBehavior', 'fit']]);

  function makeOptimizedImageUrl(imageURL, optimizationParameters) {
    const queryParams = [];
    Object.entries(optimizationParameters).forEach(([key, value]) => {
      if (queryParametersMap.has(key)) {
        queryParams.push(`${queryParametersMap.get(key)}=${value}`);
      }
    });

    if (Ember.isPresent(queryParams)) {
      return `${imageURL}?${queryParams.join('&')}`;
    }

    return imageURL;
  }
});