define("@clark/cms-ui/components/faq/faq-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MFy3H14E",
    "block": "{\"symbols\":[\"&attrs\",\"@title\",\"@description\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[10,\"h4\"],[15,0,[30,[36,0],[\"h4\"],null]],[14,\"data-test-faq-title\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,2]],[[\"class\"],[[30,[36,1],[\"reset-styles\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,0],[\"c2\"],null]],[14,\"data-test-faq-description\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,3]],[[\"tagName\"],[\"\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"typo\",\"markdown\",\"markdown-to-html\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/faq/faq-item/template.hbs"
    }
  });

  _exports.default = _default;
});