define("@clark/cms-ui/components/form-builder/input-field/phone/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input-border-default": "#A8AAB2",
    "input-border-active": "#2B6CDE",
    "form-input": "_form-input_ooa8aj _form-input_80q2i0",
    "form-input-phone-wrapper": "_form-input-phone-wrapper_ooa8aj",
    "form-input-phone-icon-wrapper": "_form-input-phone-icon-wrapper_ooa8aj",
    "form-input-phone-icon": "_form-input-phone-icon_ooa8aj"
  };
  _exports.default = _default;
});