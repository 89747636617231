define("@clark/cms-api/errors/index", ["exports", "@clark/cms-api/errors/api", "@clark/cms-api/errors/format", "@clark/cms-api/errors/response", "@clark/cms-api/errors/transport"], function (_exports, _api, _format, _response, _transport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "APIError", {
    enumerable: true,
    get: function () {
      return _api.default;
    }
  });
  Object.defineProperty(_exports, "FormatError", {
    enumerable: true,
    get: function () {
      return _format.default;
    }
  });
  Object.defineProperty(_exports, "ResponseError", {
    enumerable: true,
    get: function () {
      return _response.default;
    }
  });
  Object.defineProperty(_exports, "TransportError", {
    enumerable: true,
    get: function () {
      return _transport.default;
    }
  });
});