define('ember-in-viewport/utils/is-in-viewport', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isInViewport;


  const defaultTolerance = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  };

  function isInViewport(boundingClientRect = {}, height = 0, width = 0, tolerance = defaultTolerance) {
    const { top, left, bottom, right } = boundingClientRect;
    const tolerances = Ember.assign(Ember.assign({}, defaultTolerance), tolerance);
    const {
      top: topTolerance,
      left: leftTolerance,
      bottom: bottomTolerance,
      right: rightTolerance
    } = tolerances;

    return top + topTolerance >= 0 && left + leftTolerance >= 0 && Math.round(bottom) - bottomTolerance <= Math.round(height) && Math.round(right) - rightTolerance <= Math.round(width);
  }
});