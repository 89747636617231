define("@clark/cms-ui/components/common/cms-section/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t7LxRLpX",
    "block": "{\"symbols\":[\"@description\",\"&attrs\",\"@title\"],\"statements\":[[1,[30,[36,4],[[32,0,[\"args\"]]],null]],[2,\"\\n\\n\"],[11,\"div\"],[16,0,[31,[[30,[36,0],[\"section-header-container\"],[[\"from\"],[\"@clark/cms-ui/components/common/cms-section/header/styles\"]]]]]],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[14,\"data-test-cms-section-header-title\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"markdown\",\"class\"],[[30,[36,5],[[32,3]],null],[30,[36,2],[[30,[36,1],[\"h2\"],null],\" \",[30,[36,0],[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/common/cms-section/header/styles\"]]]],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,6],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,\"data-test-cms-section-header-description\",\"\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[32,1]],[[\"class\"],[[30,[36,2],[[30,[36,1],[\"c2\"],null],\" \",[30,[36,0],[\"description\"],[[\"from\"],[\"@clark/cms-ui/components/common/cms-section/header/styles\"]]]],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"typo\",\"concat\",\"markdown-to-html\",\"cms-section/validate-header-args\",\"transform-rich-text-header\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/cms-section/header/template.hbs"
    }
  });

  _exports.default = _default;
});