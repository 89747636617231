define("ember-simple-set-helper/helpers/set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function set(positional) {
    let [target, key, maybeValue] = positional;
    (false && !(Boolean(target) && typeof key === 'string' || typeof key === 'symbol') && Ember.assert('you must pass a path to {{set}}', Boolean(target) && typeof key === 'string' || typeof key === 'symbol'));
    return positional.length === 3 ? () => Ember.set(target, key, maybeValue) : value => Ember.set(target, key, value);
  }

  var _default = Ember.Helper.helper(set);

  _exports.default = _default;
});