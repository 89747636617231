define("@clark/cms-ui/helpers/optimized-image-url", ["exports", "@clark/cms-ui/utils/image-url"], function (_exports, _imageUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.optimizedImageUrl = optimizedImageUrl;
  _exports.default = void 0;

  function optimizedImageUrl(parameters, optimizationParameters) {
    (false && !(parameters.length < 2) && Ember.assert(`'optimized-image-url' helper expects one positional param: a base url of the image, got ${parameters.length}`, parameters.length < 2));
    const [imageUrl] = parameters;
    return (0, _imageUrl.makeOptimizedImageUrl)(imageUrl, optimizationParameters);
  }

  var _default = Ember.Helper.helper(optimizedImageUrl);

  _exports.default = _default;
});