define("ember-svg-jar/inlined/cookie-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(1.87 2)\" stroke=\"#FFF\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\"><ellipse cx=\"11.027\" cy=\"11\" rx=\"11.027\" ry=\"11\" stroke-linejoin=\"round\" stroke-width=\"2.2\"/><path d=\"M14.268 7.76l-6.481 6.48m6.49.009L7.778 7.751\" stroke-width=\"1.833\"/></g>",
    "attrs": {
      "height": "26",
      "width": "25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});