define("@clark/cms-ui/components/hero-small/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WCkfL3ML",
    "block": "{\"symbols\":[\"@title\",\"@description\",\"@imgSrc\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"args\"]]],null]],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[[30,[36,2],[\"container\"],null],\" \",[30,[36,1],[\"hero-small\"],[[\"from\"],[\"@clark/cms-ui/components/hero-small/styles\"]]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[30,[36,2],[\"row\"],null]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,2],[\"col-12 col-md-8\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"markdown\",\"class\"],[[30,[36,4],[[32,1]],null],[30,[36,3],[\"m-0\",\" \",[30,[36,1],[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/hero-small/styles\"]]]],null]]]]],[2,\"\\n      \"],[1,[30,[36,5],[[32,2]],[[\"class\"],[[30,[36,3],[[30,[36,6],[\"c1\"],null],\" \",[30,[36,1],[\"hero-small-description\"],[[\"from\"],[\"@clark/cms-ui/components/hero-small/styles\"]]]],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[\"image-container\"],[[\"from\"],[\"@clark/cms-ui/components/hero-small/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"multi-source-image\",[],[[\"@fallbackImgSource\",\"@isDefaultLoading\"],[[30,[36,7],null,[[\"url\",\"altText\"],[[32,3,[\"url\"]],[32,3,[\"altText\"]]]]],true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hero-small/validate-args\",\"local-class\",\"grid\",\"concat\",\"transform-rich-text-header\",\"markdown-to-html\",\"typo\",\"hash\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/hero-small/template.hbs"
    }
  });

  _exports.default = _default;
});