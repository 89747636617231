define("@clark/cms-ui/components/article-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ln42G8v",
    "block": "{\"symbols\":[\"@description\",\"@title\",\"@summaryItems\"],\"statements\":[[10,\"section\"],[15,0,[31,[[30,[36,0],[\"article-summary\"],[[\"from\"],[\"@clark/cms-ui/components/article-summary/styles\"]]]]]],[14,\"data-test-article-summary\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"layout-container\"],[[\"from\"],[\"@clark/cms-ui/components/article-summary/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"h2\"],[15,0,[31,[[30,[36,0],[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/article-summary/styles\"]]]]]],[14,\"data-test-article-summary-title\",\"\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"description\"],[[\"from\"],[\"@clark/cms-ui/components/article-summary/styles\"]]]]]],[14,\"data-test-article-summary-description\",\"\"],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"numbered-list\",[],[[\"@items\",\"@layout\"],[[32,3],\"desktop-tiles\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/article-summary/template.hbs"
    }
  });

  _exports.default = _default;
});