define("ember-intl/-private/store/translation", ["exports", "ember-intl/-private/utils/empty-object", "ember-intl/-private/utils/flatten", "ember-intl/-private/utils/parse"], function (_exports, _emptyObject, _flatten, _parse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @private
   * @hide
   */
  const TranslationModel = Ember.Object.extend({
    localeName: null,

    init() {
      this._super();

      if (!this.translations) {
        this.translations = new _emptyObject.default();
      }

      if (!this.asts) {
        this.asts = new _emptyObject.default();
      }
    },

    addTranslations(translations) {
      const flatTranslations = (0, _flatten.default)(translations);

      for (const key in flatTranslations) {
        let translation = flatTranslations[key]; // If it's not a string, coerce it to one.

        if (typeof translation !== 'string') {
          translation = `${translation}`;
        }

        this.translations[key] = translation;
        this.asts[key] = (0, _parse.default)(translation);
      }
    },

    find(key) {
      if (this.has(key)) {
        return {
          ast: this.asts[key],
          original: this.translations[key]
        };
      }
    },

    has(key) {
      return hasOwnProperty.call(this.translations, key);
    }

  });
  var _default = TranslationModel;
  _exports.default = _default;
});