define("@clark/cms-ui/styles/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-primary": "#2B6CDE",
    "line-height-body": "1.5rem"
  };
  _exports.default = _default;
});