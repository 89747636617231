define("@clark/cms-ui/components/common/accordion/item/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Mt+xmM4",
    "block": "{\"symbols\":[\"&attrs\",\"@for\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"accordion-item-content\"],[[\"from\"],[\"@clark/cms-ui/components/common/accordion/item/content/styles\"]]]]]],[17,1],[16,\"data-accordion-content-for\",[32,2]],[24,\"data-test-accordion-item-content\",\"\"],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/common/accordion/item/content/template.hbs"
    }
  });

  _exports.default = _default;
});