define("@clark/cms-ui/components/crosslink/appendable-list/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DEFAULT_INITIAL_VISIBLE_COUNT = 3;
  const DEFAULT_REVEAL_COUNT = 3;
  let CrosslinkAppendableListComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class CrosslinkAppendableListComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "tracking", _descriptor, this);

      _initializerDefineProperty(this, "visibleCount", _descriptor2, this);

      _defineProperty(this, "revealCount", this.args.revealCount || DEFAULT_REVEAL_COUNT);

      _defineProperty(this, "initialVisibleCount", this.args.initialVisibleCount || DEFAULT_INITIAL_VISIBLE_COUNT);

      _defineProperty(this, "items", []);

      this.items = this.createItemMatrix();
    }
    /**
     * We create a 2D array based on the properties items, initialVisibleCount &
     * revealCount so that all elements are rendered and the rows other than the
     * initial row are hidden using CSS. Then as the user clicks the show more
     * option, we start showing the subsequent rows.
     *
     * Inputs:
     *  - this.args.items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
     *  - this.initialVisibleCount = 4
     *  - this.revealCount = 3
     * Output:
     *  - [[1, 2, 3, 4], [5, 6, 7], [8, 9, 10]]
     */


    createItemMatrix() {
      let rowIndex = 0;
      const {
        items
      } = this.args;
      const itemsPerRow = this.revealCount;
      const itemMatrix = [];

      if (!items) {
        return itemMatrix;
      }

      const initiallyVisibleItems = items.slice(0, this.initialVisibleCount);
      itemMatrix[0] = [];
      itemMatrix[0].push(...initiallyVisibleItems);
      const remainingItems = items.slice(this.initialVisibleCount);

      if (!remainingItems) {
        return itemMatrix;
      }

      remainingItems.forEach((item, index) => {
        if (index % itemsPerRow === 0) {
          rowIndex++;
          itemMatrix[rowIndex] = [];
        }

        itemMatrix[rowIndex].push(item);
      });
      return itemMatrix;
    }

    get hasUnrevealedItems() {
      return this.args.items && this.args.items.length > this.visibleCount;
    }

    handleRevealClick() {
      this.visibleCount += this.revealCount;
      this.tracking.track('customer/SEO:expand-carousel');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tracking", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "visibleCount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.initialVisibleCount || DEFAULT_INITIAL_VISIBLE_COUNT;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleRevealClick", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleRevealClick"), _class.prototype)), _class));
  _exports.default = CrosslinkAppendableListComponent;
});