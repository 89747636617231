define("@clark/cms-ui/components/media-box/component", ["exports", "@glimmer/component", "@clark/cms-ui/breakpoints", "@clark/cms-ui/utils/image-url"], function (_exports, _component, _breakpoints, _imageUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Direction = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let Direction; // These are popular device widths and the standard sizes that we have for the media box section.

  _exports.Direction = Direction;

  (function (Direction) {
    Direction["leftAligned"] = "left";
    Direction["rightAligned"] = "right";
  })(Direction || (_exports.Direction = Direction = {}));

  const ImageBreakpoints = Object.freeze([640, 650, 768, 936, 1024, 1280, 1366, 1600, 1920]);

  class MediaBox extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "direction", Direction);
    }

    get orientation() {
      return this.args.orientation ? Direction.leftAligned : Direction.rightAligned;
    }
    /**
     * Returns srcset value like
     * image_url?h=640&quality=100 640w,image_url?h=700&quality=100 700w, ...
     */


    get imageSources() {
      const sources = ImageBreakpoints.map(breakpoint => {
        return `${(0, _imageUrl.makeOptimizedImageUrl)(this.args.imgSource.url, {
          width: breakpoint,
          quality: 100
        })} ${breakpoint}w`;
      });
      const webpSources = ImageBreakpoints.map(breakpoint => {
        return `${(0, _imageUrl.makeOptimizedImageUrl)(this.args.imgSource.url, {
          width: breakpoint,
          quality: 75,
          format: 'webp'
        })} ${breakpoint}w`;
      });
      return {
        webpSources: webpSources.join(', '),
        fallbackSources: sources.join(', ')
      };
    }
    /**
     * For beforeMedium, the image is rendered in a separate row and hence it occupies almost 86% of the viewport width.
     * For resolutions beforeMedium to large, the image is rendered side by side to the text which is almost 49% of the image viewport.
     * For anything greater than 1200px, the UI is scoped within 1200px (max-width) and hence 650px.
     */


    get imageSizes() {
      return `
      (max-width: ${_breakpoints.breakpoints.medium - 1}px) 86vw,
      (max-width: ${_breakpoints.breakpoints.large - 1}px) 49vw,
      650px
    `;
    }

  }

  _exports.default = MediaBox;
});