define("@clark/cms-ui/components/cms-header/conditional-cta-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "custom-button": "_custom-button_19twe2"
  };
  _exports.default = _default;
});