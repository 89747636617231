define("ember-svg-jar/inlined/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" font-family=\"CeraRoundPro-Medium, Cera Round Pro\" font-size=\"180\" font-weight=\"400\"><text opacity=\".6\" fill=\"#DADDEC\" transform=\"translate(-3 -34)\"><tspan x=\"3\" y=\"189\">404</tspan></text><text fill=\"#2B6CDE\" transform=\"translate(-3 -34)\"><tspan x=\"0\" y=\"181\">404</tspan></text></g>",
    "attrs": {
      "width": "312",
      "height": "131",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});