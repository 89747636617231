define("ember-svg-jar/inlined/next-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.646 6.373a1.273 1.273 0 011.8 0l5.09 5.09a1.27 1.27 0 010 1.8l-5.09 5.092a1.273 1.273 0 01-1.8-1.8l2.918-2.919H7.273a1.273 1.273 0 010-2.545h7.29l-2.917-2.918a1.273 1.273 0 010-1.8z\" fill=\"#203151\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});