define("ember-svg-jar/inlined/mail-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3 7.147l8.336 6.35c.379.29.95.29 1.328 0L21 7.148v9.996c0 .473-.448.857-1 .857H4c-.552 0-1-.384-1-.857V7.147zM4.505 6h14.99L12 11.71 4.505 6z\" fill=\"#17203D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});