define("@clark/cms-ui/components/transition-class-provider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I+oTQ8RY",
    "block": "{\"symbols\":[\"&default\",\"@in\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"update\"]],[32,2]],null]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"isMounted\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0,[\"transitionClass\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-update\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/transition-class-provider/template.hbs"
    }
  });

  _exports.default = _default;
});