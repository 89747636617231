define('ember-in-viewport/services/-in-viewport', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * ensure use on requestAnimationFrame, no matter how many components
   * on the page are using this mixin
   *
   * @class rAFPoolManager
   */
  class rAFPoolManager extends Ember.Service {
    init(...args) {
      super.init(...args);
      this.pool = [];
      this.flush();
    }

    flush() {
      window.requestAnimationFrame(() => {
        // assign to a variable to avoid ensure no race conditions happen
        // b/w flushing the pool and interating through the pool
        let pool = this.pool;
        this.reset();
        pool.forEach(item => {
          item[Object.keys(item)[0]]();
        });
        this.flush();
      });
    }

    add(elementId, fn) {
      this.pool.push({ [elementId]: fn });
      return fn;
    }

    remove(elementId) {
      this.pool = this.pool.filter(obj => !obj[elementId]);
    }

    reset() {
      this.pool = [];
    }
  }
  exports.default = rAFPoolManager;
});