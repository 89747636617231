define("ember-router-scroll/instance-initializers/ember-router-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    // Eagerly initialize service
    appInstance.lookup('service:router-scroll');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});