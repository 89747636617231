define("ember-intl/-private/formatters/format-relative", ["exports", "fast-memoize", "intl-messageformat", "ember-intl/-private/error-types", "ember-intl/-private/formatters/-base"], function (_exports, _fastMemoize, _intlMessageformat, _errorTypes, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const RELATIVE_TIME_OPTIONS = ['numeric', 'style'];
  /**
   * @private
   * @hide
   */

  class FormatRelative extends _base.default {
    constructor(config) {
      super(config);
      this.createNativeFormatter = (0, _fastMemoize.default)((locales, options) => {
        if (!Intl || !Intl.RelativeTimeFormat) {
          config.onError({
            kind: _errorTypes.MISSING_INTL_API,
            error: new _intlMessageformat.FormatError(`Intl.RelativeTimeFormat is not available in this environment.
    Try polyfilling it using "@formatjs/intl-relativetimeformat"
    `, _intlMessageformat.ErrorCode.MISSING_INTL_API)
          });
          return;
        }

        return new Intl.RelativeTimeFormat(locales, options);
      });
    }

    get options() {
      return RELATIVE_TIME_OPTIONS;
    }

    format(locale, value, formatOptions) {
      const formatterOptions = this.readOptions(formatOptions);
      this.validateFormatterOptions(locale, formatterOptions);
      const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
      return formatterInstance.format(value, formatOptions.unit || formatterOptions.unit);
    }

  }

  _exports.default = FormatRelative;

  _defineProperty(FormatRelative, "type", 'relative');
});