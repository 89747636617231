define("@clark/cms-ui/components/expert-tip/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "tiempos-headline": "'TiemposHeadline'",
    "image-width": "160px",
    "image-height": "200px",
    "image-width-before-medium": "90px",
    "image-height-before-medium": "130px",
    "safety-blue": "#080F8C",
    "deep-sky": "#4A86D5",
    "guardian-green": "#54efd1",
    "summer-night": "#203151",
    "wrapper": "_wrapper_4nh6ei",
    "expert-image": "_expert-image_4nh6ei",
    "expert-image-placeholder": "_expert-image-placeholder_4nh6ei",
    "expert-content": "_expert-content_4nh6ei",
    "expert-quote": "_expert-quote_4nh6ei",
    "expert-name": "_expert-name_4nh6ei",
    "inherit-color": "_inherit-color_4nh6ei",
    "variant-deep-sky": "_variant-deep-sky_4nh6ei",
    "variant-guardian-green": "_variant-guardian-green_4nh6ei"
  };
  _exports.default = _default;
});