define("@clark/cms-ui/components/lead-gen/consultant-card/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "font-medium": "'CeraRoundPro-Medium'",
    "from-medium": "only screen and (min-width: 769px)",
    "c-white": "#FFFFFF",
    "rich-black": "#01051C",
    "text-color": "#FFFFFF",
    "image-size-default": "60px",
    "image-size-desktop": "64px",
    "consultant-card": "_consultant-card_sxebbs",
    "layout-vertical": "_layout-vertical_sxebbs",
    "image-container": "_image-container_sxebbs",
    "image": "_image_sxebbs",
    "body": "_body_sxebbs",
    "name": "_name_sxebbs",
    "position": "_position_sxebbs"
  };
  _exports.default = _default;
});