define("@clark/cms-ui/components/multi-source-image/source/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x/dh0tYC",
    "block": "{\"symbols\":[\"&attrs\",\"@src\",\"@includeModernFormat\"],\"statements\":[[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"source\"],[17,1],[16,\"srcset\",[30,[36,0],[[32,2]],[[\"format\"],[\"webp\"]]]],[24,4,\"image/webp\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[11,\"source\"],[17,1],[12],[13]],\"hasEval\":false,\"upvars\":[\"optimized-image-url\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/multi-source-image/source/template.hbs"
    }
  });

  _exports.default = _default;
});