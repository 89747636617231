define("@clark/cms-ui/helpers/media-box/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateArgs = validateArgs;
  _exports.default = void 0;

  function validateArgs([args]) {
    (false && !(Boolean(args.imgSource)) && Ember.assert('Media box: imageSource is not passed to the component', Boolean(args.imgSource)));
    (false && !(Boolean(args.title)) && Ember.assert('Media box: title is not passed to the component', Boolean(args.title)));
  }

  var _default = Ember.Helper.helper(validateArgs);

  _exports.default = _default;
});