define("@clark/cms-ui/components/link/component", ["exports", "@glimmer/component", "@clark/cms-ui/components/link/styles"], function (_exports, _component, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let LinkComponent = (_dec = Ember._action, (_class = class LinkComponent extends _component.default {
    get classes() {
      const value = [_styles.default.link];

      if (this.args.isSubdued) {
        value.push(_styles.default.subdued);
      }

      if (this.args.isMonochrome) {
        value.push(_styles.default.monochrome);
      }

      return value.join(' ');
    }

    handleClick(event) {
      if (this.args.onClick) {
        this.args.onClick(event);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype)), _class));
  _exports.default = LinkComponent;
});