define("ember-svg-jar/inlined/fb-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.164 13.5V22h4.096v-8.46h2.796l.678-3.527h-3.445V7.426c0-.357.19-.834.763-.834h1.99V3h-3.717C11.042 3 9.15 4.594 9.15 6.29v3.623H7v3.572l2.164.015z\" fill=\"#17203D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});