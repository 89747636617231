define("ember-svg-jar/inlined/chevron-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.293 31.293a1 1 0 101.414 1.414l8-7.996a1 1 0 000-1.414l-8-8.004a1 1 0 00-1.414 1.414l7.293 7.297-7.293 7.289z\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});