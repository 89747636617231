define("ember-svg-jar/inlined/check-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>check-ui</title><path d=\"M20.843 7.136a.5.5 0 01.021.707l-9.646 10a.5.5 0 01-.7.028l-5.353-5.025a.5.5 0 11.67-.742l4.99 4.697 9.311-9.644a.5.5 0 01.707-.021z\" fill=\"#8075f2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});