define("@clark/cms-ui/components/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "10r968Lt",
    "block": "{\"symbols\":[\"@id\",\"&attrs\",\"&default\",\"@url\",\"@isExternal\",\"@download\",\"@hideExternalIcon\"],\"statements\":[[6,[37,5],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"unstyled-link\",[[16,0,[32,0,[\"classes\"]]],[16,1,[32,1]],[17,2],[4,[38,0],[\"click\",[32,0,[\"handleClick\"]]],null]],[[\"@url\",\"@isExternal\",\"@download\"],[[32,4],[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,3,null],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[32,5],[30,[36,3],[[32,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[\"icon-lockup\"],[[\"from\"],[\"@clark/cms-ui/components/link/styles\"]]]]]],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[\"icon-layout\"],[[\"from\"],[\"@clark/cms-ui/components/link/styles\"]]]]]],[12],[2,\"\\n          \"],[8,\"icon\",[],[[\"@accessibilityLabel\",\"@source\",\"@size\"],[[30,[36,2],[\"common.new_window_accessibility_label\"],null],\"external-link\",\"small\"]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[32,0,[\"classes\"]]],[16,1,[32,1]],[17,2],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"local-class\",\"t\",\"not\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/link/template.hbs"
    }
  });

  _exports.default = _default;
});