define("@clark/cms-ui/helpers/quick-selection-item/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateQuickSelectionArgs = validateQuickSelectionArgs;
  _exports.default = void 0;

  function validateQuickSelectionArgs([args]) {
    (false && !(Boolean(args.icon)) && Ember.assert(`Quick Selection Item missing SVG icon`, Boolean(args.icon)));
    (false && !(Boolean(args.title)) && Ember.assert(`Quick Selection Item missing title`, Boolean(args.title)));
  }

  var _default = Ember.Helper.helper(validateQuickSelectionArgs);

  _exports.default = _default;
});