define("@clark/cms-ui/components/quick-selection/quick-selection-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5IMMggGC",
    "block": "{\"symbols\":[\"&attrs\",\"@icon\",\"@title\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"args\"]]],null]],[2,\"\\n\\n\"],[11,\"button\"],[16,0,[31,[[30,[36,3],[[30,[36,2],[\"quick-selection-item \",[30,[36,1],[[32,0,[\"isSelected\"]],\"quick-selection-item-selected\"],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/quick-selection/quick-selection-item/styles\"]]]]]],[17,1],[4,[38,4],[\"click\",[32,0,[\"highlightItem\"]]],null],[12],[2,\"\\n  \"],[8,\"icon\",[[16,0,[31,[[30,[36,3],[\"quick-selection-item-icon\"],[[\"from\"],[\"@clark/cms-ui/components/quick-selection/quick-selection-item/styles\"]]]]]]],[[\"@source\"],[[32,2]]],null],[2,\"\\n  \"],[10,\"h4\"],[15,0,[30,[36,5],[\"h4\"],null]],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[[30,[36,3],[\"selected-sign\"],[[\"from\"],[\"@clark/cms-ui/components/quick-selection/quick-selection-item/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"icon\",[],[[\"@source\",\"@size\"],[\"check-ui\",\"large\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"quick-selection-item/validate-args\",\"if\",\"concat\",\"local-class\",\"on\",\"typo\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/quick-selection/quick-selection-item/template.hbs"
    }
  });

  _exports.default = _default;
});