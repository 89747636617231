define("@clark/cms-ui/helpers/markdown", ["exports", "@clark/cms-ui/styles/markdown"], function (_exports, _markdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.markdown = markdown;
  _exports.default = void 0;

  function markdown(parameters) {
    (false && !(parameters.length < 2) && Ember.assert('Markdown helper expects format of classes in one string a.k.a "reset"', parameters.length < 2));
    return parameters[0].split(/\s+/g).map(c => _markdown.default[c]).filter(Boolean).join(' ');
  }

  var _default = Ember.Helper.helper(markdown);

  _exports.default = _default;
});