define("@clark/cms-ui/components/cms-header/conditional-cta-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CZmdw117",
    "block": "{\"symbols\":[\"&default\",\"@leadGenData\",\"@isScrolled\",\"&attrs\",\"@customCtaButtonData\"],\"statements\":[[6,[37,1],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"button\",[[16,0,[31,[[30,[36,0],[\"custom-button\"],[[\"from\"],[\"@clark/cms-ui/components/cms-header/conditional-cta-button/styles\"]]]]]],[17,4],[24,\"data-test-custom-cta-button\",\"\"]],[[\"@url\",\"@appearance\"],[[32,5,[\"url\"]],[30,[36,1],[[30,[36,3],[[30,[36,2],[\"isFromMedium\"],null],[32,3]],null],\"primary\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,5,[\"text\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"button\",[[16,0,[31,[[30,[36,0],[\"custom-button\"],[[\"from\"],[\"@clark/cms-ui/components/cms-header/conditional-cta-button/styles\"]]]]]],[17,4],[24,\"data-test-lead-gen-cta-button\",\"\"]],[[\"@url\",\"@appearance\"],[[30,[36,1],[[32,2,[\"highMargin\"]],\"#lead-gen\",[32,2,[\"cta\",\"url\"]]],null],[30,[36,1],[[30,[36,3],[[30,[36,2],[\"isFromMedium\"],null],[32,3]],null],\"primary\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,4],[\"header.cta.get_an_offer\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"if\",\"media\",\"and\",\"t\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/cms-header/conditional-cta-button/template.hbs"
    }
  });

  _exports.default = _default;
});