define("ember-svg-jar/inlined/hausrat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 17.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5H8zm.5 4v-3h3v3h-3z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.328.623a.5.5 0 00-.67.011l-15 14a.5.5 0 10.683.732L4 12.884V27c0 .396.087.895.404 1.305C4.74 28.74 5.271 29 6 29h20.536c.224.001.667.004 1.005-.334.34-.34.459-.9.459-1.666V12.115l3.172 2.762a.5.5 0 10.656-.754l-15.5-13.5zM27 11.243l-10.988-9.57L4.947 12H5v15c0 .27.063.522.196.695.115.15.333.305.804.305h9.5v-8.889c0-.27.08-.66.335-.996.271-.36.712-.615 1.332-.615h4.666c.27 0 .667.073 1.012.31.372.255.655.683.655 1.301V28h3c.29 0 .316-.023.334-.041.035-.035.166-.225.166-.959V11.244zM22.5 28h-6v-8.889c0-.1.036-.265.132-.393.078-.103.22-.218.535-.218h4.666c.12 0 .306.038.447.135.114.078.22.205.22.476V28z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "29",
      "viewBox": "0 0 32 29",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});