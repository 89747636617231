define("@clark/cms-ui/components/ui/hint/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "hint": "_hint_1i7b2z",
    "type-error": "_type-error_1i7b2z",
    "type-help-text": "_type-help-text_1i7b2z"
  };
  _exports.default = _default;
});