define("@clark/cms-ui/components/multi-source-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xLXi+X3h",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@fallbackImgSource\",\"@isDefaultLoading\",\"@imgClass\"],\"statements\":[[11,\"picture\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"source\",\"mediaQueries\"],[[30,[36,0],[\"multi-source-image/source\"],null],[32,0,[\"mediaQueries\"]]]]]]],[2,\"\\n  \"],[10,\"source\"],[15,\"srcset\",[30,[36,2],[[32,3,[\"url\"]]],[[\"format\"],[\"webp\"]]]],[14,4,\"image/webp\"],[12],[13],[2,\"\\n  \"],[10,\"img\"],[15,\"loading\",[30,[36,3],[[32,4],\"auto\",\"lazy\"],null]],[15,\"src\",[32,3,[\"url\"]]],[15,\"alt\",[32,3,[\"altText\"]]],[15,0,[32,5]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"optimized-image-url\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/multi-source-image/template.hbs"
    }
  });

  _exports.default = _default;
});