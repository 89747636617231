define("@clark/cms-ui/components/success-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PgUHH9Hy",
    "block": "{\"symbols\":[\"@imgSrc\",\"@title\",\"@description\",\"@button\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"args\"]]],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,\"role\",\"presentation\"],[15,0,[30,[36,1],[\"row justify-content-center\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[\"col-12 col-md-8\"],null],\" \",[30,[36,2],[\"success-message-container\"],[[\"from\"],[\"@clark/cms-ui/components/success-message/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"img\"],[15,0,[31,[[30,[36,2],[\"success-message-image\"],[[\"from\"],[\"@clark/cms-ui/components/success-message/styles\"]]]]]],[15,\"src\",[32,1,[\"url\"]]],[15,\"alt\",[32,1,[\"altText\"]]],[14,\"data-test-success-message-image\",\"\"],[12],[13],[2,\"\\n    \"],[10,\"h2\"],[15,0,[31,[[30,[36,3],[\"h2\"],null],\" \",[30,[36,2],[\"success-message-header\"],[[\"from\"],[\"@clark/cms-ui/components/success-message/styles\"]]]]]],[14,\"data-test-success-message-header\",\"\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[15,0,[31,[[30,[36,3],[\"c2\"],null],\" \",[30,[36,2],[\"success-message-description\"],[[\"from\"],[\"@clark/cms-ui/components/success-message/styles\"]]]]]],[14,\"data-test-success-message-description\",\"\"],[12],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"button\",[[24,\"data-test-success-message-button\",\"\"]],[[\"@url\"],[[32,4,[\"href\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,4,[\"text\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"success-message/validate-args\",\"grid\",\"local-class\",\"typo\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/success-message/template.hbs"
    }
  });

  _exports.default = _default;
});