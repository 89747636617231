define("@clark/cms-ui/components/form-builder/input-field/email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XC1+skPM",
    "block": "{\"symbols\":[\"@placeholder\",\"@value\",\"@name\",\"&attrs\"],\"statements\":[[11,\"input\"],[16,0,[31,[[30,[36,0],[[30,[36,2],[[32,0,[\"isError\"]],\"input-form-error\"],null]],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/email/styles\"]]]]]],[16,\"placeholder\",[32,1]],[16,2,[32,2]],[16,3,[32,3]],[17,4],[24,4,\"email\"],[4,[38,3],[\"input\",[32,0,[\"onChange\"]]],null],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"isError\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[\"input-form-error-message\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/email/styles\"]]]]]],[14,\"data-test-input-email-error\",\"\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"forms.email.error_message\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"t\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/email/template.hbs"
    }
  });

  _exports.default = _default;
});