define("ember-svg-jar/inlined/phone-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.793 16.775l-.09-.2c-.27-.66-3.576-1.668-3.849-1.685l-.22.017c-.452.097-.948.54-1.991 1.499-1.175-.564-2.784-2.07-3.542-2.957-.82-.96-1.845-2.577-2.213-3.784 1.19-1.094 1.685-1.57 1.716-2.098.017-.285-.55-3.813-1.147-4.171l-.18-.117c-.38-.255-.955-.64-1.594-.505a1.354 1.354 0 00-.446.187C5.81 3.243 4.76 4 4.281 4.988c-.296.615-.429 6.234 3.625 10.982 4.006 4.696 8.947 4.9 9.689 4.743l.02-.005.066-.02c1.001-.373 1.851-1.365 2.17-1.774.59-.757.169-1.655-.058-2.139\" fill=\"#17203D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});