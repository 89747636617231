define("@clark/cms-ui/helpers/nullable-bool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nullableBool = nullableBool;
  _exports.default = void 0;

  function nullableBool([value, ifTruthey, ifFalsey, ifNull]) {
    if (value == null) {
      return ifNull;
    }

    return value ? ifTruthey : ifFalsey;
  }

  var _default = Ember.Helper.helper(nullableBool);

  _exports.default = _default;
});