define("ember-svg-jar/inlined/twitter-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22 5.825a7.813 7.813 0 01-2.239.612 3.903 3.903 0 001.714-2.152A7.827 7.827 0 0119 5.229 3.896 3.896 0 0016.155 4a3.894 3.894 0 00-3.798 4.777 11.076 11.076 0 01-8.034-4.065 3.886 3.886 0 001.206 5.194 3.884 3.884 0 01-1.765-.486l-.001.048a3.895 3.895 0 003.127 3.816 3.913 3.913 0 01-1.76.067 3.9 3.9 0 003.641 2.701 7.83 7.83 0 01-4.841 1.665c-.315 0-.625-.017-.93-.054a11.046 11.046 0 005.975 1.748c7.17 0 11.091-5.928 11.091-11.07 0-.168-.003-.336-.011-.503A7.907 7.907 0 0022 5.825\" fill=\"#17203D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});