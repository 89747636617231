define("@clark/cms-ui/components/thumbnail-list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "background-light-gray": "#F5F6FA",
    "thumbnail-list-container": "_thumbnail-list-container_9w13gn",
    "thumbnail-list-footer": "_thumbnail-list-footer_9w13gn",
    "thumbnail-list-body": "_thumbnail-list-body_9w13gn",
    "thumbnail-list-item": "_thumbnail-list-item_9w13gn"
  };
  _exports.default = _default;
});