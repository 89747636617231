define("@clark/cms-ui/components/two-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/xu3pY5s",
    "block": "{\"symbols\":[\"section\",\"@footer\",\"@header\",\"@content\"],\"statements\":[[8,\"common/cms-section\",[[16,0,[31,[[30,[36,4],[\"container\"],null],\" \",[30,[36,3],[\"two-column\"],[[\"from\"],[\"@clark/cms-ui/components/two-column/styles\"]]]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"header\"]],[[24,0,\"mb-3\"]],[[\"@title\"],[[32,3,[\"title\"]]]],null],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[[24,\"data-test-two-column-content\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,6],[[32,4]],[[\"class\"],[[30,[36,5],[[30,[36,3],[\"content\"],[[\"from\"],[\"@clark/cms-ui/components/two-column/styles\"]]]],null]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,2,[\"mobile\",\"href\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"button\",[],[[\"@appearance\",\"@url\"],[[30,[36,1],[[30,[36,0],[[32,2,[\"isSecondary\"]]],null],\"primary\",\"secondary\"],null],[30,[36,1],[[30,[36,2],[\"any\"],null],[32,2,[\"mobile\",\"href\"]],[32,2,[\"desktop\",\"href\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,2],[\"any\"],null],[32,2,[\"mobile\",\"text\"]],[32,2,[\"desktop\",\"text\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"if\",\"is-mobile\",\"local-class\",\"grid\",\"concat\",\"markdown-to-html\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/two-column/template.hbs"
    }
  });

  _exports.default = _default;
});