define("@clark/cms-ui/components/common/table/component", ["exports", "@glimmer/component", "showdown"], function (_exports, _component, _showdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Table = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = (_temp = class Table extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "fastboot", _descriptor, this);

      _initializerDefineProperty(this, "tableHead", _descriptor2, this);

      _initializerDefineProperty(this, "tableBody", _descriptor3, this);

      this.extractTableData();
    }

    parseTableDOM() {
      const converter = new _showdown.default.Converter({
        tables: true
      });
      const result = converter.makeHtml(this.args.model);

      if (this.fastboot.isFastBoot) {
        const JSDOM = FastBoot.require('jsdom').JSDOM;

        const dom = new JSDOM(result); // eslint-disable-next-line @typescript-eslint/no-non-null-assertion

        return dom.window.document.querySelector("table");
      }

      const document = new DOMParser().parseFromString(result, 'text/html');
      const table = document.body.firstChild;
      return table;
    }

    extractTableData() {
      const table = this.parseTableDOM();
      const headerCells = table.querySelectorAll('thead tr th');
      this.tableHead = [...headerCells].map(headTag => this.fastboot.isFastBoot ? Ember.String.htmlSafe(headTag.outerHTML) : headTag);
      const body = table.querySelectorAll('tbody tr');
      body.forEach((row, index) => {
        const tableCells = row.querySelectorAll('td');
        this.tableBody[index] = [...tableCells].map(cell => this.fastboot.isFastBoot ? Ember.String.htmlSafe(cell.outerHTML) : cell);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tableHead", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tableBody", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = Table;
});