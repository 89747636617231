define("@clark/cms-ui/modifiers/lock-scroll", ["exports", "ember-modifier", "body-scroll-lock"], function (_exports, _emberModifier, _bodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The `overscroll-behavior` CSS property is what we should be using, once it's generally available.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
   * https://caniuse.com/#feat=css-overscroll-behavior
   *
   * If you want to be able to scroll element after locking you should use modifier directly on that
   * element, wont work if modifier will be used on parent dom element
   */
  var _default = (0, _emberModifier.modifier)(element => {
    (0, _bodyScrollLock.disableBodyScroll)(element);
    return () => {
      (0, _bodyScrollLock.enableBodyScroll)(element);
    };
  });

  _exports.default = _default;
});