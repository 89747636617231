define("@clark/cms-ui/utils/script-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchAndInjectScript = fetchAndInjectScript;

  /**
   *
   * @param source source for the script to be injected
   * @param replace flag to know if injected script tag needs to be removed/replaced
   */
  function injectScript(script, replace = false) {
    const trustpilotScriptNode = document.querySelector(`script[src=${script.source}]`);

    if (replace && trustpilotScriptNode) {
      document.head.removeChild(trustpilotScriptNode);
    }

    if (script && !document.querySelector(`script[src=${script.source}]`)) {
      document.head.append(script);
    }
  }
  /**
   *
   * @param source source for the script to be injected
   * @param replace flag to know if injected script tag needs to be removed/replaced
   */


  function fetchScript(source, replace = false) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = source;
      script.type = 'text/javascript';
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
      script.addEventListener('abort', () => reject(new Error(`Aborted loading of '${source}'.`)));
      injectScript(script, replace);
    });
  }
  /**
   * @param source: url for the script to be injected
   * @param retryAttempts: number of attemps (default 3)
   * @param retryAttempts: number of attemps (default 3)
   *
   * Fetches and injects a 3rd party script in head. Has retry logic incase it
   * fails to load the script.
   */


  async function fetchAndInjectScript(source, retryAttempts = 3) {
    for (let i = 0; i < retryAttempts; i++) {
      try {
        const replace = i > 0;
        return fetchScript(source, replace);
      } catch (error) {
        const isLastAttempt = i + 1 === retryAttempts;
        if (isLastAttempt) throw error;
      }
    }
  }
});