define("ember-svg-jar/inlined/tick-sign-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.933 2H4.066A3.067 3.067 0 001 5.066v16.867A3.067 3.067 0 004.066 25h16.867A3.067 3.067 0 0024 21.933V5.066A3.067 3.067 0 0020.933 2zM18.83 8.841l-7.99 8.99a.51.51 0 01-.706 0L6.14 13.837a.492.492 0 01.696-.695l3.627 3.627 7.667-8.627a.495.495 0 01.699.7z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});