define("@clark/cms-ui/helpers/success-message/validate-args", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateSuccessMessageArgs = validateSuccessMessageArgs;
  _exports.default = void 0;

  function validateSuccessMessageArgs([args]) {
    (false && !(typeof args.title === 'string') && Ember.assert(`Success Message missing title`, typeof args.title === 'string'));
    (false && !(typeof args.description === 'string') && Ember.assert(`Success Message missing description`, typeof args.description === 'string'));
    (false && !(Boolean(args.imgSrc.url)) && Ember.assert(`Success Message missing imgSrc url prop`, Boolean(args.imgSrc.url)));
    (false && !(Boolean(args.button.href) && typeof args.button.text === 'string') && Ember.assert(`Success Message missing button props`, Boolean(args.button.href) && typeof args.button.text === 'string'));
  }

  var _default = Ember.Helper.helper(validateSuccessMessageArgs);

  _exports.default = _default;
});