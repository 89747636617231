define("ember-svg-jar/inlined/linkedin-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.6 12.835c0-1.63-.053-2.993-.107-4.169h3.527l.188 1.818h.08c.534-.856 1.844-2.111 4.036-2.111 2.671 0 4.676 1.79 4.676 5.639v7.723h-4.062v-7.242c0-1.684-.589-2.833-2.058-2.833-1.122 0-1.79.775-2.084 1.523-.107.267-.134.641-.134 1.016v7.536H9.6v-8.9zm-6.493 8.899H7.17V8.666H3.107v13.068zM7.302 5.031c0 1.122-.829 2.03-2.191 2.03C3.828 7.062 3 6.154 3 5.032 3 3.882 3.856 3 5.165 3c1.308 0 2.11.882 2.137 2.031z\" fill=\"#17203D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});