define("ember-svg-jar/inlined/icon-toc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0)\"><path fill=\"#080F8C\" d=\"M.727.636h35.455v32.727H.727z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M34.054 3.482h-.709v-.711c0-1.181-.95-2.135-2.127-2.135H5.69a2.128 2.128 0 00-2.128 2.135v.711h-.709A2.128 2.128 0 00.727 5.616V31.23c0 1.181.95 2.135 2.127 2.135h31.2a2.128 2.128 0 002.127-2.135V5.616c0-1.18-.95-2.134-2.127-2.134zM19.164 2.06h12.054c.397 0 .709.313.709.712v27.035-.711H19.163V2.059zM4.98 2.771c0-.399.312-.712.71-.712h12.054v27.036H4.981V2.77zm29.782 28.458a.703.703 0 01-.709.712h-31.2a.703.703 0 01-.709-.712V5.616c0-.398.312-.711.71-.711h.708v24.19c0 .782.638 1.423 1.418 1.423h26.946c.78 0 1.418-.64 1.418-1.423V4.905h.71c.396 0 .708.313.708.711V31.23zM14.91 7.04a.703.703 0 01-.71.712H8.528a.703.703 0 01-.71-.712c0-.398.313-.711.71-.711H14.2c.397 0 .709.313.709.711zm0 4.27a.703.703 0 01-.71.71H8.528a.703.703 0 01-.71-.71c0-.4.313-.712.71-.712H14.2c.397 0 .709.313.709.711zm0 4.268a.703.703 0 01-.71.711H8.528a.703.703 0 01-.71-.711c0-.399.313-.711.71-.711H14.2c.397 0 .709.313.709.71zm0 4.269a.703.703 0 01-.71.711H8.528a.703.703 0 01-.71-.711c0-.399.313-.712.71-.712H14.2c.397 0 .709.313.709.712zM29.09 7.039a.703.703 0 01-.71.712H22.71a.703.703 0 01-.71-.712c0-.398.313-.711.71-.711h5.672c.398 0 .71.313.71.711zm0 4.27a.703.703 0 01-.71.71H22.71a.703.703 0 01-.71-.71c0-.4.313-.712.71-.712h5.672c.398 0 .71.313.71.711zm0 4.268a.703.703 0 01-.71.711H22.71a.703.703 0 01-.71-.711c0-.399.313-.711.71-.711h5.672c.398 0 .71.313.71.71zm0 4.269a.703.703 0 01-.71.711H22.71a.703.703 0 01-.71-.711c0-.399.313-.712.71-.712h5.672c.398 0 .71.313.71.712z\" fill=\"currentColor\"/></g><defs><clipPath id=\"clip0\"><path fill=\"currentColor\" transform=\"translate(.727 .636)\" d=\"M0 0h35.455v32.727H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "37",
      "height": "34",
      "viewBox": "0 0 37 34",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});