define("@clark/cms-ui/components/ui/labeled-field/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "label-spacing": "8px",
    "hint-spacing": "4px",
    "errors-box-arrow-size": "8px",
    "labeled-field": "_labeled-field_1bkp0r",
    "label": "_label_1bkp0r",
    "hint": "_hint_1bkp0r",
    "errors": "_errors_1bkp0r",
    "errors-appearance-box": "_errors-appearance-box_1bkp0r"
  };
  _exports.default = _default;
});