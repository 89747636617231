define("@clark/cms-ui/components/picture-frame/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "picture-frame": "_picture-frame_120hs1",
    "picture-frame-container": "_picture-frame-container_120hs1",
    "right-container": "_right-container_120hs1",
    "large-img": "_large-img_120hs1",
    "right-col-image": "_right-col-image_120hs1"
  };
  _exports.default = _default;
});