define("@clark/cms-ui/components/trustpilot/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "trustpilot-container": "_trustpilot-container_opmjcu",
    "widget-container": "_widget-container_opmjcu"
  };
  _exports.default = _default;
});