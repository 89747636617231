define("ember-svg-jar/inlined/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.2 1.083L8 6.917.8 1.083\" stroke-linecap=\"round\" stroke=\"#78787A\" stroke-width=\"1.167\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "16",
      "height": "8",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});