define("ember-svg-jar/inlined/heimat-chevron-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.676 9.92l-.117.122-9.278 10.673a1.746 1.746 0 00-.422 1.312c-.038.408.067.83.32 1.182l.102.13 9.278 10.672.117.122A1.75 1.75 0 0028.3 31.84l-.1-.125-8.421-9.689 8.421-9.688a1.75 1.75 0 00-2.524-2.418z\"/>",
    "attrs": {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});