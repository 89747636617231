define("@clark/cms-ui/components/numbered-list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-medium": "only screen and (min-width: 769px)",
    "tile-spacing-desktop": "24px",
    "numbered-list": "_numbered-list_1yd41j",
    "item-container": "_item-container_1yd41j",
    "layout-desktop-tiles": "_layout-desktop-tiles_1yd41j"
  };
  _exports.default = _default;
});