define("ember-css-modules/templates/static-helpers-hack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e6+Wcnoh",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"hello\"],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "ember-css-modules/templates/static-helpers-hack.hbs"
    }
  });

  _exports.default = _default;
});