define("@clark/cms-ui/components/highlight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5llTmrjB",
    "block": "{\"symbols\":[\"&default\",\"@title\",\"@description\"],\"statements\":[[1,[30,[36,2],[[32,0,[\"args\"]]],null]],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[[30,[36,1],[\"container\"],null],\" \",[30,[36,0],[\"highlight-container\"],[[\"from\"],[\"@clark/cms-ui/components/highlight/styles\"]]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[\"row\"],null],\" \",[30,[36,0],[\"highlight\"],[[\"from\"],[\"@clark/cms-ui/components/highlight/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,1],[\"col-12\"],null]],[12],[2,\"\\n      \"],[10,\"h3\"],[15,0,[31,[[30,[36,3],[\"h3\"],null],\" \",[30,[36,0],[\"title\"],[[\"from\"],[\"@clark/cms-ui/components/highlight/styles\"]]]]]],[14,\"data-test-highlight-title\",\"\"],[12],[2,\"\\n        \"],[1,[30,[36,5],[[32,2]],[[\"class\"],[[30,[36,4],[\"reset-styles\"],null]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[15,0,[30,[36,1],[\"row\"],null]],[12],[2,\"\\n        \"],[10,\"h4\"],[15,0,[31,[[30,[36,6],[[30,[36,1],[\"col-md-12\"],null],\" \",[30,[36,3],[\"h4\"],null]],null],\" \",[30,[36,0],[\"description\"],[[\"from\"],[\"@clark/cms-ui/components/highlight/styles\"]]]]]],[14,\"data-test-highlight-description\",\"\"],[12],[2,\"\\n          \"],[1,[30,[36,5],[[32,3]],[[\"class\"],[[30,[36,4],[\"reset-styles\"],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,7],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[\"col-md-4\"],null],\" \",[30,[36,0],[\"highlight-cta-container\"],[[\"from\"],[\"@clark/cms-ui/components/highlight/styles\"]]]]]],[12],[2,\"\\n            \"],[18,1,null],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"grid\",\"highlight/validate-args\",\"typo\",\"markdown\",\"markdown-to-html\",\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/highlight/template.hbs"
    }
  });

  _exports.default = _default;
});