define("@clark/cms-ui/components/media-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "image-height": "520px",
    "image-height-mobile": "320px",
    "from-medium": "only screen and (min-width: 769px)",
    "from-desktop": "only screen and (min-width: 1200px)",
    "media-box-container": "_media-box-container_17aimf",
    "media-box-wrapper": "_media-box-wrapper_17aimf",
    "media-image-container": "_media-image-container_17aimf",
    "media-image": "_media-image_17aimf",
    "media-content": "_media-content_17aimf",
    "media-title": "_media-title_17aimf",
    "media-description": "_media-description_17aimf",
    "media-reverse": "_media-reverse_17aimf"
  };
  _exports.default = _default;
});