define("@clark/cms-ui/components/table-of-contents/container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yW4jkLmD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"table-of-contents-container\"],[[\"from\"],[\"@clark/cms-ui/components/table-of-contents/container/styles\"]]]]]],[14,\"data-test-table-of-contents-container\",\"\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/table-of-contents/container/template.hbs"
    }
  });

  _exports.default = _default;
});