define("@clark/cms-ui/components/modal/close-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OmdxkgK1",
    "block": "{\"symbols\":[\"classes\",\"@onClose\",\"@placement\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,5],[\" \",[30,[36,4],[\"close-button\",[30,[36,3],[\"placement-\",[30,[36,2],[[32,3],\"modal\"],null]],null]],null]],null]],[[\"from\"],[\"@clark/cms-ui/components/modal/close-button/styles\"]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[32,1]],[16,\"aria-label\",[30,[36,0],[\"common.close\"],null]],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n    \"],[8,\"icon\",[],[[\"@source\"],[\"delete\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"on\",\"or\",\"concat\",\"array\",\"join\",\"local-class\",\"let\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/modal/close-button/template.hbs"
    }
  });

  _exports.default = _default;
});