define("@clark/cms-ui/components/stack/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sUlibvBS",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"Item\"],[[30,[36,0],[\"stack/item\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/stack/template.hbs"
    }
  });

  _exports.default = _default;
});