define("@clark/cms-ui/components/form-builder/input-field/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t2z+Crpp",
    "block": "{\"symbols\":[\"@checked\",\"&attrs\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"input-form-checkbox-wrapper\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/check-box/styles\"]]]]]],[12],[2,\"\\n  \"],[10,\"label\"],[15,0,[31,[[30,[36,0],[\"input-form-checkbox-container\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/check-box/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"input\",[[16,0,[31,[[30,[36,0],[\"input-form-checkbox\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/check-box/styles\"]]]]]],[17,2]],[[\"@type\",\"@checked\"],[\"checkbox\",[32,1]]],null],[2,\"\\n    \"],[8,\"icon\",[[16,0,[31,[[30,[36,0],[\"input-form-checkmark\"],[[\"from\"],[\"@clark/cms-ui/components/form-builder/input-field/check-box/styles\"]]]]]]],[[\"@source\"],[\"tick-sign-square\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "@clark/cms-ui/components/form-builder/input-field/check-box/template.hbs"
    }
  });

  _exports.default = _default;
});