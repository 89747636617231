define("@clark/cms-ui/components/breadcrumbs/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-secondary-border": "#DADDEC",
    "cta-secondary": "#FFFFFF",
    "from-medium": "only screen and (min-width: 769px)",
    "max-content-width": "calc(758px + 24px * 2)",
    "breadcrumbs-link-active": "#F99400",
    "breadcrumbs": "_breadcrumbs_18inwo",
    "layout-container": "_layout-container_18inwo",
    "list": "_list_18inwo",
    "item": "_item_18inwo",
    "item-container": "_item-container_18inwo",
    "is-linkless": "_is-linkless_18inwo"
  };
  _exports.default = _default;
});